// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable eqeqeq */
import { useState } from 'react';
import 'react-swipeable-list/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ExtendedPlayerDTO } from '../api/player/player.model';
import './ListaSwipeMobile.css';
import Tooltips from './bootstrap/Tooltips';
import Icon from './icon/Icon';
import Card, { CardBody } from './bootstrap/Card';
import Badge from './bootstrap/Badge';

interface WithTwoActionsProps {
	players: ExtendedPlayerDTO[];
	fullSwipe?: boolean;
	// onDeletePlayer: (playerId: string, playerName: string) => Promise<void>;
	onShowDetail: (playerId: string) => void;
	role: string;
}

const WithTwoActions = (props: WithTwoActionsProps) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/upgrade');
	};

	const renderPlayerInfo = (value: any) => {
		return props.role === 'FREEMIUM' ? (
			<Tooltips
				title={'Passa a Premium per vedere il dato'}
				placement={'auto'}
				flip={['auto']}>
				<div onClick={handleClick}>
					<Icon icon={'Lock'} color={'light'} />
				</div>
			</Tooltips>
		) : isNaN(Number(value)) || value === undefined || value === 0 ? (
			0
		) : (
			value
		);
	};

	return (
		<>
			{props.players.map((player, index) => (
				<Card
					key={'player_' + player.id + index}
					className={`rounded-3 mx-0 mb-3 mx-lg-3 mb-lg-3 ${
						player.goal ? 'bg-l25-success' : player.failure ? 'bg-l25-danger' : ''
					}`}
					borderSize={0}
					shadow={'3d'}
					style={{ cursor: 'pointer' }}
					onClick={() => {
						props.onShowDetail(player.id);
					}}>
					<CardBody className={'py-0 px-0'}>
						<div className='row'>
							<div className='col-9 pe-1'>
								<div className='d-flex h-100'>
									<div className='d-inline-flex flex-column justify-content-center align-items-center align-items-stretch me-2'>
										<h4 className='mb-1'>
											<div
												className={`badge rounded-end rounded-0
																${
																	player.role == 'P'
																		? 'bg-warning'
																		: player.role == 'D'
																		? 'bg-success'
																		: player.role == 'C'
																		? 'bg-info'
																		: 'bg-danger'
																}`}>
												{player.role}
											</div>
										</h4>
										{player.mantraRole &&
											player.mantraRole.map((mantraRole, index) => (
												// eslint-disable-next-line react/jsx-key
												<h6 key={index} className='mb-0'>
													<div
														key={mantraRole}
														// className='badge bg-danger rounded-end rounded-0'
														className={`badge rounded-end rounded-0
																			${
																				mantraRole == 'Por'
																					? 'bg-warning'
																					: mantraRole ==
																							'Ds' ||
																					  mantraRole ==
																							'Dc' ||
																					  mantraRole ==
																							'Dd'
																					? 'bg-success'
																					: mantraRole ==
																							'E' ||
																					  mantraRole ==
																							'M' ||
																					  mantraRole ==
																							'C'
																					? 'bg-info'
																					: mantraRole ==
																							'W' ||
																					  mantraRole ==
																							'T'
																					? 'bg-mantraWT'
																					: 'bg-danger'
																			}`}>
														{mantraRole}
													</div>
												</h6>
											))}
									</div>
									<div className='d-flex flex-column justify-content-between flex-grow-1 h-100 overflow-hidden'>
										<div className='d-flex overflow-hidden w-100'>
											<h5
												style={{ flex: '1 1 50%' }}
												className='fw-bold mb-0 me-1 text-nowrap overflow-hidden mt-2'>
												<Badge
													style={{
														display: 'block',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
													className={'bg-l75-light text-dark'}
													shadow={'sm'}
													rounded={'default'}>
													{player.team}
												</Badge>
											</h5>
											<h5
												style={{ flex: '1 1 50%' }}
												className='fs-5 mb-0 text-nowrap overflow-hidden mt-2'>
												{player.note1 && (
													<Badge
														style={{
															display: 'block',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
														className={
															'bg-l75-light text-dark fw-normal'
														}
														shadow={'sm'}
														rounded={'default'}>
														{player.note1}
													</Badge>
												)}
											</h5>
										</div>
										<div className='d-flex'>
											<h4 className='fw-bold mb-0 py-2'>
												<div>
													{player.bestChoose && (
														<Icon icon={'ThumbUp'} color={'success'} />
													)}
													{player.worstChoose && (
														<Icon icon={'ThumbDown'} color={'danger'} />
													)}
													{player.marketObjective ? (
														<Icon icon={'Star'} color={'success'} />
													) : (
														player.recommendedPlayer && (
															<Icon
																icon={'StarHalf'}
																color={'success'}
															/>
														)
													)}
													{player.notRecommendedPlayer && (
														<Icon
															icon={'DoNotTouch'}
															color={'danger'}
														/>
													)}
													{player.injured && (
														<Icon
															icon={'LocalHospital'}
															color={'danger'}
														/>
													)}
													{' '}{player.surname}
												</div>
											</h4>
										</div>
										<div className='d-flex w-100 overflow-hidden'>
											<h5
												style={{ flex: '1 1 50%' }}
												className='fs-5 mb-0 me-1 text-nowrap overflow-hidden mb-2'>
												{player.note2 && (
													<Badge
														style={{
															display: 'block',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
														className={
															'bg-l75-light text-dark fw-normal'
														}
														shadow={'sm'}
														rounded={'default'}>
														{player.note2}
													</Badge>
												)}
											</h5>
											<h5
												style={{ flex: '1 1 50%' }}
												className='fs-5 mb-0 text-nowrap overflow-hidden mb-2'>
												{player.note3 && (
													<Badge
														style={{
															display: 'block',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}
														className={
															'bg-l75-light text-dark fw-normal'
														}
														shadow={'sm'}
														rounded={'default'}>
														{player.note3}
													</Badge>
												)}
											</h5>
										</div>
									</div>
								</div>
							</div>
							<div className='col-3 d-flex flex-column d-flex justify-content-between mt-2 mb-2 ps-1'>
								<div
									className={
										'd-flex flex-column text-center bg-success rounded-1 mb-1 me-2'
									}>
									<h5 className='mb-0 pt-1 color-white'>
										{renderPlayerInfo(player.classicFantalgoritmoPrice)}
									</h5>
									<small className='color-white'>P. FA</small>
								</div>
								<div
									className={
										'd-flex flex-column text-center bg-info rounded-1 mt-1 me-2'
									}>
									<h5 className='mb-0 pt-1 color-white'>
										{props.role === 'FREEMIUM'
											? renderPlayerInfo(player.classicQuotation)
											: `${
													player.classicQuotation
														? player.classicQuotation
														: '0'
											  } / ${
													player.mantraQuotation
														? player.mantraQuotation
														: '0'
											  }`}
									</h5>
									<small className='color-white'>QUOT</small>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			))}
		</>
	);
};

export default WithTwoActions;
