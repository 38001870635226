import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteLeague } from '../../../api/league/league.api';
import Ads from '../../../components/Ads';
import Button from '../../../components/bootstrap/Button';
import AuthContext from '../../../contexts/authContext';
import { LeagueContext } from '../../../contexts/leagueContext';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import LeagueNameSection from './components/leagueNameSection/leagueNameSection';
import RulesSection from './components/rulesSection/rulesSection';
import TeamsSection from './components/teamsSection/teamsSection';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import ImportFromLeghe from './components/importFromLeghe/importFromLeghe.component';

const LeagueSetup = () => {
	const { userSubscriptionInfo } = useContext(AuthContext);
	const { action } = useParams();
	const navigate = useNavigate();

	const [showImportFlow, setShowImportFlow] = useState(false); // Controlla la visualizzazione del flusso
	const [teams, setTeams] = useState([]); // Stato per le squadre

	const { selectedLeague, setSelectedLeague, leagues, setHasDeletedLeague } =
		useContext(LeagueContext)!;

	const showEditLeagueName = Boolean(action?.includes('modifica_nome'));
	const setShowEditLeagueName = (open: boolean) => {
		const newAction =
			(action
				? action
						.split('-')
						.filter((a) => a !== 'modifica_nome')
						.join('-')
				: '') + (open ? 'modifica_nome' : '');
		navigate(`/setup-lega/${newAction}`);
	};

	if (!selectedLeague) {
		return (
			<PageWrapper
				title='Setup Lega'
				isProtected={true}
				showAds={!userSubscriptionInfo?.paidSubscription}>
				<Page
					container='fluid'
					className='d-flex justify-content-between align-items-center'>
					<h4>Devi prima creare o selezionare una lega</h4>
				</Page>
			</PageWrapper>
		);
	}

	function handleDeleteLeague() {
		deleteLeague(selectedLeague.id).then(() => {
			setSelectedLeague(leagues[0]);
			setHasDeletedLeague(true);
		});
	}

	return (
		<PageWrapper
			title='Setup Lega'
			isProtected={true}
			showAds={!userSubscriptionInfo?.paidSubscription}>
			<SubHeader>
				<SubHeaderRight>
					<ImportFromLeghe
						showImportFlow={showImportFlow}
						setShowImportFlow={setShowImportFlow}
						action={action}
						teams={teams}
						setTeams={setTeams}
					/>
					<SubheaderSeparator/>
					<Button color='danger' icon='Delete' shadow={'lg'} onClick={handleDeleteLeague}>
						Elimina
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page container={'fluid'}>
				<div className={'row h-100'}>
					<div className='col-12'>
						<Ads />
					</div>

					<LeagueNameSection
						showEditLeagueName={showEditLeagueName}
						setShowEditLeagueName={setShowEditLeagueName}
					/>
					<div className='col-12 col-md-7 mb-3'>
						<RulesSection />
					</div>
					<div className='col-12 col-md-5 mb-3'>
						<TeamsSection />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default LeagueSetup;
