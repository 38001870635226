import { createSlice } from '@reduxjs/toolkit';
import { advertisementAction } from "./advertisement.action";
import { Event } from "../../api/advertisement/advertisement.model";

interface AdvertisementState {
	events: Event[];
	loading: boolean;
	error: boolean;
	errorMessage: string;
}

// Define the initial state using that type
const initialState: AdvertisementState = {
	events: [],
	loading: false,
	error: false,
	errorMessage: '',
};

export const advertisementSlice = createSlice({
	extraReducers: (builder) => {
		builder.addCase(advertisementAction.getOdds.pending, (state, action) => {
			state.loading = true;
			state.error = false;
			state.errorMessage = '';
		});
		builder.addCase(advertisementAction.getOdds.fulfilled, (state, action) => {
			state.events = action.payload;
			state.loading = false;
			state.error = false;
			state.errorMessage = '';
		});
		builder.addCase(advertisementAction.getOdds.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
			state.errorMessage = action.payload ? action.payload as string : '';
		});
	},
	initialState,
	name: 'advertisement',
	reducers: {},
});
