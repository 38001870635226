import { use } from 'i18next';
import {
	Children,
	Context,
	createContext,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type ErrorLevel = 'success' | 'info' | 'warning' | 'error' | undefined; // Fixed the duplicated 'info' and added 'warning'

const customToast = (
	title: string,
	msg: string,
	type: ErrorLevel,
	handleClose: (toast?: Toast) => void,
) => {
	toast.dismiss();
	setTimeout(() => {
		switch (type) {
			case 'success':
				toast.success(
					<>
						<h5>{title}</h5>
						<div>{msg}</div>
					</>,
					{
						autoClose: 5000,
						onClose: () => handleClose(undefined),
					},
				);
				break;
			case 'info':
				toast.info(
					<>
						<h5>{title}</h5>
						<div>{msg}</div>
					</>,
					{
						autoClose: 5000,
						onClose: () => handleClose(undefined),
					},
				);
				break;
			case 'warning':
				toast.warn(
					<>
						<h5>{title}</h5>
						<div>{msg}</div>
					</>,
					{
						autoClose: 5000,
						onClose: () => handleClose(undefined),
					},
				);
				break;
			case 'error':
				toast.error(
					<>
						<h5>{title}</h5>
						<div>{msg}</div>
					</>,
					{
						autoClose: 5000,
						onClose: () => handleClose(undefined),
					},
				);
				break;
			default:
				toast(
					<>
						<h5>{title}</h5>
						<div>{msg}</div>
					</>,
					{
						autoClose: 5000,
						onClose: () => handleClose(undefined),
					},
				);
				break;
		}
	}, 100);
};

export interface Toast {
	level: ErrorLevel;
	title: string;
	message: string;
}

export interface ToastContextType {
	setToast: (toast?: Toast) => void;
}

const defaultToastContext: ToastContextType = {
	setToast: () => {},
};

export const ToastContext: Context<ToastContextType> = createContext(defaultToastContext);

export const ToastContextProvider = ({ children }: { children?: ReactNode }) => {
	const [toast, setToast] = useState<Toast>();

	useEffect(() => {
		toast && customToast(toast.title, toast.message, toast.level, setToast);
	}, [toast]);

	return (
		<ToastContext.Provider value={{ setToast }}>
			<ToastContainer />
			{children}
		</ToastContext.Provider>
	);
};
