import { createSlice } from '@reduxjs/toolkit';
import {Profile, Subscription} from '../../api/profile/profile.model';
import { profileAction } from './profile.action';

interface ProfileState {
	loading: boolean;
	error: boolean;
	profile?: Profile;
	subscription?: Subscription
}

// Define the initial state using that type
const initialState: ProfileState = {
	loading: false,
	error: false,
};

export const profileSlice = createSlice({
	name: 'profile',

	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(profileAction.findUserInfo.pending, (state, action) => {
			state.error = false;
		});
		builder.addCase(profileAction.findUserInfo.fulfilled, (state, action) => {
			state.profile = action.payload;
			state.error = false;
		});
		builder.addCase(profileAction.findUserInfo.rejected, (state, action) => {
			state.error = true;
		});
		builder.addCase(profileAction.updateUserInfo.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(profileAction.updateUserInfo.fulfilled, (state, action) => {
			state.profile = action.payload;
			state.loading = false;
		});
		builder.addCase(profileAction.updateUserInfo.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(profileAction.getUserSubscriptionInfo.pending, (state, action) => {
			state.error = false;
		});
		builder.addCase(profileAction.getUserSubscriptionInfo.fulfilled, (state, action) => {
			state.subscription = action.payload;
			state.error = false;
		});
	},
});
