import React, { useState } from 'react';
import { FantalgoritmoData, PlayerDetail } from '../../../../api/player/player.model';
import Card, { CardActions, CardBody, CardHeader, CardTitle } from '../../../../components/bootstrap/Card';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import Popovers from '../../../../components/bootstrap/Popovers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import Progress from '../../../../components/bootstrap/Progress';

interface DatiFantalgoritmoProps {
	playerDetail: PlayerDetail;
	role: string;
	navigate: any;
}

interface InfoCardProps {
	title: string;
	value: string | number | undefined;
	role: string;
	playerDetail: { data?: FantalgoritmoData } | null;
}

interface CenteredMessageProps {
	text: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, value, role, playerDetail }) => {
	return (
		<Card shadow={'sm'} className='bg-l25-info text-center rounded-1'>
			<CardBody>
				<h5
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
					<span dangerouslySetInnerHTML={{ __html: title }} />
				</h5>
				<h4>
					{role === 'FREEMIUM' || !value ? 'N.D.' : value}
				</h4>
			</CardBody>
		</Card>
	);
};



const CenteredMessage: React.FC<CenteredMessageProps> = ({ text }) => {
	return (
		<div
			style={{
				position: 'absolute',
				top: '40%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 1,
			}}>
			<h5 className='text-center'>{text}</h5>
		</div>
	);
};

const DatiFantalgoritmo: React.FC<DatiFantalgoritmoProps> = ({ playerDetail, role, navigate }) => {
	const [viewType, setViewType] = useState<'classic' | 'mantra'>('classic');

	const getValue = (type: 'classic' | 'mantra', field: string) => {
		const key = `${type}${field.charAt(0).toUpperCase() + field.slice(1)}` as keyof FantalgoritmoData;
		return playerDetail?.data?.[key];
	};

	return (
		<Card>
			<CardHeader>
				<CardTitle className="d-flex align-items-center gap-2 my-auto">
					<Icon icon={'BarChart'} color={'info'} size={'2x'}></Icon>
					<h5 className="fw-bold my-auto">Dati Fantalgoritmo</h5>
				</CardTitle>
				<CardActions>
					<ButtonGroup>
						<Button color={'info'}
								isActive={viewType === 'classic'}
								isOutline={viewType !== 'classic'}
								onClick={() => setViewType('classic')}>
							C<span className={'d-none d-xl-inline'}>lassic</span>
						</Button>
						<Button color={'info'}
								isActive={viewType === 'mantra'}
								isOutline={viewType !== 'mantra'}
								onClick={() => setViewType('mantra')}>
							M<span className={'d-none d-xl-inline'}>antra</span>
						</Button>
					</ButtonGroup>
				</CardActions>
			</CardHeader>
			{role === 'FREEMIUM' && (
				<Tooltips
					title={'Passa a Premium per vedere i dati'}
					placement={'auto'}
					flip={['auto']}>
					<div
						style={{
							textAlign: 'center',
							position: 'absolute',
							top: '60%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: 1,
						}}
						onClick={() => {
							navigate('/upgrade');
						}}>
						<Icon icon={'Lock'} color={'dark'} size={'4x'} />
						<h5 className="text-center">Passa a Premium per vedere i dati</h5>
					</div>
				</Tooltips>
			)}
			<CardBody style={{ filter: role === 'FREEMIUM' ? 'blur(6px)' : '' }}>
				{role !== 'FREEMIUM' && !playerDetail?.data && (
					<CenteredMessage text="I dati sono in elaborazione... Riprova più tardi! 😉" />
				)}
				<div className={'row'}>
					<div
						className="col-12 mb-2 text-center"
						style={{
							filter: role === 'FREEMIUM' || !playerDetail?.data ? 'blur(6px)' : '',
						}}>
            <span>
              <b>Qualità/Prezzo</b>
            </span>
						<Progress
							isAutoColor={true}
							value={
								role === 'FREEMIUM'
									? 50
									: playerDetail && playerDetail?.data
										? getValue(viewType, 'valueForMoney')
										: 50
							}
							style={{ width: '100%' }}></Progress>
					</div>
					<div
						className="col-12 mt-2"
						style={{
							filter: role === 'FREEMIUM' || !playerDetail?.data ? 'blur(6px)' : '',
						}}>
						<InfoCard
							title="Prezzo Fantalgoritmo <small className={'text-muted'}>/ 1000 CR</small>"
							value={getValue(viewType, 'staticPrice')}
							role={role}
							playerDetail={playerDetail}
						/>
					</div>
					<div className="col-6">
						<InfoCard
							title="Quotazione"
							value={getValue(viewType, 'quotation')}
							role={role}
							playerDetail={playerDetail}
						/>
					</div>
					<div className="col-6">
						<InfoCard
							title="Indice Appetibilità"
							value={getValue(viewType, 'reliabilityIndex')}
							role={role}
							playerDetail={playerDetail}
						/>
					</div>
					<div className="col-6">
						<InfoCard
							title="Prezzo Medio Aste <small className={'text-muted'}>/ 1000 CR</small>"
							value={playerDetail?.data?.averageAuctionPrice}
							role={role}
							playerDetail={playerDetail}
						/>
					</div>
					<div className="col-6">
						<InfoCard
							title="Valore di Mercato <small className={'text-muted'}>/ 1000 CR</small>"
							value={getValue(viewType, 'marketValue')}
							role={role}
							playerDetail={playerDetail}
						/>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default DatiFantalgoritmo;