import { apiBuilder } from '../api.config';
import {
	ExportLink,
	ExtendedPlayerDTO,
	FindAllQueryParams,
	PlayerDetail,
	PlayerDoubt,
	Players,
	PlayerStatistics,
} from './player.model';

export const findAll = (queryParams: FindAllQueryParams): Promise<Players> => {
	const URL = `/players`;
	return apiBuilder.get<Players>(URL, queryParams);
};

export const findPlayers = (): Promise<Players> => {
	const URL = `/players`;
	return apiBuilder.get<Players>(URL);
};

const removePlayer = (playerId: string): Promise<void> => {
	const URL = `/players/${playerId}`;
	return apiBuilder.delete<void>(URL);
};

export const findDetail = (playerId: string): Promise<PlayerDetail> => {
	const URL = `/players/${playerId}`;
	return apiBuilder.get<PlayerDetail>(URL);
};

export const findStatistics = (playerId: string): Promise<PlayerStatistics> => {
	const URL = `/players/${playerId}/statistics`;
	return apiBuilder.get<PlayerStatistics>(URL);
};

export const setLike = (playerId: string): Promise<ExtendedPlayerDTO> => {
	const URL = `/players/${playerId}/goals`;
	return apiBuilder.post<ExtendedPlayerDTO, boolean>(URL);
};

export const setDislike = (playerId: string): Promise<ExtendedPlayerDTO> => {
	const URL = `/players/${playerId}/failures`;
	return apiBuilder.post<ExtendedPlayerDTO, boolean>(URL);
};

const comparePlayers = (players: []): Promise<PlayerDoubt[]> => {
	const params = players.map((player) => `playerIds=${player}`).join('&');
	const URL = `/players/doubts?${params}`;
	return apiBuilder.get<PlayerDoubt[]>(URL);
};

export const setNote = (playerId: string, note: string): Promise<string> => {
	const URL = `/players/${playerId}/notes`;
	return apiBuilder.post<string, { note: string }>(URL, { note });
};

export const getNote = (playerId: string): Promise<string> => {
	const URL = `/players/${playerId}/notes`;
	return apiBuilder.get<string>(URL);
};

export const deleteNote = (playerId: string): Promise<void> => {
	const URL = `/players/${playerId}/notes`;
	return apiBuilder.delete<void>(URL);
};

// export const exportAll = (): Promise<Blob> => {
// 	const URL = `/players/exports`;
// 	return apiBuilder.getBlob<Blob>(URL);
// };

export const exportAll = (): Promise<ExportLink> => {
	const URL = `/players/exports`;
	return apiBuilder.get<ExportLink>(URL);
};

const addPlayerToTeam = (
	playerId: string,
	teamId: string,
	price: number,
): Promise<ExtendedPlayerDTO> => {
	const URL = `/players/${playerId}`;
	return apiBuilder.post<ExtendedPlayerDTO, { teamId: string; price: number }>(URL, {
		teamId,
		price,
	});
};

export const playerAPI = {
	findAll,
	removePlayer,
	findStatistics,
	findDetail,
	comparePlayers,
	setNote,
	addPlayerToTeam,
	exportAll,
};
