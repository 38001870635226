import React, {useContext, useLayoutEffect} from 'react';
import { useTour } from '@reactour/tour';
import { createUseStyles } from 'react-jss';
import SusyDarkMode from './assets/img/wanna/susy/susy-dark-mode.png';
import Susy2 from './assets/img/wanna/susy/susy2.png';
import Button from './components/bootstrap/Button';
import {useWindowSize} from "react-use";
import ThemeContext from "./contexts/themeContext";

/**
 * Steps style
 */
export const styles = {
	/**
	 * Overlay style
	 * @param base
	 */
	maskWrapper: (base: any) => ({ ...base }),
	maskArea: (base: any) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base: any) => ({ ...base }),
	badge: (base: any) => ({ ...base }),
	popover: (base: any) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
	}),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '150px',
		objectFit: 'contain',
	},
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourNavigation = () => {
	const { currentStep, setCurrentStep } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
				<div className='col'>
					{!!currentStep && (
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							Indietro
						</Button>
					)}
				</div>
				<div className='col-auto'>
					<Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={() => setCurrentStep(currentStep + 1)}>
						Avanti
					</Button>
				</div>
			</div>
		</div>
	);
};

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name: string): `[data-tour='${string}']` => {
	return `[data-tour='${name}']`;
};

const StartTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-12 d-flex align-items-center'>
				<div>
					<p className='lead'>Benvenuto!</p>
					<p>Fantallenatore, benvenuto nel nuovo portale di Fantalgoritmo! Ti aiuteremo a dominare il tuo fantacalcio. Iniziamo il tour!</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const WhatsAppTour = () => {
	const { width } = useWindowSize();
	const { setAsideStatus, setLeftMenuStatus, setRightMenuStatus } = useContext(ThemeContext);

	const linkHandleClick = () => {
		// For Mobile Design
		if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)){
			setAsideStatus(false);
			setLeftMenuStatus(false);
			setRightMenuStatus(true);
		}
	};

	useLayoutEffect(() => {
		if (
			width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
		)
			document.body.classList.add('overflow-hidden');
			linkHandleClick();
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	});
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-12 d-flex align-items-center'>
				<div>
					<p className='lead'>Hai bisogno di aiuto?</p>
					<p>Ricorda che per ogni problema o informazione puoi contattarci su Whatsapp</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const AuctionGuideTour = () => {
	const { width } = useWindowSize();
	const { setAsideStatus, setLeftMenuStatus, setRightMenuStatus } = useContext(ThemeContext);

	const linkHandleClick = () => {
		// For Mobile Design
		if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)){
			setAsideStatus(false);
			setLeftMenuStatus(false);
			setRightMenuStatus(false);
		}
	};

	useLayoutEffect(() => {
		if (
			width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
		)
			document.body.classList.remove('overflow-hidden');
			linkHandleClick();
		return () => {
			document.body.classList.add('overflow-hidden');
		};
	});

	return (
		<div className='row'>
			<div className='col-md-12 d-flex align-items-center'>
				<div>
					<p className='lead'>Partiamo!</p>
					<p>Partiamo da qui, cliccando potrai scaricare le Guide: leggile, è fondamentale per capire i nostro metodo.
					</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const HandleLeaguesTour = () => {
	const { width } = useWindowSize();
	const { setAsideStatus, setLeftMenuStatus, setRightMenuStatus } = useContext(ThemeContext);

	const linkHandleClick = () => {
		// For Mobile Design
		if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)){
			setAsideStatus(true);
			setLeftMenuStatus(false);
			setRightMenuStatus(false);
		}
	};

	useLayoutEffect(() => {
		if (
			width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
		)
			linkHandleClick();
		return () => {
		};
	});
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-12 d-flex align-items-center'>
				<div>
					<p className='lead'>Gestione Leghe</p>
					<p>Qui potrai gestire le tue leghe, crearne di nuove e selezionare quella in cui operare! Prova a creare una.
					</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const DarkModeTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={SusyDarkMode} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Dark / Light Mode</p>
					<p>You can switch between dark and light mode.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const LangSwitcherTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy2} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Language Switcher</p>
					<p>
						"react-i18next" is integrated for internationalization. Currently in demo,
						it is prepared only for <code>Aside</code>, you can easily use it in your
						project.
					</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

/**
 * Tour Steps
 */
const steps = [
	/**
	 * Start Tour
	 * @step 0
	 */
	{
		selector: getTargetName('app'),
		content: () => <StartTour />,
		highlightedSelectors: [getTargetName('app')],
		mutationObservables: [getTargetName('app')],
		resizeObservables: [getTargetName('app')],
	},
	/**
	 * Help Step
	 * @step 1
	 */
	{
		selector: getTargetName('whatsapp'),
		content: () => <WhatsAppTour />,
		highlightedSelectors: [getTargetName('whatsapp')],
		mutationObservables: [getTargetName('whatsapp')],
		resizeObservables: [getTargetName('whatsapp')],
	},
	/**
	 * Auction Guide Step
	 * @step 2
	 */
	{
		selector: getTargetName('auction-guide'),
		content: () => <AuctionGuideTour />,
		highlightedSelectors: [getTargetName('auction-guide')],
		mutationObservables: [getTargetName('auction-guide')],
		resizeObservables: [getTargetName('auction-guide')],
	},
	/**
	 * Auction Guide Step
	 * @step 2
	 */
	{
		selector: getTargetName('handle-leagues'),
		content: () => <HandleLeaguesTour />,
		highlightedSelectors: [getTargetName('handle-leagues')],
		mutationObservables: [getTargetName('handle-leagues')],
		resizeObservables: [getTargetName('handle-leagues')],
	},
];

export default steps;
