import React, { SVGProps } from 'react';

const SvgTram = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M12.97 7h-1.94c-2.75.08-3.62.58-3.9 1h9.74c-.28-.42-1.15-.92-3.9-1zM7 16v1c0 .45.3.84.74.95h3.11c-.22-.26-.35-.59-.35-.95 0-.39.15-.73.39-1H7zm6.5 1c0 .36-.13.69-.35.95h3.11c.44-.11.74-.5.74-.95v-1h-3.89c.24.27.39.61.39 1z'
				opacity={0.3}
			/>
			<path d='M13 5l.75-1.5H17V2H7v1.5h4.75L11 5c-3.13.09-6 .73-6 3.5V17c0 1.5 1.11 2.73 2.55 2.95L6 21.5v.5h2l2-2h4l2 2h2v-.5l-1.55-1.55h-.01.01C17.89 19.73 19 18.5 19 17V8.5c0-2.77-2.87-3.41-6-3.5zm-1.97 2h1.94c2.75.08 3.62.58 3.9 1H7.13c.28-.42 1.15-.92 3.9-1zm-.18 10.95H7.74A.974.974 0 017 17v-1h3.89c-.24.27-.39.61-.39 1 0 .36.13.69.35.95zM17 17c0 .45-.3.84-.74.95h-3.11c.22-.26.35-.59.35-.95 0-.39-.15-.73-.39-1H17v1zm0-3H7v-4h10v4z' />
		</svg>
	);
};

export default SvgTram;
