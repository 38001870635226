import { createAsyncThunk } from '@reduxjs/toolkit';
import { teamAPI } from '../../api/team/team.api';

enum TeamsAction {
	FIND_ALL = 'teams/findAll',
}

const findAll = createAsyncThunk(TeamsAction.FIND_ALL, async (leagueId: string, thunkAPI) => {
	try {
		const teams = await teamAPI.findAll(leagueId);
		return { leagueId, teams };
	} catch (e) {
		throw e;
	}
});

export const teamsAction = {
	findAll,
};
