import { createAsyncThunk } from '@reduxjs/toolkit';
import { teamAPI } from '../../api/team/team.api';

enum TeamDetailAction {
	FIND_DETAIL = 'teamDetail/findDetail',
}

const findDetail = createAsyncThunk(
	TeamDetailAction.FIND_DETAIL,
	async (args: { leagueId: string; teamId: string }, thunkAPI) => {
		try {
			const { leagueId, teamId } = args;
			const teamDetail = await teamAPI.findDetail(leagueId, teamId);
			return teamDetail;
		} catch (e) {
			throw e;
		}
	},
);

export const teamDetailAction = {
	findDetail,
};
