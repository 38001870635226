import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { CustomEventName } from '../event.type';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_REST_URL,
	headers: {},
});

window.addEventListener(CustomEventName.ON_TOKEN_SUCCESS, (e: any) => {
	const token = e.detail;
	axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
	let tokenDiv = document.createElement('div');
	tokenDiv.setAttribute('hidden', 'hidden');
	tokenDiv.id = 'btk-user';
	tokenDiv.textContent = `${token}`;
	const btkElement = document.getElementById('btk-user');
	if (btkElement !== null) {
		btkElement.remove();
	}
	const rootElement = document.getElementById('root');
	if (rootElement !== null) {
		rootElement.appendChild(tokenDiv);
	}
});

const get = async <T>(
	url: string,
	queryParams?: any,
	axiosRequestConfig?: AxiosRequestConfig,
): Promise<T> => {
	try {
		let encodingPathParams: string = '';

		if (queryParams) {
			encodingPathParams = qs.stringify(queryParams, { arrayFormat: 'repeat' });
		}
		const newUrl = encodingPathParams ? `${url}?${encodingPathParams}` : url;
		const response = await axiosInstance.get(newUrl, {
			responseType: 'json',
			...axiosRequestConfig,
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};

const post = async <T, K>(
	url: string,
	data?: K,
	axiosRequestConfig?: AxiosRequestConfig,
): Promise<T> => {
	try {
		const response = await axiosInstance.post(url, data, {
			responseType: 'json',
			...axiosRequestConfig,
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};

const put = async <T, K>(
	url: string,
	data?: K,
	axiosRequestConfig?: AxiosRequestConfig,
): Promise<T> => {
	try {
		const response = await axiosInstance.put(url, data, {
			responseType: 'json',
			...axiosRequestConfig,
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};

const remove = async <T>(url: string, axiosRequestConfig?: AxiosRequestConfig): Promise<T> => {
	try {
		const response = await axiosInstance.delete(url, {
			responseType: 'json',
			...axiosRequestConfig,
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};

const getBlob = async <T>(
	url: string,
	queryParams?: any,
	axiosRequestConfig?: AxiosRequestConfig,
): Promise<T> => {
	try {
		let encodingPathParams: string = '';

		if (queryParams) {
			encodingPathParams = qs.stringify(queryParams, { arrayFormat: 'repeat' });
		}
		const newUrl = encodingPathParams ? `${url}?${encodingPathParams}` : url;
		const response = await axiosInstance.get(newUrl, {
			responseType: 'blob',
			...axiosRequestConfig,
		});
		console.log('ar', response);
		return response.data;
	} catch (e) {
		throw e;
	}
};

export const apiBuilder = {
	get,
	post,
	put,
	delete: remove,
	getBlob,
};
