import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody, CardFooter, CardFooterLeft, CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import AuthContext from '../../../contexts/authContext';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import cominciaAsta from '../../../assets/img/asta-comincia-asta.png';
import configuratoreAsta from '../../../assets/img/asta-configuratore-asta.png';
import astaScaricaGuida from '../../../assets/img/asta-scarica-guida.png';
import inserisciFormazione from '../../../assets/img/campionato-inserisci-formazione.png';
import campionatoScaricaGuida from '../../../assets/img/campionato-scarica-guida.png';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Cookies from 'js-cookie';

const DashboardPage = () => {
	const { userSubscriptionInfo } = useContext(AuthContext);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize(); // Check initially
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		ReactPixel.track('Lead');
		ReactGA.event({
			action: 'generate_lead',
			category: 'User',
			label: 'User lead',
		});
	}, []);

	const openInDefaultBrowser = () => {
		window.open(getLink(), '_system');
		//window.open('https://dev.app.fantalgoritmo.it', '_blank', "location=yes");
		//window.open('intent:https://dev.app.fantalgoritmo.it#Intent;end', '_blank');
		//window.open('intent://dev.app.fantalgoritmo.it#Intent;scheme=https;package=com.android.chrome;end', '_blank');
	};

	const detectApp = () => {
		const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

		const isInstagramInApp = /Instagram/.test(userAgent);
		const isFacebookInApp = /FBAN|FBAV/.test(userAgent);
		const isTwitchInApp = /Twitch/.test(userAgent);
		const isTikTokInApp = /TikTok/.test(userAgent);
		const isYouTubeInApp = /YouTube|YouTubeApp/.test(userAgent);

		if (isInstagramInApp) {
			return 'Instagram';
		} else if (isFacebookInApp) {
			return 'Facebook';
		} else if (isTwitchInApp) {
			return 'Twitch';
		} else if (isTikTokInApp) {
			return 'TikTok';
		} else if (isYouTubeInApp) {
			return 'YouTube';
		} else {
			return '';
		}
	};

	const detectPlatform = () => {
		const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

		if (/android/i.test(userAgent)) {
			return 'Android';
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS';
		}

		return 'unknown';
	};

	const getLink = (): string => {
		if (detectPlatform() === 'Android') {
			return `intent:${window.location.href}#Intent;end`;
		} else if (detectPlatform() === 'iOS') {
			return `x-safari-${window.location.href}`;
		} else {
			return `${window.location.href}`;
		}
	};

	if (detectApp() !== '') {
		return (
			<div className='row'>
				<div className='col-12'></div>
				<div className='col-12 d-flex flex-column align-items-center justify-content-center'>
					<div className='fs-3 text-center'>
						Per offrirvi una migliore esperienza è consigliato procedere sul browser di
						sistema
					</div>
					<Button color={'info'} className='mt-3'>
						<a
							href={getLink()}
							target='_blank'
							style={{ textDecoration: 'none', color: 'white' }}>
							Prosegui
						</a>
					</Button>
				</div>

				<div className='col-12'></div>
			</div>
		);
	}
	return (
		<PageWrapper title='Dashboard Page' isProtected={true} showAds={false}>
			<Page container='fluid' className='pt-0'>
				{!userSubscriptionInfo?.paidSubscription && (
					<>
						<Alert
							color={'primary'}
							isLight={true}
							borderWidth={3}
							rounded={'default'}
							isDismissible>
							<div className='d-flex align-items-center justify-content-between w-100'>
								<div className='d-flex flex-column align-items-start justify-content-center'>
									<AlertHeading tag={'h4'}>
										Se non arrivi a PREMIO ti rimborsiamo 🎉
									</AlertHeading>
									<p>
										Assicurati il podio con Fantalgoritmo. Il 97% dei nostri
										utenti è soddisfatto!
									</p>
								</div>
								<Button
									color={'primary'}
									isOutline={true}
									className='rounded-0'
									onClick={() => {
										navigate('/upgrade');
									}}>
									Vinci ora!
								</Button>
							</div>
						</Alert>
					</>
				)}

				<div className='row'>
					<div className={`col-12 mb-2 d-flex ${
						!isMobile
							? 'align-items-center gap-4'
							: 'flex-column  align-items-start gap-1'
					} justify-content-start`}>
						<h2 className='fw-bolder'>Campionato</h2>
					</div>
					<div className='col-12 col-md-6 mb-3'>
						<Link to={'/campionato'} style={{ textDecoration: 'none' }}>
							<img
								src={inserisciFormazione}
								className={'rounded-top'}
								style={{ width: '100%', height: '73%', objectFit: 'cover' }}
							/>
							<Card
								shadow={'3d'}
								stretch={false}
								hasTab={false}
								tabButtonColor={'primary'}>
								<CardHeader size={'sm'} className={'rounded-bottom rounded-top-0'}>
									<CardLabel icon='StackedLineChart'>
										<CardTitle>Inserisci formazione</CardTitle>
										<CardSubTitle>
											Schiera la migliore formazione per la giornata
										</CardSubTitle>
									</CardLabel>
									<CardActions>
										<Button
											color='info'
											icon='ArrowForwardIos'
											isLight={true}></Button>
									</CardActions>
								</CardHeader>
								<CardBody className={'p-0'}>
									<></>
								</CardBody>
							</Card>
						</Link>
					</div>
					<div className='col-12 col-md-6 mb-3'>
						<Link
							to={
								'https://storage.googleapis.com/it-fantalgoritmo-prod-app-csb/guide/Guida%20al%20campionato%202024-2025.pdf'
							}
							target='_blank'
							rel='noopener noreferrer'
							style={{ textDecoration: 'none' }}>
							<img
								src={campionatoScaricaGuida}
								className={'rounded-top'}
								style={{ width: '100%', height: '73%', objectFit: 'cover' }}
							/>
							<Card
								shadow={'3d'}
								stretch={false}
								hasTab={false}
								tabButtonColor={'primary'}>
								<CardHeader size={'sm'} className={'rounded-bottom rounded-top-0'}>
									<CardLabel icon='Download'>
										<CardTitle>Scarica la guida</CardTitle>
										<CardSubTitle>Preparati per il campionato</CardSubTitle>
									</CardLabel>
									<CardActions>
										<Button
											color='info'
											icon='ArrowForwardIos'
											isLight={true}></Button>
									</CardActions>
								</CardHeader>
								<CardBody className={'p-0'}>
									<></>
								</CardBody>
							</Card>
						</Link>
					</div>
					<div className='col-12 mb-2'>
						<h2 className='fw-bolder'>Asta</h2>
					</div>
					<div className='col-12 col-md-6 col-xxl-3 mb-3'>
						<Link to={'/lista-calciatori'} style={{ textDecoration: 'none' }}>
							<Card
								shadow={'3d'}
								stretch={true}
								hasTab={false}
								tabButtonColor={'primary'}>
								<CardBody className={'p-0'}>
									<img
										src={configuratoreAsta}
										className={'rounded-top img-fluid w-100 h-100'}
										style={{ objectFit: 'cover', objectPosition: 'center' }}
									/>
								</CardBody>
								<CardFooter>
									<CardFooterLeft>
										<CardLabel icon='LooksOne'>
											<CardTitle>Studia per l'asta</CardTitle>
											<CardSubTitle>
												Guarda tutte le statistiche dei giocatori
											</CardSubTitle>
										</CardLabel>
									</CardFooterLeft>
									<CardFooterRight>
										<Button
											color='info'
											icon='ArrowForwardIos'
											isLight={true}></Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						</Link>
					</div>
					<div className='col-12 col-md-6 col-xxl-3 mb-3'>
						<Link to={'/asta'} style={{ textDecoration: 'none' }}>
							<Card
								shadow={'3d'}
								stretch={true}
								hasTab={false}
								tabButtonColor={'primary'}>
								<CardBody className={'p-0'}>
									<img
										src={cominciaAsta}
										className={'rounded-top img-fluid w-100 h-100'}
										style={{ objectFit: 'cover', objectPosition: 'center' }}
									/>
								</CardBody>
								<CardFooter>
									<CardFooterLeft>
										<CardLabel icon='LooksTwo'>
											<CardTitle>Comincia l'asta</CardTitle>
											<CardSubTitle>
												Scegli i migliori giocatori grazie ai nostri dati
											</CardSubTitle>
										</CardLabel>
									</CardFooterLeft>
									<CardFooterRight>
										<Button
											color='info'
											icon='ArrowForwardIos'
											isLight={true}></Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						</Link>
					</div>
					<div className='col-12 col-md-6 col-xxl-3 mb-3'>
						<Link
							to={`https://storage.googleapis.com/it-fantalgoritmo-prod-app-csb/guide/Guida%20all'Asta%20-%20Fantalgoritmo.pdf`}
							target='_blank'
							rel='noopener noreferrer'
							style={{ textDecoration: 'none' }}>
							<Card
								shadow={'3d'}
								stretch={true}
								hasTab={false}
								tabButtonColor={'primary'}>
								<CardBody className={'p-0'}>
									<img
										src={astaScaricaGuida}
										className={'rounded-top img-fluid w-100 h-100'}
										style={{ objectFit: 'cover', objectPosition: 'center' }}
									/>
								</CardBody>
								<CardFooter>
									<CardFooterLeft>
										<CardLabel icon='Download'>
											<CardTitle>Scarica la guida</CardTitle>
											<CardSubTitle>
												Preparati al meglio con la nostra strategia
											</CardSubTitle>
										</CardLabel>
									</CardFooterLeft>
									<CardFooterRight>
										<Button
											color='info'
											icon='ArrowForwardIos'
											isLight={true}></Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						</Link>
					</div>
					<div className='col-12 col-md-6 col-xxl-3 mb-3'>
						<Link
							to={userSubscriptionInfo?.role === 'FREEMIUM' || userSubscriptionInfo?.role === 'BASE'
								? '/upgrade'
								: 'https://www.fantalgoritmo.it/wp-content/uploads/2024/08/Le-3-rose-consigliate-degli-esperti-di-Serie-A.pdf'}
							{...(userSubscriptionInfo.role !== 'FREEMIUM' && userSubscriptionInfo.role !== 'BASE' && {
								target: '_blank',
								rel: 'noopener noreferrer',
							})}
							style={{ textDecoration: 'none' }}>
							<Card
								shadow={'3d'}
								stretch={true}
								hasTab={false}
								tabButtonColor={'primary'}>
								<CardBody className={'p-0'}>
									<img
										src={astaScaricaGuida}
										className={'rounded-top img-fluid w-100 h-100'}
										style={{ objectFit: 'cover', objectPosition: 'center' }}
									/>
								</CardBody>
								<CardFooter>
									<CardFooterLeft>
										<CardLabel icon='Download'>
											<CardTitle>Scarica le rose consigliate</CardTitle>
											<CardSubTitle>
												Guarda le rose di osservatori ed esperti di Serie A
											</CardSubTitle>
										</CardLabel>
									</CardFooterLeft>
									<CardFooterRight>
										<Button
											color='info'
											icon='ArrowForwardIos'
											isLight={true}></Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						</Link>
					</div>

					{/* {!userSubscriptionInfo.paidSubscription && !isMobile && (
						<>
							<div className='col-12 mb-4 d-flex justify-content-between align-items-center gap-1'>
								<a
									href='https://record.betsson.it/_KwlcCS55ieVEtDG9aSt81mNd7ZgqdRLk/1/'
									target='_blank'
									style={{ width: '100%' }}
									className='d-none d-md-inline-block'
									rel='noreferrer'>
									<img
										className='bg-success w-100 rounded-1'
										src={require('../../../assets/img/ad1.png')}></img>
								</a>
								<a
									href='https://record.starcasino.it/_KwlcCS55ieVOEOCTjRhJuGNd7ZgqdRLk/1/'
									target='_blank'
									style={{ width: '100%' }}
									className='d-none d-md-inline-block'
									rel='noreferrer'>
									<img
										className='bg-success w-100 rounded-1'
										src={require('../../../assets/img/ad2.png')}></img>
								</a>
								<a
									href='https://www.eurobet.it/it/' //TODO: inserire link corretto
									target='_blank'
									style={{ width: '100%' }}
									className='d-none d-md-inline-block'
									rel='noreferrer'>
									<img
										className='bg-success w-100 rounded-1'
										src={require('../../../assets/img/ad3.png')}></img>
								</a>
							</div>
						</>
					)}
					{!userSubscriptionInfo.paidSubscription && isMobile && (
						<div className='d-flex justify-content-center align-items-center w-100'>
							<div className='col-12 mb-4 d-flex flex-column justify-content-center align-items-center gap-1 px-2 w-75'>
								<a
									href='https://record.betsson.it/_KwlcCS55ieVEtDG9aSt81mNd7ZgqdRLk/1/'
									target='_blank'
									style={{ width: '100%' }}
									className='d-md-inline-block'
									rel='noreferrer'>
									<img
										className='bg-success w-100 rounded-1'
										src={require('../../../assets/img/ad1.png')}></img>
								</a>
								<a
									href='https://record.starcasino.it/_KwlcCS55ieVOEOCTjRhJuGNd7ZgqdRLk/1/'
									target='_blank'
									style={{ width: '100%' }}
									className='d-md-inline-block'
									rel='noreferrer'>
									<img
										className='bg-success w-100 rounded-1'
										src={require('../../../assets/img/ad2.png')}></img>
								</a>
								<a
									href='https://www.eurobet.it/it/' //TODO: inserire link corretto
									target='_blank'
									style={{ width: '100%' }}
									className='d-md-inline-block'
									rel='noreferrer'>
									<img
										className='bg-success w-100 rounded-1'
										src={require('../../../assets/img/ad3.png')}></img>
								</a>
							</div>
						</div>
					)} */}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;
