import React, { useContext, useEffect, useState } from 'react';
import { WizardItem } from '../../../../components/Wizard';
import Select from '../../../../components/bootstrap/forms/Select';
import WebSocketClient, {
	MessageTypes,
	WebSocketResponse,
} from '../../../../websockets/websockets.config';
import { AuctionContext } from '../../../../contexts/auctionContext';
import AuthContext from '../../../../contexts/authContext';
import Spinner from '../../../../components/bootstrap/Spinner';
import { useNavigate } from 'react-router';

type Team = {
	teamId: string;
	name: string;
	available: boolean;
};

interface SelectTeamProps {
	teamsAuction: Team[];
	setTeamsAuction: (t: Team[]) => void;
}

const SelectTeam = (props: SelectTeamProps) => {
	const { authToken } = useContext(AuthContext);
	const { auctionId, setSelectedTeam, selectedTeam, alreadySelectedTeam, setInvalidCode } =
		useContext(AuctionContext)!;

	const [canSelect, setCanSelect] = useState<boolean>(false);

	useEffect(() => {
		if (alreadySelectedTeam) {
			setSelectedTeam(
				props.teamsAuction.find((team) => team.teamId === alreadySelectedTeam.teamId),
			);
		}
	}, [alreadySelectedTeam]);

	useEffect(() => {
		if (auctionId && authToken && !location.pathname.includes('condividi')) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.subscribe('private-teams', (data: WebSocketResponse) => {
					switch (data.messageType) {
						case MessageTypes.TEAMS_AVAILABLE:
							setInvalidCode(false);
							props.setTeamsAuction(data.content);
							setCanSelect(true);
							break;
						case MessageTypes.TEAM_SELECTION_CONFIRMED:
							break;
						default:
							break;
					}
				});
				client.subscribe('public-teams', (data: WebSocketResponse) => {
					switch (data.messageType) {
						case MessageTypes.TEAMS_AVAILABLE:
							props.setTeamsAuction(data.content);
							break;
						default:
							break;
					}
				});

				client.publish(`/app/auctions/${auctionId}/teams`, ''); //request available teams
			});
		}
	}, [auctionId, authToken]);

	return (
		<>
			<div>
				<p className='text-muted mb-1'>Squadre disponibili</p>
			</div>
			<div>
				{!canSelect && <Spinner isSmall={true} color={'light'} />}
				{canSelect && (
					<Select
						// disabled={props.assignedTeam}
						className={'mb-3 text-muted'}
						id={'team'}
						value={selectedTeam?.teamId}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
							setSelectedTeam(
								props.teamsAuction.find((team) => team.teamId === e.target.value),
							);
						}}
						ariaLabel={'selectedTeam'}
						placeholder={`${
							alreadySelectedTeam ? selectedTeam?.name : 'Visualizza Lista Squadre'
						}`}>
						{alreadySelectedTeam
							? ''
							: props.teamsAuction
									.filter((t) => t.available)
									.map((t) => {
										return (
											<option key={t.teamId} value={t.teamId}>
												{t.name}
											</option>
										);
									})}
					</Select>
				)}
			</div>
		</>
	);
};

export default SelectTeam;
