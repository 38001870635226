import { ChangeEvent, useEffect, useState } from 'react';
import { getLeagueTeams, importLeague } from '../../../../api/league/league.api';
import { LeagueData, LeagueTeams, Team, TeamBasic } from '../../../../api/league/league.model';
import Button from '../../../../components/bootstrap/Button';
import Checks from '../../../../components/bootstrap/forms/Checks';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../../components/bootstrap/forms/InputGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Spinner from '../../../../components/bootstrap/Spinner';
import { WizardItem } from '../../../../components/Wizard';

export const auctionSettings = (
	formik: any,
	isCallAtQuotationPrice: boolean,
	setIsCallAtQuotationPrice: (v: boolean) => void,
	isAuctionRepair: boolean,
	setIsAuctionRepair: (v: boolean) => void,
	isVisitorsEnabled: boolean,
	setIsVisitorsEnabled: (v: boolean) => void,
) => {
	const callModesMock = [
		{ mode: 'Libera', value: 1, disabled: false },
		{ mode: 'Random (Non Disponibile)', value: 2, disabled: true },
		{ mode: 'Ordine Alfabetico (Non Disponibile)', value: 3, disabled: true },
	];
	const usagesMock = [
		{ usage: 'Solitario', value: 1 },
		{ usage: 'Condiviso', value: 2 },
	];

	return (
		<WizardItem id={'firstStep'} title={'Impostazioni Asta'}>
			<form noValidate onSubmit={formik.handleSubmit}>
				<FormGroup>
					<div>
						<p className='text-muted mb-1'>Nome Asta</p>
					</div>
					<Input
						placeholder='Nome'
						type={'text'}
						name='auctionName'
						value={formik.values.auctionName}
						isValid={formik.touched.auctionName && !formik.errors.auctionName}
						isTouched={formik.touched.auctionName}
						invalidFeedback={formik.errors.auctionName}
						onBlur={formik.handleBlur}
						required={true}
						onChange={formik.handleChange}
						ariaLabel='Input'
					/>

					<div>
						<p className='text-muted mb-1 mt-4'>Modalità Chiamata</p>
					</div>
					<Select
						className={'mb-3 text-muted'}
						id={'callMode'}
						value={formik.values.callMode}
						isValid={formik.touched.callMode && !formik.errors.callMode}
						isTouched={formik.touched.callMode}
						invalidFeedback={formik.errors.callMode}
						onBlur={formik.handleBlur}
						required={true}
						onChange={formik.handleChange}
						ariaLabel={'auctionType'}
						placeholder={'Scegli il tipo di utilizzo'}>
						{callModesMock.map((a) => {
							return (
								<option key={a.value} value={a.value} disabled={a.disabled}>
									{a.mode}
								</option>
							);
						})}
					</Select>

					<div>
						<p className='text-muted mb-1 mt-4'>Utilizzo</p>
					</div>
					<Select
						className={'mb-3 text-muted'}
						id={'usage'}
						value={formik.values.usage}
						isValid={formik.touched.usage && !formik.errors.usage}
						isTouched={formik.touched.usage}
						invalidFeedback={formik.errors.usage}
						onBlur={formik.handleBlur}
						required={true}
						onChange={formik.handleChange}
						ariaLabel={'auctionType'}
						placeholder={'Scegli il tipo di utilizzo'}>
						{usagesMock.map((a) => {
							return (
								<option key={a.value} value={a.value}>
									{a.usage}
								</option>
							);
						})}
					</Select>

					<div>
						<p className='text-muted mb-1 mt-4'>Secondi Contatore Prima Chiamata</p>
					</div>

					<Input
						placeholder='5'
						type={'text'}
						id={'firstCallTime'}
						value={formik.values.firstCallTime}
						isValid={formik.touched.firstCallTime && !formik.errors.firstCallTime}
						isTouched={formik.touched.firstCallTime}
						invalidFeedback={formik.errors.firstCallTime}
						onBlur={formik.handleBlur}
						required={true}
						onChange={formik.handleChange}
						ariaLabel='Input'
					/>

					<div>
						<p className='text-muted mb-1 mt-4'>
							Secondi Contatore Chiamate Successive
						</p>
					</div>
					<Input
						placeholder='5'
						type={'text'}
						id={'laterCallTime'}
						value={formik.values.laterCallTime}
						isValid={formik.touched.laterCallTime && !formik.errors.laterCallTime}
						isTouched={formik.touched.laterCallTime}
						invalidFeedback={formik.errors.laterCallTime}
						onBlur={formik.handleBlur}
						required={true}
						onChange={formik.handleChange}
						ariaLabel='Input'
					/>

					<div className='d-flex align-items-center justify-content-between mb-1 mt-4'>
						<div>
							<p className='text-muted mb-0 mt-0 me-4'>
								Chiamata al prezzo di quotazione
							</p>
						</div>
						<Checks
							type={'switch'}
							checked={isCallAtQuotationPrice}
							onChange={() => {
								setIsCallAtQuotationPrice(!isCallAtQuotationPrice);
							}}
							className='mb-0'
						/>
					</div>

					<div className='d-flex align-items-center justify-content-between mb-1 mt-4'>
						<div>
							<p className='text-muted mb-0 mt-0 me-4'>Asta di riparazione</p>
						</div>
						<Checks
							type={'switch'}
							disabled={true}
							checked={isAuctionRepair}
							onChange={() => {
								setIsAuctionRepair(!isAuctionRepair);
							}}
							className='mb-0'
						/>
					</div>
					<div className='d-flex align-items-center justify-content-between mb-1 mt-4'>
						<div>
							<p className='text-muted mb-0 mt-0 me-4'>Abilita Visitatori</p>
						</div>
						<Checks
							type={'switch'}
							disabled={true}
							checked={isVisitorsEnabled}
							onChange={() => {
								setIsVisitorsEnabled(!isVisitorsEnabled);
							}}
							className='mb-0'
						/>
					</div>
				</FormGroup>
			</form>
		</WizardItem>
	);
};

export const teamSettings = (
	selectedLeagueId: string | undefined,
	teams: LeagueTeams | undefined,
	setTeams: (v: any) => void,
	leagueData: LeagueData | null,
	newTeams: TeamBasic[] | undefined,
	setNewTeams: (v: TeamBasic[]) => void,
	nrAttaccanti: number,
	setNrAttaccanti: (v: number) => void,
	nrCentrocampisti: number,
	setNrCentrocampisti: (v: number) => void,
	nrDifensori: number,
	setNrDifensori: (v: number) => void,
	nrPortieri: number,
	setNrPortieri: (v: number) => void,
	newRules: any,
	setNewRules: (v: any) => void,
) => {
	const [importedLeagueName, setImportedLeagueName] = useState<string>('');
	const [hasClickedImport, setHasClickedImport] = useState<boolean>(false);
	const [importedLeague, setImportedLeague] = useState<any>();
	const [competition, setCompetition] = useState<any | null>();
	const [newTeam, setNewTeam] = useState<any>({ id: '', name: '', initialCredits: 0 });
	const [leagueImportSuccess, setLeagueImportSuccess] = useState<boolean>(false);
	const [importedTeams, setImportedTeams] = useState<TeamBasic[]>([]);

	useEffect(() => {
		if (importedLeague) {
			setCompetition(importedLeague[0].competition);
		}
	}, [importedLeague]);

	useEffect(() => {
		if (importedTeams.length > 0) {
			setNewTeams(importedTeams);
			setTeams({ teams: importedTeams });
		}
	}, [importedTeams]);

	useEffect(() => {
		if (competition) {
			competition.teams.map((team: any) => {
				const newTeam = {
					id: team.id || '', // Use team.id if available, otherwise an empty string
					name: team.name,
					initialCredits: team.initialCredits,
					pathLogo: team.pathLogo || null, // Use team.pathLogo if available, otherwise null
				} as TeamBasic;

				setImportedTeams((importedTeams) => [...importedTeams, newTeam]);
			});
		}
	}, [competition]);

	const removeTeam = (index: number) => {
		if (teams) {
			const newTeams = teams.teams.filter(
				(team: any, teamIndex: number) => teamIndex !== index,
			);
			if (newTeams) {
				setTeams({ teams: newTeams });
				setNewTeams(newTeams);
			}
		}
	};

	useEffect(() => {
		if (selectedLeagueId)
			getLeagueTeams(selectedLeagueId).then((ret) => {
				setTeams(ret);
			});
	}, [selectedLeagueId]);

	const addTeam = () => {
		if (newTeam && teams && newTeams) {
			if (
				newTeam.name !== '' &&
				!teams?.teams?.some((team) => team.name === newTeam.name) &&
				!newTeams?.some((team) => team.name === newTeam.name)
			) {
				const teamIndex = teams.teams.findIndex(
					(team: any) => team.id === newTeam.id && newTeam.id !== '',
				);
				if (teamIndex !== -1) {
					// Team with the same id found, do nothing
				} else {
					// No team with the same id found, add the new team
					const newTeamsArray = newTeams.concat(newTeam);

					setNewTeams(newTeamsArray);
					setTeams({ teams: newTeamsArray });
				}
				setNewTeam({ id: '', name: '', initialCredits: 0 });
			}
		}
	};

	function handleLeagueImport(leagueName: string) {
		if (selectedLeagueId) {
			importLeague(selectedLeagueId, leagueName)
				.then((ret) => {
					setImportedLeague(ret);
					setLeagueImportSuccess(true);
				})
				.catch((error) => {
					setLeagueImportSuccess(false);
					console.error(error); //TODO: show error message
				});
		}
	}

	return (
		<WizardItem id={'secondStep'} title={'Impostazioni Squadre'}>
			<div>
				<p className='text-muted mb-1 mt-4'>Opzioni Rose</p>
			</div>
			<InputGroup>
				<InputGroupText className='bg-warning'>P</InputGroupText>
				<Input
					placeholder={`${leagueData?.rules.numberGoalkeepers ?? 0}`}
					type={'number'}
					value={nrPortieri}
					required={true}
					isValid={(nrPortieri ?? 0) >= 0 && (nrPortieri ?? 0) <= 3}
					invalidFeedback={'Al massimo 3'}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setNrPortieri(Number(e.target.value));
						setNewRules({
							...newRules,
							numberGoalkeepers: Number(e.target.value),
						});
					}}
					ariaLabel='Input'
				/>
				<InputGroupText className='bg-success'>D</InputGroupText>
				<Input
					placeholder={`${leagueData?.rules.numberDefenders}`}
					type={'number'}
					value={nrDifensori}
					required={true}
					isValid={(nrDifensori ?? 0) >= 0 && (nrDifensori ?? 0) <= 8}
					invalidFeedback={'Al massimo 8'}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setNrDifensori(Number(e.target.value));
						setNewRules({
							...newRules,
							numberDefenders: Number(e.target.value),
						});
					}}
					ariaLabel='Input'
				/>
				<InputGroupText className='bg-info'>C</InputGroupText>
				<Input
					placeholder={`${leagueData?.rules.numberMidfielders}`}
					type={'number'}
					value={nrCentrocampisti}
					required={true}
					isValid={(nrCentrocampisti ?? 0) >= 0 && (nrCentrocampisti ?? 0) <= 8}
					invalidFeedback={'Al massimo 8'}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setNrCentrocampisti(Number(e.target.value));
						setNewRules({
							...newRules,
							numberMidfielders: Number(e.target.value),
						});
					}}
					ariaLabel='Input'
				/>
				<InputGroupText className='bg-danger'>A</InputGroupText>
				<Input
					placeholder={`${leagueData?.rules.numberForwards}`}
					type={'number'}
					value={nrAttaccanti}
					required={true}
					isValid={(nrAttaccanti ?? 0) >= 0 && (nrAttaccanti ?? 0) <= 6}
					invalidFeedback={'Al massimo 6'}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setNrAttaccanti(Number(e.target.value));
						setNewRules({
							...newRules,
							numberForwards: Number(e.target.value),
						});
					}}
					ariaLabel='Input'
				/>
			</InputGroup>
			<div>
				<p className='text-muted mb-2'>Importa Squadre da Leghe Fantacalcio</p>
			</div>
			<div>
				<InputGroup>
					<InputGroupText>leghe.fantacalcio.it/</InputGroupText>
					<Input
						placeholder='la-tua-lega'
						type={'text'}
						value={importedLeagueName}
						required={false}
						isValid={importedLeagueName.length !== 0}
						invalidFeedback={'Lega non trovata'}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setImportedLeagueName(e.target.value)
						}
						ariaLabel='Input'
					/>
					<Button
						color={'warning'}
						isOutline={true}
						onClick={() => {
							handleLeagueImport(importedLeagueName);
							setHasClickedImport(true);
						}}>
						Importa
					</Button>
				</InputGroup>
			</div>
			{!leagueImportSuccess && hasClickedImport && (
				<div>
					Sto cercando la lega. Potrebbero volerci fino a 30 secondi
					<Spinner className='mt-3 align-center' />
				</div>
			)}
			{leagueImportSuccess && (
				<div>
					<label className={'mb-1 mt-3 text-muted'}>Seleziona competizione</label>
					<Select
						className={'text-muted mb-4'}
						aria-label={'select'}
						ariaLabel={'select'}
						//disabled={isLoading || isError}
						list={importedLeague.map((league: any) => {
							return {
								text: league.name,
								value: league.id,
							};
						})}
						placeholder='Seleziona Competizione'
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const selectedCompetition = importedLeague.find(
								(competition: any) =>
									Number(competition.id) === Number(e.target.value),
							);
							if (selectedCompetition) {
								setCompetition(selectedCompetition);
							}
						}}
					/>
				</div>
			)}
			<div>
				<p className='text-muted mb-4 mt-3 fs-6 text-center'>Oppure</p>
			</div>
			<div>
				<p className='text-muted mb-2'>Inserisci Manualmente le Squadre</p>
			</div>
			<div>
				<InputGroup>
					<Input
						placeholder='Nome Squadra'
						type={'text'}
						value={newTeam?.name}
						required={false}
						isValid={newTeam?.name.length !== 0}
						invalidFeedback={'Squadra non trovata'}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const teamName = e.target.value;
							const existingTeam = teams?.teams.find(
								(team: any) =>
									team.name.toLowerCase().trim() ===
									teamName.toLowerCase().trim(),
							);
							const id = existingTeam ? existingTeam.id : '';
							const initialCredits = 0;

							setNewTeam({
								id: id,
								name: teamName,
								initialCredits: initialCredits,
							});
						}}
						ariaLabel='Input'
					/>
					<Button color={'info'} isOutline={true} onClick={addTeam}>
						Aggiungi
					</Button>
				</InputGroup>
			</div>
			<div>
				<p className='text-muted mb-3 mt-4'>Lista Squadre</p>
			</div>
			<div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
				{!leagueImportSuccess &&
					newTeams &&
					newTeams?.map((team: any, index: number) => (
						<div
							key={index}
							className='d-flex justify-content-between align-items-center'>
							<Button
								color={'light'}
								isOutline={true}
								isDisable={true}
								className='rounded-0 flex-grow-1 text-start text-dark fw-normal'>
								{`${team.name}`}
							</Button>
							<div className='d-flex'>
								<Input
									placeholder='Crediti'
									type={'number'}
									value={team.initialCredits}
									required={true}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										if (Number(e.target.value) < 0) {
											return;
										}
										const newTeam = {
											id: team.id,
											name: team.name,
											initialCredits: Number(e.target.value),
										};
										const updatedTeams =
											newTeams?.map((team, idx) =>
												idx === index ? newTeam : team,
											) || [];

										setNewTeams(updatedTeams);
									}}
									ariaLabel='Input'
									style={{ width: 100 }}
								/>
								<Button
									className='bg-danger text-white rounded-1'
									icon={'Remove'}
									size={'sm'}
									onClick={() => {
										removeTeam(index);
									}}></Button>
							</div>
						</div>
					))}
				{leagueImportSuccess &&
					teams &&
					newTeams &&
					teams.teams &&
					teams.teams?.map((team: any, index: number) => (
						<div
							key={index}
							className='d-flex justify-content-between align-items-center'>
							<Button
								color={'light'}
								isOutline={true}
								isDisable={true}
								className='rounded-0 flex-grow-1 text-start text-dark fw-normal'>
								{`${team.name}`}
							</Button>
							<div className='d-flex'>
								<Input
									placeholder='Crediti'
									type={'number'}
									value={newTeams[index].initialCredits}
									required={true}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										if (Number(e.target.value) < 0) {
											return;
										}

										const newTeam = {
											...teams.teams[index],
											initialCredits: Number(e.target.value),
										};

										const updatedTeams = newTeams?.map((team, idx) =>
											idx === index ? newTeam : team,
										);

										if (updatedTeams) setNewTeams(updatedTeams);
									}}
									ariaLabel='Input'
									style={{ width: 100 }}
								/>
								<Button
									className='bg-danger text-white rounded-1'
									icon={'Remove'}
									size={'sm'}
									onClick={() => {
										removeTeam(index);
									}}></Button>
							</div>
						</div>
					))}
			</div>
		</WizardItem>
	);
};
