import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import { StartAuctionWizard } from './startAuctionWizard';
import Icon from '../../../../components/icon/Icon';
import { LeagueContext } from '../../../../contexts/leagueContext';
import { useContext } from 'react';

interface StartAuctionModalProps {
	action: string | undefined;
}

export const StartAuctionModal = (props: StartAuctionModalProps) => {
	const navigate = useNavigate();
	const { selectedLeague } = useContext(LeagueContext)!;

	const isOpen = Boolean(props.action?.includes('avvia'));

	const setOpen = (open: boolean) => {
		const newAction =
			(props.action
				? props.action
						.split('-')
						.filter((a) => a !== 'avvia')
						.join('-')
				: '') + (open ? 'avvia' : '');
		navigate(`/asta/${newAction}`);
	};

	return (
		<>
			<Button
				className='d-flex align-items-center justify-content-center gap-2flex-grow-1 bg-lo50-success text-white'
				onClick={() => {
					setOpen(true);
				}}
				isDisable={!selectedLeague}
				style={{ fontSize: '1.1em' }}>
				<Icon icon={'PlayArrow'} size={'2x'}></Icon>
				<>Avvia Nuova Asta</>
			</Button>
			<Modal
				id={'startAuction'}
				titleId={'Avvia Nuova Asta'}
				isOpen={isOpen}
				setIsOpen={setOpen}
				isStaticBackdrop={true}
				isCentered={true}>
				<ModalHeader showClose={false}>
					<ModalTitle id={'startAuction'}>Avvia Nuova Asta</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							setOpen(false);
						}}
					/>
				</ModalHeader>
				<ModalBody>
					<StartAuctionWizard setStartAuctionOpen={setOpen} />
				</ModalBody>
			</Modal>
		</>
	);
};
