import { createSlice } from '@reduxjs/toolkit';
import { PlayerDetail } from '../../api/player/player.model';
import { playerDetailAction } from './playerDetail.action';

interface PlayerDetailState {
	loading: boolean;
	error: boolean;
	detail?: PlayerDetail;
	setNoteMessageError: string;
}

// Define the initial state using that type
const initialState: PlayerDetailState = {
	loading: false,
	error: false,
	setNoteMessageError: '',
};

export const playerDetailSlice = createSlice({
	name: 'playerDetail',

	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(playerDetailAction.findDetail.pending, (state, action) => {
			state.loading = true;
			state.error = false;
		});
		builder.addCase(playerDetailAction.findDetail.fulfilled, (state, action) => {
			state.detail = action.payload;
			state.loading = false;
			state.error = false;
		});
		builder.addCase(playerDetailAction.findDetail.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
		});
		builder.addCase(playerDetailAction.setNote.fulfilled, (state, action) => {
			if (state.detail) {
				state.detail.note = action.payload;
				state.setNoteMessageError = '';
			}
		});
		builder.addCase(playerDetailAction.setNote.pending, (state, action) => {
			state.setNoteMessageError = '';
		});
		builder.addCase(playerDetailAction.setNote.rejected, (state, action) => {
			state.setNoteMessageError = action.payload as string;
		});
	},
});
