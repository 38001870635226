import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import { JoinAuctionWizard } from './joinAuctionWizard';
import Icon from '../../../../components/icon/Icon';
import { AuctionContext } from '../../../../contexts/auctionContext';
import { translateCode } from '../../../../api/auction/auction.api';

interface JoinAuctionModalProps {
	action: string | undefined;
	auctionCode: string;
	setAuctionCode: (auctionCode: string) => void;
}

export const JoinAuctionModal = (props: JoinAuctionModalProps) => {
	const navigate = useNavigate();
	const { setAuctionId, setInvalidCode } = useContext(AuctionContext)!;

	const isResult = (action: string | undefined) =>
		Boolean(action !== 'avvia' && action !== undefined);

	const isOpen = isResult(props.action);

	const setOpen = (open: boolean) => {
		let newAction = '';

		if (open) {
			// When opening, use the auctionCode if available; default to 'unisciti'
			newAction = `${props.auctionCode ? `${props.auctionCode}` : 'unisciti'}`;
		} else {
			navigate('/asta');
		}

		// Navigate only if necessary
		if (newAction !== props.action) {
			navigate('/asta');
			if (open) {
				navigate(`/asta/${newAction}`);
			}
		}
	};

	useEffect(() => {
		if (!isOpen) {
			setAuctionId('');
			setInvalidCode(true);
		}
	}, [isOpen]);

	useEffect(() => {
		const handleBackButtonEvent = (e: any) => {
			props.setAuctionCode('');
			navigate('/asta');
		};

		window.addEventListener('popstate', handleBackButtonEvent);

		return () => {
			window.removeEventListener('popstate', handleBackButtonEvent);
		};
	}, []);

	return (
		<>
			<Button
				className='d-flex align-items-center justify-content-center gap-2 flex-grow-1 bg-lo50-warning text-white'
				onClick={() => {
					setOpen(true);
				}}
				style={{ fontSize: '1.1em' }}>
				<Icon icon={'DoubleArrow'} size={'2x'}></Icon>
				<>Unisciti a un'asta</>
			</Button>
			<Modal
				id={'joinAuction'}
				titleId={"Unisciti a un'Asta"}
				isOpen={isOpen}
				setIsOpen={setOpen}
				isStaticBackdrop={true}
				isCentered={true}>
				<ModalHeader showClose={false}>
					<ModalTitle id={'joinAuction'}>Unisciti a un'Asta</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							setOpen(false);
							props.setAuctionCode('');
						}}
					/>
				</ModalHeader>

				<ModalBody>
					<JoinAuctionWizard />
				</ModalBody>
			</Modal>
		</>
	);
};
