// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInfluencerStatistics } from '../../../../api/influencer/influencer.api';
import { Influencer, InfluencerStatistics } from '../../../../api/influencer/influencer.model';
import { deleteNote, findDetail, findStatistics, getNote } from '../../../../api/player/player.api';
import {
	ExtendedPlayerDTO,
	PlayerDetail,
	PlayerStatistics,
} from '../../../../api/player/player.model';
import Spinner from '../../../../components/bootstrap/Spinner';
import { usePlayerDetail } from '../../../../hooks/businessLogic/usePlayerDetail';
import {
	RenderInfluencer,
	renderInfortuni,
	renderNote,
	renderPlayerIdentikit,
	renderStatistiche,
} from './renderCommonItems';
import DatiFantalgoritmo from './DatiFantalgoritmo';

interface PlayerDetailProps {
	playerId: string;
	selectedInfluencer?: Influencer | undefined;
	userSubscriptionInfo: any;
	playerDetail: PlayerDetail | null;
}

const PlayerDetailsDesktop = (props: PlayerDetailProps) => {
	const navigate = useNavigate();
	const [showAddPlayerToTeam, setShowAddPlayerToTeam] = useState<boolean>(false);
	const [collapseState, setCollapseState] = useState(true);
	const [statisticheCollapseState, setStatisticheCollapseState] = useState(false);
	const [playerStatistics, setPlayerStatistics] = useState<PlayerStatistics | null>(null);
	const [dropdownOpenStatistiche, setDropdownOpenStatistiche] = useState(false);
	const [dropdownOpenInfortuni, setDropdownOpenInfortuni] = useState(false);
	const [noteState, setNoteState] = useState<any>(null);
	const [selectedYearStatistiche, setSelectedYearStatistiche] = useState<string>('');
	const [selectedYearInfortuni, setSelectedYearInfortuni] = useState<string>('');
	const [influencerStatistics, setInfluencerStatistics] = useState<
		InfluencerStatistics | undefined
	>(undefined);
	const { /* playerDetail, isLoading, isError,*/ updateNote, setNoteMessageError } =
		usePlayerDetail(props.playerId);
	const { playerDetail } = props;

	useEffect(() => {
		if (props.playerId) {
			findStatistics(props.playerId)
				.then((ret) => {
					setPlayerStatistics(ret);
					setSelectedYearStatistiche(Object.keys(ret.performances || {})[0]);
					setSelectedYearInfortuni(Object.keys(ret.injuries || {})[0]);
				})
				.catch((e) => {
					setPlayerStatistics(null);
				});

			getNote(props.playerId)
				.then((ret) => {
					setNoteState(ret);
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [props.playerId]);

	useEffect(() => {
		if (
			!props.playerId ||
			!props.selectedInfluencer ||
			props.selectedInfluencer === undefined
		) {
			return;
		}

		getInfluencerStatistics(props.selectedInfluencer.id, props.playerId)
			.then((ret) => {
				setInfluencerStatistics(ret);
			})
			.catch((e) => {
				console.error(e);
			});
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedInfluencer, props.playerId]);

	return (
		<>
			{/*@ts-ignore*/}

			{!playerDetail || !playerStatistics ? (
				<div className={'d-flex flex-row align-items-center justify-content-center mt-5'}>
					<Spinner isGrow={true} color={'dark'} />
				</div>
			) : (
				<>
					<div>{renderPlayerIdentikit(playerDetail)}</div>
					<div>
						<RenderInfluencer
							isMobile={false}
							playerId={props.playerId}
							playerDetail={playerDetail}
							selectedInfluencer={props.selectedInfluencer}
							influencerStatistics={influencerStatistics}
							setInfluencerStatistics={setInfluencerStatistics}
							collapseState={collapseState}
							setCollapseState={setCollapseState}
							userSubscription={props.userSubscriptionInfo}></RenderInfluencer>
					</div>

					<div>
						<DatiFantalgoritmo
							playerDetail={playerDetail}
							role={props.userSubscriptionInfo.role}
							navigate={navigate}
						/>
					</div>
					<div>
						{renderStatistiche(
							playerDetail,
							playerStatistics,
							dropdownOpenStatistiche,
							setDropdownOpenStatistiche,
							selectedYearStatistiche,
							setSelectedYearStatistiche,
							statisticheCollapseState,
							setStatisticheCollapseState,
							false,
							props.userSubscriptionInfo.role,
							navigate,
						)}
					</div>
					<div>
						{renderInfortuni(
							playerStatistics,
							dropdownOpenInfortuni,
							setDropdownOpenInfortuni,
							selectedYearInfortuni,
							setSelectedYearInfortuni,
							false,
							props.userSubscriptionInfo.role,
							navigate,
						)}
					</div>
					<div>
						{renderNote(
							props.playerId,
							noteState,
							setNoteState,
							updateNote,
							setNoteMessageError,
							deleteNote,
						)}
					</div>
				</>
			)}
		</>
	);
};

export default PlayerDetailsDesktop;
