import React, { FC, ReactNode, useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import WrapperOverlay from './WrapperOverlay';
import HeaderRoutes from '../Header/HeaderRoutes';
import FooterRoutes from '../Footer/FooterRoutes';
import ThemeContext from '../../contexts/themeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomEventName } from '../../event.type';

interface IWrapperContainerProps {
	children: ReactNode;
	className?: string;
}
export const WrapperContainer: FC<IWrapperContainerProps> = ({
	children,
	className = undefined,
	...props
}) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames(
				'wrapper',
				{ 'wrapper-right-panel-active': rightPanel },
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};
WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

const Wrapper = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const goToHomePage = useCallback(() => navigate('/'), [navigate]);

	useEffect(() => {
		window.addEventListener(CustomEventName.ON_AUTH_SUCCESS, () => {
			if (location.pathname !== '/login') {
				navigate(location.pathname);
				return;
			}
			goToHomePage();
		});
		return () => {
			window.removeEventListener(CustomEventName.ON_AUTH_SUCCESS, () => -1);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<WrapperContainer>
				<HeaderRoutes />
				<Content />
				<FooterRoutes />
			</WrapperContainer>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
