import { createContext, ReactNode, useEffect, useState } from 'react';
import { CustomEventName } from '../event.type';
import { AuctionData } from '../api/auction/auction.model';

interface AuctionContextType {
	auctionId: string;
	setAuctionId: (auctionId: string) => void;
	auctionStatus: AuctionStatus | undefined;
	setAuctionStatus: (status: AuctionStatus | undefined) => void;
	teamsAuction: Team[];
	setTeamsAuction: (teams: Team[]) => void;
	teams: TeamAuction[] | any;
	setTeams: (teams: TeamAuction[] | any) => void;
	selectedTeam: Team | undefined;
	setSelectedTeam: (team: Team | undefined) => void;
	alreadySelectedTeam: any;
	setAlreadySelectedTeam: (alreadySelectedTeam: any) => void;
	invalidCode: boolean;
	setInvalidCode: (invalidCode: boolean) => void;
	isAuctionActive: boolean;
	setIsAuctionActive: (isAuctionActive: boolean) => void;
	auctionHistory: any[];
	setAuctionHistory: (auctionHistory: any[]) => void;
}

type Team = {
	teamId: string;
	name: string;
	available: boolean;
};

interface TeamAuction {
	id: string;
	name: string;
	goalkeepers: any[];
	midfielders: any[];
	defenders: any[];
	forwards: any[];
	initialCredits: number;
	remainingCredits: number;
}

interface User {
	name: string;
	teamId: string;
	teamName: string;
	personal: boolean;
}

interface AuctionStatus {
	numberUsers: number;
	numberVisitors: number;
	users: {
		[key: string]: User;
	};
}

export interface AuctionHistory {
	dateTime: Date;
	message: string;
}

export const AuctionContext = createContext<AuctionContextType | undefined>(undefined);

export const AuctionProvider = ({ children }: { children?: ReactNode }) => {
	const [auctionId, setAuctionId] = useState<string>('');
	const [auctionStatus, setAuctionStatus] = useState<AuctionStatus>();
	const [teamsAuction, setTeamsAuction] = useState<Team[]>([]);
	const [teams, setTeams] = useState<TeamAuction[]>([]);
	const [selectedTeam, setSelectedTeam] = useState<Team>();
	const [alreadySelectedTeam, setAlreadySelectedTeam] = useState<any>(undefined);
	const [invalidCode, setInvalidCode] = useState<boolean>(true);
	const [isAuctionActive, setIsAuctionActive] = useState<boolean>(false);
	const [auctionHistory, setAuctionHistory] = useState<AuctionHistory[]>([]);

	useEffect(() => {
		setAuctionId(localStorage.getItem('auctionId') || '');
	}, []);

	useEffect(() => {
		if (auctionId) {
			localStorage.setItem('auctionId', auctionId);
		}
	}, [auctionId]);

	useEffect(() => {
		const auctionStatusString = localStorage.getItem('auctionStatus');
		if (auctionStatusString) {
			setAuctionStatus(JSON.parse(auctionStatusString));
		}
	}, []);

	useEffect(() => {
		if (auctionStatus) {
			localStorage.setItem('auctionStatus', JSON.stringify(auctionStatus));
		}
	}, [auctionStatus]);

	return (
		<AuctionContext.Provider
			value={{
				auctionId,
				setAuctionId,
				auctionStatus,
				setAuctionStatus,
				teamsAuction,
				setTeamsAuction,
				teams,
				setTeams,
				selectedTeam,
				setSelectedTeam,
				alreadySelectedTeam,
				setAlreadySelectedTeam,
				invalidCode,
				setInvalidCode,
				isAuctionActive,
				setIsAuctionActive,
				auctionHistory,
				setAuctionHistory,
			}}>
			{children}
		</AuctionContext.Provider>
	);
};
