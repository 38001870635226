import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { planAPI } from '../../api/plan/plan.api';

enum PlanAction {
	FIND_ALL = 'plan/findAll',
}

const findAll = createAsyncThunk(
	PlanAction.FIND_ALL,
	async (reload: boolean, thunkAPI) => {
		try {
			// when fetch leagues then set the first one as selected league
			const plans = await planAPI.findAll();
			return plans;
		} catch (e) {
			throw e;
		}
	},
	{
		condition(reload, api): boolean {
			const { plan } = api.getState() as RootState;
			return plan.list.length === 0 || plan.error || reload;
		},
	},
);

export const planAction = {
	findAll,
};
