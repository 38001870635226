import { createSlice } from '@reduxjs/toolkit';
import { ImportedLeague } from '../../api/league/league.model';
import { importLeagueAction } from './importLeague.action';

interface ImportLeagueState {
	importedLeagues: ImportedLeague[];
	loading: boolean;
	isAddingLeague: boolean;
	error: boolean;
	errorMessage: string;
}

// Define the initial state using that type
const initialState: ImportLeagueState = {
	importedLeagues: [],
	loading: false,
	isAddingLeague: false,
	error: false,
	errorMessage: '',
};

export const importLeagueSlice = createSlice({
	name: 'importLeague',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(importLeagueAction.importLeague.pending, (state, action) => {
			state.loading = true;
			state.error = false;
			state.errorMessage = '';
		});
		builder.addCase(importLeagueAction.importLeague.fulfilled, (state, action) => {
			state.importedLeagues = action.payload;
			state.loading = false;
			state.error = false;
			state.errorMessage = '';
		});
		builder.addCase(importLeagueAction.importLeague.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
			state.errorMessage = action.payload ? (action.payload as string) : '';
		});
		builder.addCase(importLeagueAction.addImportedLeague.pending, (state, action) => {
			state.isAddingLeague = true;
			state.error = false;
		});
		builder.addCase(importLeagueAction.addImportedLeague.fulfilled, (state, action) => {
			state.importedLeagues = [];
			state.isAddingLeague = false;
			state.error = false;
		});
		builder.addCase(importLeagueAction.addImportedLeague.rejected, (state, action) => {
			state.error = true;
			state.isAddingLeague = false;
		});
	},
});
