import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TeamBasic } from '../../../../../api/league/league.model';
import { findAll, updateTeams } from '../../../../../api/team/team.api';
import Button from '../../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import Input from '../../../../../components/bootstrap/forms/Input';
import InputGroup from '../../../../../components/bootstrap/forms/InputGroup';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../../components/bootstrap/Modal';
import { LeagueContext } from '../../../../../contexts/leagueContext';
import { ToastContext } from '../../../../../contexts/toastContext';
import { updateLeague } from '../../../../../api/league/league.api';

const TeamsSection = () => {
	const { setToast } = useContext(ToastContext);

	//TODO: deal with navigate duplicates teams list
	const [teams, setTeams] = useState<any[]>([]);
	const { action } = useParams();
	const navigate = useNavigate();

	const {
		selectedLeague,
		setSelectedLeague,
		newRules,
		setNewRules,
		newTeams,
		setNewTeams,
		setHasTeamsBeenUpdated,
	} = useContext(LeagueContext)!;

	const showTeamsSettings = Boolean(action?.includes('modifica_squadre'));
	const setShowTeamsSettings = (open: boolean) => {
		const newAction =
			(action
				? action
						.split('-')
						.filter((a) => a !== 'modifica_squadre')
						.join('-')
				: '') + (open ? 'modifica_squadre' : '');
		navigate(`/setup-lega/${newAction}`);
	};

	const [createdTeam, setCreatedTeam] = useState<any>({
		id: undefined,
		name: '',
		initialCredits: undefined,
	});

	useEffect(() => {
		if (selectedLeague !== undefined) {
			findAll(selectedLeague.id)
				.then((res) => {
					setTeams(res.teams || []);
					setNewTeams(res.teams || []);
				})
				.catch(() => {}); //ignores error on league delete
		}
	}, [selectedLeague]);

	useEffect(() => {
		if (newTeams) setNewRules({ ...selectedLeague.rules, participants: newTeams.length });
	}, [newTeams]);

	const onSave = async () => {
		if (newRules && newTeams && selectedLeague) {
			updateTeams(selectedLeague.id, newTeams).then((res: any) => {
				updateLeague(selectedLeague?.id, { ...selectedLeague, rules: newRules }).then(
					(res) => {
						setSelectedLeague(res);
					},
				);
				setToast({
					level: 'success',
					title: 'Impostazioni salvate',
					message: `Le impostazioni della lega ${selectedLeague.name} sono state aggiornate`,
				});
				setHasTeamsBeenUpdated(true);
				setShowTeamsSettings(false);
			});
		}
	};

	return (
		<>
			<Card>
				<CardHeader borderSize={3} borderColor={'light'}>
					<CardLabel icon='Archive' className={'mb-0'}>
						<CardTitle>
							<span className='h3 fw-bold mb-0 d-inline-block'>Squadre</span>
						</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							color='light'
							icon='Edit'
							onClick={() => {
								setNewTeams(teams || []);
								setShowTeamsSettings(true);
							}}>
							<span className='d-none d-sm-inline'>Modifica</span>
						</Button>
					</CardActions>
				</CardHeader>

				<CardBody>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th scope='col'>Nome</th>
								<th scope='col'>Crediti disponibili</th>
							</tr>
						</thead>
						<tbody>
							{teams && teams?.length > 0 && (
								<>
									{teams?.map((team: any, index: number) => {
										return (
											<tr key={index}>
												<td>{team.name}</td>
												<td>{team.initialCredits}</td>
											</tr>
										);
									})}
								</>
							)}
						</tbody>
					</table>
				</CardBody>
			</Card>
			<Modal
				isOpen={showTeamsSettings}
				setIsOpen={setShowTeamsSettings}
				isCentered={true}
				isAnimation={true}>
				<ModalHeader showClose={false} className={''} setIsOpen={setShowTeamsSettings}>
					<ModalTitle id={''}>Squadre</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							setShowTeamsSettings(false);
						}}
					/>
				</ModalHeader>
				<ModalBody className={''}>
					<div className='container p-0'>
						<form className='row'>
							<div className='d-flex justify-content-start align-items-center mb-2'>
								<div className='col-6 d-flex align-items-center'>
									<label>{'Crea Squadra'}</label>
								</div>
								<InputGroup>
									<Input
										style={{ width: 100 }}
										type='text'
										value={createdTeam.name}
										required={true}
										placeholder={'Nome Squadra'}
										isValid={createdTeam.name.length > 0}
										invalidFeedback={'Il nome della squadra è obbligatorio'}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											setCreatedTeam({
												id: undefined,
												name: e.target.value,
												initialCredits: 0,
											});
										}}
									/>
									<Button
										color={'success'}
										onClick={() => {
											setCreatedTeam({
												id: undefined,
												name: createdTeam.name,
												initialCredits: 0,
											});
											if (newTeams) {
												setNewTeams(newTeams.concat(createdTeam));
												setCreatedTeam({
													id: undefined,
													name: '',
													initialCredits: undefined,
												});
											}
										}}>
										+
									</Button>
								</InputGroup>
							</div>

							{newTeams &&
								newTeams?.length > 0 &&
								newTeams?.map((team: any, index: number) => {
									return (
										<div className={'mb-1'}>
											<div className={'d-flex'}>
												<div className='col-6 d-flex align-items-center'>
													<label>{'Squadra ' + (index + 1)}</label>
												</div>
												<div className='col-6'>
													<InputGroup className='d-flex align-items-center justify-content-start'>
														<Input
															key={index}
															type='text'
															value={team.name}
															required={true}
															isValid={team.name.length > 0}
															invalidFeedback={
																'Il nome della squadra è obbligatorio'
															}
															onChange={(
																e: ChangeEvent<HTMLInputElement>,
															) => {
																const newTeam = {
																	id: team.id,
																	name: e.target.value,
																	initialCredits:
																		team.initialCredits,
																};
																const updatedTeams = newTeams?.map(
																	(team, idx) =>
																		idx === index
																			? newTeam
																			: team,
																);
																setNewTeams(updatedTeams);
															}}
														/>
														<Input
															key={index}
															style={{ maxWidth: 80 }}
															type='number'
															value={team.initialCredits}
															required={false}
															onChange={(
																e: ChangeEvent<HTMLInputElement>,
															) => {
																if (Number(e.target.value) < 0) {
																	return;
																}
																const newTeam = {
																	id: team.id,
																	name: team.name,
																	initialCredits: Number(
																		e.target.value,
																	),
																};
																const updatedTeams = newTeams?.map(
																	(team, idx) =>
																		idx === index
																			? newTeam
																			: team,
																);
																setNewTeams(updatedTeams);
															}}
														/>
														<Button
															color={'danger'}
															isOutline={true}
															onClick={() => {
																const updatedTeams =
																	newTeams.filter(
																		(teamOld) =>
																			teamOld.id !== team.id,
																	);
																setNewTeams(updatedTeams);
															}}>
															-
														</Button>
													</InputGroup>
												</div>
											</div>
										</div>
									);
								})}
						</form>
					</div>
				</ModalBody>
				<ModalFooter className={''}>
					<Button icon='save' color='info' onClick={onSave}>
						Salva
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default TeamsSection;
