import { apiBuilder } from '../api.config';
import { Auction, AuctionData } from './auction.model';

export const createAuction = async (request: any): Promise<any> => {
	const URL = `auctions`;
	try {
		const response = await apiBuilder.post<any, any>(URL, request);
		return response;
	} catch (error: any) {
		if (error.response) {
			// Il server ha risposto con uno stato diverso da 2xx
			console.error('Error response:', error.response);
		} else if (error.request) {
			// La richiesta è stata fatta ma non è stata ricevuta alcuna risposta
			console.error('Error request:', error.request);
		} else {
			// Qualcosa è andato storto nella configurazione della richiesta
			console.error('Error message:', error.message);
		}
		console.error('Error config:', error.config);
		throw error; // Rilancia l'errore per gestirlo nel chiamante
	}
};

export const getAuctionCSV = async (auctionId: string) => {
	const URL = `auctions/${auctionId}/exports`;
	//auctions/${auctionId}/exports
	const response = await apiBuilder.getBlob<any>(URL);
	return {
		response,
	};
};

export const getAuctionHistory = (leagueId: string): Promise<AuctionData[]> => {
	const URL = `auctions/leagues/${leagueId}`;
	return apiBuilder.get<AuctionData[]>(URL);
};

export const translateCode = (code: string): Promise<Auction> => {
	const URL = `auctions/codes/${code}`;
	return apiBuilder.get<Auction>(URL);
};

export const getAuctionData = (auctionId: string): Promise<Auction> => {
	const URL = `auctions/${auctionId}`;
	return apiBuilder.get<Auction>(URL);
};
