declare global {
	interface Window {
		MSStream?: any;
	}
}

import { useKeycloak } from '@react-keycloak/web';
import Cookies from 'js-cookie';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { joinReferralGroup } from '../../../api/user/user.api';
import Spinner from '../../../components/bootstrap/Spinner';
import Icon from '../../../components/icon/Icon';
import { InfluencerContext } from '../../../contexts/influencerContext';
import ThemeContext from '../../../contexts/themeContext';
import { CustomEventName } from '../../../event.type';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Influencers from '../../../layout/Influencers/Influencers';
import Leagues from '../../../layout/Leagues/Leagues';
import Navigation from '../../../layout/Navigation/Navigation';
import { footerMenu, mainMenu } from '../../../menu';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { keycloak, initialized } = useKeycloak();
	const navigate = useNavigate();

	const [tokenReceived, setTokenReceived] = useState<boolean>(false);
	const { setHasBeenReferred } = useContext(InfluencerContext)!;
	const [platform, setPlatform] = useState<string>('');
	const [whichApp, setWhichApp] = useState<string>('');

	useEffect(() => {
		const handleTokenSuccess: () => void = () => {
			setTokenReceived(true);
		};

		window.addEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);

		return () => {
			window.removeEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);
		};
	}, []);

	const handleOnClick = useCallback(() => navigate('../login'), [navigate]);

	const detectPlatform = () => {
		const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

		if (/android/i.test(userAgent)) {
			return 'Android';
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS';
		}

		return 'unknown';
	};

	const detectApp = () => {
		const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

		const isInstagramInApp = /Instagram/.test(userAgent);
		const isFacebookInApp = /FBAN|FBAV/.test(userAgent);
		const isTwitchInApp = /Twitch/.test(userAgent);
		const isTikTokInApp = /TikTok/.test(userAgent);
		const isYouTubeInApp = /YouTube|YouTubeApp/.test(userAgent);

		if (isInstagramInApp) {
			return 'Instagram';
		} else if (isFacebookInApp) {
			return 'Facebook';
		} else if (isTwitchInApp) {
			return 'Twitch';
		} else if (isTikTokInApp) {
			return 'TikTok';
		} else if (isYouTubeInApp) {
			return 'YouTube';
		} else {
			return '';
		}
	};

	useEffect(() => {
		setPlatform(detectPlatform());
		setWhichApp(detectApp());

		const existingReferralCode = Cookies.get('referralCode');
		const existingReferralCodeExpiry = Cookies.get('referralCodeExpiry');

		if (!existingReferralCode && !existingReferralCodeExpiry) {
			const referral = location.pathname.split('/')[1];
			const code = location.pathname.split('/')[2];

			if (code && referral === 'ref') {
				Cookies.set('referralCode', code);
				Cookies.set('referralCodeExpiry', (new Date().getTime() + 86400000).toString()); // 24 ore
			}
		} else if (existingReferralCode && existingReferralCodeExpiry) {
			const now = new Date();
			const expiryDate = new Date(parseInt(existingReferralCodeExpiry, 10));

			if (now > expiryDate) {
				const referral = location.pathname.split('/')[1];
				const code = location.pathname.split('/')[2];

				if (code && referral === 'ref') {
					Cookies.set('referralCode', code);
					Cookies.set('referralCodeExpiry', (new Date().getTime() + 86400000).toString()); // 24 ore
				}
			} else {
				const validCode = Cookies.get('referralCode');
				const validExpiry = Cookies.get('referralCodeExpiry');

				if (validCode && validExpiry && tokenReceived) {
					joinReferralGroup(validCode)
						.then((res) => {
							setHasBeenReferred(true);
						})
						.catch((err) => {
							if (err.response && err.response.status === 409) {
							} else {
								console.error('An error occurred:', err);
							}
						});

					if (location.pathname !== '/') {
						navigate('/');
					}
				}
			}
		}

		if (!keycloak.authenticated && initialized && whichApp === '') {
			handleOnClick();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keycloak, initialized]);

	const { t } = useTranslation(['translation', 'menu']);

	const getLink = (): string => {
		if (platform === 'Android') {
			return 'intent:https://dev.app.fantalgoritmo.it#Intent;end';
		} else if (platform === 'iOS') {
			return 'x-safari-https://dev.app.fantalgoritmo.it';
		} else {
			return 'https://app.fantalgoritmo.it';
		}
	};

	return (
		<Aside>
			{!initialized ? (
				<div className={'d-flex flex-row align-items-center justify-content-center mt-5'}>
					<Spinner isSmall={true} color={'light'} />
				</div>
			) : (
				<>
					<AsideHead>
						<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
						<Influencers />
						<Leagues />
					</AsideHead>
					<AsideBody>
						<Navigation menu={mainMenu} id='aside-dashboard' />
					</AsideBody>
					<AsideFoot>
						<nav aria-label='aside-bottom-user-menu-2'>
							<div className='navigation'>
								<Navigation menu={footerMenu} id='aside-dashboard' />
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() => keycloak.logout()}>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<Icon
												icon='Logout'
												className='navigation-icon'
												color={'danger'}
											/>
											<span className='navigation-text text-danger'>
												{t('menu:Logout') as ReactNode}
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav>
					</AsideFoot>
				</>
			)}
		</Aside>
	);
};

export default DefaultAside;
