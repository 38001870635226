import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import Button from '../../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../../components/bootstrap/Modal';
import Input from '../../../../../components/bootstrap/forms/Input';
import { useLeagueNameSection } from '../../../../../hooks/businessLogic/useLeagueNameSection';
import { ToastContext } from '../../../../../contexts/toastContext';
import { LeagueContext } from '../../../../../contexts/leagueContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
	getLeagueData,
	updateLeague,
	deleteLeague,
	findAll,
} from '../../../../../api/league/league.api';
import placeholderLogoLega from '../../../../../assets/img/placeholder-logo-lega.svg';

interface LeagueNameSectionProps {
	showEditLeagueName: boolean;
	setShowEditLeagueName: (show: boolean) => void;
}

const LeagueNameSection = (props: LeagueNameSectionProps) => {
	const {
		leagues,
		setLeagues,
		selectedLeague,
		setSelectedLeague,
		newName,
		setNewName,
		setHasNameBeenUpdated,
	} = useContext(LeagueContext)!;

	function onSave() {
		if (newName.length !== 0) {
			updateLeague(selectedLeague.id, { ...selectedLeague, name: newName }).then((res) => {
				//setSelectedLeague(res);
				const updatedLeagues = leagues.map((league) => {
					if (league.id === selectedLeague.id) {
						return { ...league, name: newName };
					}
					return league;
				});
				setLeagues(updatedLeagues);
				setHasNameBeenUpdated(true);
			});
			props.setShowEditLeagueName(false);
		}
	}

	return (
		<>
			<div className="col-12">
				<div
					className="d-flex justify-content-start align-items-center mb-3"
					style={{ paddingRight: '1em' }}>
					<img
						style={{
							width: 60,
							height: 60,
							borderRadius: '50%',
						}}
						src={placeholderLogoLega}></img>
					<span className="display-4 fw-bold me-3">{selectedLeague.name}</span>
					<Button
						color="light"
						icon="Edit"
						onClick={() => {
							setNewName(selectedLeague.name);
							props.setShowEditLeagueName(true);
						}}>
						<span className="d-none d-sm-inline">Modifica</span>
					</Button>
				</div>
			</div>
			<Modal
				isOpen={props.showEditLeagueName}
				setIsOpen={props.setShowEditLeagueName}
				isCentered={true}
				isAnimation={true}>
				<ModalHeader
					showClose={false}
					className={''}
					setIsOpen={props.setShowEditLeagueName}>
					<ModalTitle id={''}>Generali</ModalTitle>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
						onClick={() => {
							props.setShowEditLeagueName(false);
						}}
					/>
				</ModalHeader>
				<ModalBody className={''}>
					<div className="container p-0">
						<div className="row">
							<div className={'mb-3'}>
								<div className={'d-flex'}>
									<div className='col-6 d-flex align-items-center'>
										<label>Nome</label>
									</div>
									<div className='col-6'>
										<Input
											type='text'
											value={newName}
											required={true}
											isValid={newName.length !== 0}
											invalidFeedback={'Il nome della lega è obbligatorio'}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												setNewName(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className={''}>
					<Button icon='save' color='info' onClick={onSave}>
						Salva
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default LeagueNameSection;
