import { createAsyncThunk } from '@reduxjs/toolkit';
import { profileAPI } from '../../api/profile/profile.api';
import { Profile } from '../../api/profile/profile.model';
import { RootState } from '../store';

enum ProfileAction {
	FIND_USER_INFO = 'profile/findUserInfo',
	UPDATE_USER_INFO = 'profile/updateUserInfo',
	GET_USER_SUBSCRIPTION_INFO = 'profile/getUserSubscriptionInfo'
}

const findUserInfo = createAsyncThunk(
	ProfileAction.FIND_USER_INFO,
	async (_, thunkAPI) => {
		try {
			const userInfo = await profileAPI.findUserInfo();
			return userInfo;
		} catch (e) {
			throw e;
		}
	},
	{
		condition(arg, api): boolean {
			const { profile } = api.getState() as RootState;
			return !profile.profile || profile.error;
		},
	},
);

const updateUserInfo = createAsyncThunk(
	ProfileAction.UPDATE_USER_INFO,
	async (arg: Partial<Profile>, thunkAPI) => {
		try {
			const userInfo = await profileAPI.updateUserInfo(arg);
			return userInfo;
		} catch (e) {
			throw e;
		}
	},
);

const getUserSubscriptionInfo = createAsyncThunk(
	ProfileAction.GET_USER_SUBSCRIPTION_INFO,
	async (_, thunkAPI) => {
		try {
			const userSubscriptionInfo = await profileAPI.getUserSubscriptionInfo();
			return userSubscriptionInfo;
		} catch (e) {
			throw e;
		}
	},
	{
		condition(arg, api): boolean {
			const { profile } = api.getState() as RootState;
			return !profile.profile || profile.error;
		},
	},
);

export const profileAction = {
	findUserInfo,
	updateUserInfo,
	getUserSubscriptionInfo
};
