import { apiBuilder } from '../api.config';
import { TeamBasic } from '../league/league.model';
import { Team, TeamDetail } from './team.model';

export const findAll = (leagueId: string): Promise<any> => {
	const URL = `leagues/${leagueId}/teams`;
	return apiBuilder.get<any>(URL);
};

export const updateTeams = (leagueId: string, teams: any): Promise<any[]> => {
	const URL = `leagues/${leagueId}/teams`;
	return apiBuilder.post<any[], { teams: any[] }>(URL, { teams });
};

const findDetail = (leagueId: string, teamId: string): Promise<TeamDetail> => {
	const URL = `leagues/${leagueId}/teams/${teamId}`;
	return apiBuilder.get<TeamDetail>(URL);
}; //TODO: remove

export const teamAPI = {
	findAll,
	findDetail,
};
