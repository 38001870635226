// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-swipeable-list/dist/styles.css';
import { getAuctionHistory } from '../../../api/auction/auction.api';
import { AuctionData } from '../../../api/auction/auction.model';
import placeholderEmptyAsta from '../../../assets/img/placeholder-empty-asta.svg';
import Ads from '../../../components/Ads';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { AuctionContext } from '../../../contexts/auctionContext';
import AuthContext from '../../../contexts/authContext';
import { LeagueContext } from '../../../contexts/leagueContext';
import { CustomEventName } from '../../../event.type';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { PastAuctions } from './components/pastAuctions';
import { SubHeaderAuction } from './components/subheaderAuction';
import { ShareModal } from '../../../components/ShareModal';

const Auction = () => {
	const { userSubscriptionInfo } = useContext(AuthContext);
	const { selectedLeague } = useContext(LeagueContext)!;
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const { action } = useParams();

	const [auctionHistory, setAuctionHistory] = useState<AuctionData[] | null>(null);

	const { auctionId, setAuctionId } = useContext(AuctionContext)!;

	const [tokenReceived, setTokenReceived] = useState<boolean>(false);

	useEffect(() => {
		const handleTokenSuccess: () => void = () => {
			setTokenReceived(true);
		};

		window.addEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);

		return () => {
			window.removeEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);
		};
	}, []);

	const [isComponentMounted, setIsComponentMounted] = useState(true);
	const [loading, setLoading] = useState(true); // New loading state

	useEffect(() => {
		if (selectedLeague || (isComponentMounted && selectedLeague)) {
			setLoading(true); // Start loading
			getAuctionHistory(selectedLeague.id)
				.then((ret) => {
					setAuctionHistory(ret.slice().reverse());
				})
				.catch((error) => {
					console.error('Failed to fetch auction history:', error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [selectedLeague, tokenReceived, location.pathname, isComponentMounted]);

	useEffect(() => {
		// This effect runs only once when the component mounts
		setIsComponentMounted(false);
	}, []);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize(); // Check initially
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<PageWrapper
			title='Aste'
			isProtected={true}
			showAds={!userSubscriptionInfo?.paidSubscription}>
			<SubHeaderAuction
				action={action}
				auctionCode={auctionId}
				setAuctionCode={setAuctionId}
			/>
			<Page container='fluid' className='pt-0'>
				<div className='row'>
					<div>
						<Ads />

						<Alert
							color={'danger'}
							isLight={true}
							borderWidth={3}
							rounded={'default'}
							isDismissible
							className='mt-2'>
							<AlertHeading tag={'h4'}>Avviso Importante</AlertHeading>
							Alcune funzionalità non sono ancora attive, appena saranno disponibili
							online, riceverai una comunicazione al riguardo.
						</Alert>
					</div>

					{!selectedLeague && (
						<div
							className={
								'd-flex flex-column justify-content-center align-items-center'
							}>
							<img
								src={placeholderEmptyAsta}
								style={{ maxWidth: isMobile ? '50%' : '30%' }}></img>
							<div className='container-fluid row justify-content-center text-center'>
								Selezionare una lega per visualizzare lo storico delle aste o
								avviare una nuova asta.
							</div>
						</div>
					)}
					{selectedLeague && auctionHistory?.length === 0 && (
						<div
							className={
								'd-flex flex-column justify-content-center align-items-center'
							}>
							<img
								src={placeholderEmptyAsta}
								style={{ maxWidth: isMobile ? '50%' : '30%' }}></img>
							<div className='container-fluid row justify-content-center text-center'>
								Non ci sono aste già effettuate. Avvia la tua prima asta!
							</div>
						</div>
					)}

					{selectedLeague && auctionHistory && auctionHistory.length > 0 && (
						<div className='h-full top-0'>
							{auctionHistory.map((auction) => {
								return (
									<div>
										<Card key={auction.id}>
											<CardBody>
												<PastAuctions
													isMobile={isMobile}
													action={action}
													auctionHistoryValue={auction}
												/>
											</CardBody>
										</Card>
									</div>
								);
							})}
						</div>
					)}
					<ShareModal
						isMobile={isMobile}
						parentUri={'/asta'}
						action={action}
						auctionId={auctionId}
					/>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Auction;
