import { RootState } from '../store';
import { League, TeamBasic } from '../../api/league/league.model';

const getSelectedLeague = (state: RootState): League | undefined => state.selectedLeague.league;
// const getPersonalTeam = (state: RootState): TeamBasic | undefined =>
// 	state.selectedLeague.league?.teams.find((t) => t.personal);

export const selectedLeagueSelector = {
	getSelectedLeague,
	//getPersonalTeam,
};
