import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getInfluencerStatistics } from '../../../../api/influencer/influencer.api';
import { Influencer, InfluencerStatistics } from '../../../../api/influencer/influencer.model';
import { deleteNote, findDetail, findStatistics, getNote } from '../../../../api/player/player.api';
import {
	ExtendedPlayerDTO,
	PlayerDetail,
	PlayerStatistics,
} from '../../../../api/player/player.model';
import AddPlayerToTeam from '../../../../components/addPlayerToTeam';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Spinner from '../../../../components/bootstrap/Spinner';
import Icon from '../../../../components/icon/Icon';
import { getOpen, setOpen } from '../../../../helpers/navigation';
import { usePlayerDetail } from '../../../../hooks/businessLogic/usePlayerDetail';
import {
	RenderInfluencer,
	renderInfortuni,
	renderNote,
	renderPlayerIdentikit,
	renderStatistiche,
} from './renderCommonItems';
import DatiFantalgoritmo from './DatiFantalgoritmo';

interface PlayerDetailProps {
	playerId: string | undefined;
	setPlayerId: (value: string) => void;
	refreshList: () => void;
	renderPlayerPreferenceButtons: (v: string) => React.ReactNode;
	selectedInfluencer?: Influencer | undefined;
	userSubscriptionInfo: any;
	playerDetail: PlayerDetail | null;
	parentUri: string;
	calledPlayer?: boolean | undefined;
}

const PlayerDetailsMobile = (props: PlayerDetailProps) => {
	const [collapseState, setCollapseState] = useState<boolean>(true);
	const [statisticheCollapseState, setStatisticheCollapseState] = useState(false);
	const [noteState, setNoteState] = useState<any>(undefined);
	const navigate = useNavigate();
	const [playerStatistics, setPlayerStatistics] = useState<PlayerStatistics | null>(null);
	const [dropdownOpenStatistiche, setDropdownOpenStatistiche] = useState(false);
	const [dropdownOpenInfortuni, setDropdownOpenInfortuni] = useState(false);
	const { updateNote, setNoteMessageError } = usePlayerDetail(props.playerId || '');
	const [influencerStatistics, setInfluencerStatistics] = useState<
		InfluencerStatistics | undefined
	>(undefined);
	const { action } = useParams();

	const [selectedYearStatistiche, setSelectedYearStatistiche] = useState('');
	const [selectedYearInfortuni, setSelectedYearInfortuni] = useState('');

	const { playerDetail } = props;
	useEffect(() => {
		if (props.playerId) {
			findStatistics(props.playerId)
				.then((ret) => {
					setPlayerStatistics(ret);
					setSelectedYearStatistiche(Object.keys(ret.performances || {})[0]);
					setSelectedYearInfortuni(Object.keys(ret.injuries || {})[0]);
				})
				.catch((e) => {
					setPlayerStatistics(null);
				});

			getNote(props.playerId)
				.then((ret) => {
					setNoteState(ret);
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [props.playerId]);

	useEffect(() => {
		const handleBackButtonEvent = (e: any) => {
			props.setPlayerId('');
		};

		window.addEventListener('popstate', handleBackButtonEvent);

		return () => {
			window.removeEventListener('popstate', handleBackButtonEvent);
		};
	}, []);

	useEffect(() => {
		if (
			!props.playerId ||
			!props.selectedInfluencer ||
			props.selectedInfluencer === undefined
		) {
			return;
		}

		getInfluencerStatistics(props.selectedInfluencer.id, props.playerId)
			.then((ret) => {
				setInfluencerStatistics(ret);
			})
			.catch((e) => {
				console.error(e);
			});
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedInfluencer, props.playerId]);

	return (
		<>
			{!playerDetail || !playerStatistics || !props.playerId ? (
				<div className={'d-flex flex-row align-items-center justify-content-center mt-5'}>
					<Spinner isGrow={true} color={'dark'} />
				</div>
			) : (
				<>
					<Modal
						id={`player-detail`}
						setIsOpen={() => {}}
						titleId='playerDetails'
						isOpen={
							Boolean(props.playerId) &&
							!Boolean(getOpen(action, 'assegna')) &&
							!props.calledPlayer
						}
						isBackdrop={false}
						isScrollable={true}>
						<ModalHeader className='d-flex flex-column '>
							<div className='d-flex justify-content-between align-items-center w-100'>
								<ModalTitle id='playerDetails'>Dettagli Giocatore</ModalTitle>
								<Button
									className='p-1 px-2 rounded-1'
									onClick={() => {
										setOpen(
											navigate,
											props.parentUri,
											action,
											'dettagli',
											[],
											false,
										);
										props.setPlayerId('');
									}}
									style={{ color: 'gray' }}>
									<Icon icon={'Close'} size={'2x'}></Icon>
								</Button>
							</div>
							<div className='d-flex justify-content-between align-items-center w-100 gap-2 mt-3'>
								{props.playerId &&
									props.renderPlayerPreferenceButtons(props.playerId)}
							</div>
						</ModalHeader>
						<ModalBody>
							{renderPlayerIdentikit(playerDetail)}
							<RenderInfluencer
								playerId={props.playerId}
								playerDetail={playerDetail}
								selectedInfluencer={props.selectedInfluencer}
								influencerStatistics={influencerStatistics}
								setInfluencerStatistics={setInfluencerStatistics}
								collapseState={collapseState}
								setCollapseState={setCollapseState}
								userSubscription={props.userSubscriptionInfo}
								isMobile={true}></RenderInfluencer>
							<DatiFantalgoritmo
								playerDetail={playerDetail}
								role={props.userSubscriptionInfo.role}
								navigate={navigate}
							/>
							{renderStatistiche(
								playerDetail,
								playerStatistics,
								dropdownOpenStatistiche,
								setDropdownOpenStatistiche,
								selectedYearStatistiche,
								setSelectedYearStatistiche,
								statisticheCollapseState,
								setStatisticheCollapseState,
								true,
								props.userSubscriptionInfo.role,
								navigate,
							)}
							{renderInfortuni(
								playerStatistics,
								dropdownOpenInfortuni,
								setDropdownOpenInfortuni,
								selectedYearInfortuni,
								setSelectedYearInfortuni,
								true,
								props.userSubscriptionInfo.role,
								navigate,
							)}
							{renderNote(
								props.playerId,
								noteState,
								setNoteState,
								updateNote,
								setNoteMessageError,
								deleteNote,
							)}
						</ModalBody>
					</Modal>
				</>
			)}
		</>
	);
};

export default PlayerDetailsMobile;
