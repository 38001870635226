// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import 'react-swipeable-list/dist/styles.css';
import { Influencer } from '../../../api/influencer/influencer.model';
import { deleteMainInfluencerIdAPI, setMainInfluencerIdAPI } from '../../../api/user/user.api';
import placeHolderInfluencer from '../../../assets/img/placeholder-influencer.svg';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import AuthContext from '../../../contexts/authContext';
import { InfluencerContext } from '../../../contexts/influencerContext';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

let timeout: NodeJS.Timeout;

const InfluencersList = () => {
	const { userSubscriptionInfo } = useContext(AuthContext);

	const { selectedInfluencer, setSelectedInfluencer, influencersList, setInfluencersList } =
		useContext(InfluencerContext) || {};
	const [isMobile, setIsMobile] = useState(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [newList, setNewList] = useState<Influencer[]>([]);

	useEffect(() => {
		if (influencersList && influencersList.length > 0 && newList.length === 0) {
			setNewList(influencersList);
		}
	}, [influencersList, newList]);

	const renderSearchInput = () => {
		return (
			<Input
				id='searchInput'
				type='search'
				className='form-control border-0 shadow-none bg-transparent'
				placeholder='Cerca Creator per nome utente'
				value={searchValue}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const newSearchValue = e.target.value;
					setSearchValue(newSearchValue);
					if (timeout) {
						clearTimeout(timeout);
					}
					timeout = setTimeout(() => {
						if (newSearchValue === '') {
							if (influencersList) setNewList(influencersList);
						} else {
							onSearchInfluencers(newSearchValue);
						}
					}, 500);
				}}
			/>
		);
	};

	const onSearchInfluencers = (searchValue: string) => {
		const results = influencersList?.filter((influencer) =>
			influencer.username.includes(searchValue),
		);

		if (results) {
			setNewList?.(results);
		}
	};

	const renderInfluencerCard = (influencer: Influencer) => {
		return (
			<Card key={influencer.id} className='rounded-1' style={{ minWidth: 320 }}>
				<CardBody className='d-flex flex-column gap-2'>
					<div className='d-flex align-items-center justify-content-start gap-3'>
						<img
							style={{ maxWidth: 90, maxHeight: 90 }}
							className='rounded-1 '
							src={
								!influencer.pathLogo ? placeHolderInfluencer : influencer.pathLogo
							}></img>
						<div className='d-flex flex-column w-100'>
							<div className='d-flex flex-column justify-content-start align-items-start'>
								<h6 className='mb-0'>{influencer.name}</h6>
								<p className='text-muted mb-0'>@{influencer.username}</p>
							</div>
							<div className='d-flex align-items-end justify-content-end'>
								<Button
									className={`p-2 px-3 mt-1 rounded-1 w-100 ${
										selectedInfluencer?.id === influencer.id
											? 'bg-l25-success text-success'
											: 'bg-light'
									}`}
									onClick={() => {
										if (selectedInfluencer?.id === influencer.id) {
											setSelectedInfluencer?.(undefined);
											deleteMainInfluencerIdAPI();
										} else {
											setSelectedInfluencer?.(influencer);
											setMainInfluencerIdAPI(influencer.id);
										}
									}}>
									{selectedInfluencer?.id === influencer.id ? 'Seguito' : 'Segui'}
								</Button>
							</div>
							{/* <p className='mb-3'>Follower {influencer.followers}</p> */}
						</div>
					</div>
					<div className='d-flex justify-content-center align-items-center gap-1'>
						{influencer.facebookUsername && (
							<Badge
								className='rounded-pill bg-l25-info text-info p-1 px-3'
								onClick={() =>
									window.open(
										`https://www.facebook.com/${influencer.facebookUsername}`,
										'_blank',
									)
								}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									x='0px'
									y='0px'
									width='20'
									height='20'
									viewBox='0 0 48 48'>
									<path
										fill='#039be5'
										d='M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z'></path>
									<path
										fill='#fff'
										d='M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z'></path>
								</svg>
							</Badge>
						)}
						{influencer.youtubeUsername && (
							<Badge
								className='rounded-pill bg-l25-danger text-danger p-1 px-3'
								onClick={() =>
									window.open(
										`https://www.youtube.com/@${influencer.youtubeUsername}`,
										'_blank',
									)
								}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									x='0px'
									y='0px'
									width='20'
									height='20'
									viewBox='0 0 48 48'>
									<path
										fill='#FF3D00'
										d='M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z'></path>
									<path fill='#FFF' d='M20 31L20 17 32 24z'></path>
								</svg>
							</Badge>
						)}
						{influencer.instagramUsername && (
							<Badge
								className='rounded-pill bg-l25-secondary text-secondary p-1 px-3'
								onClick={() =>
									window.open(
										`https://www.instagram.com/${influencer.instagramUsername}`,
										'_blank',
									)
								}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									x='0px'
									y='0px'
									width='20'
									height='20'
									viewBox='0,0,256,256'>
									<g
										fill='#ed3792'
										fillRule='nonzero'
										stroke='none'
										strokeWidth='1'
										strokeLinecap='butt'
										strokeLinejoin='miter'
										strokeMiterlimit='10'
										strokeDasharray=''
										strokeDashoffset='0'
										fontFamily='none'
										fontWeight='none'
										fontSize='none'
										textAnchor='none'
										style={{ mixBlendMode: 'normal' }}>
										<g transform='scale(10.66667,10.66667)'>
											<path d='M8,3c-2.757,0 -5,2.243 -5,5v8c0,2.757 2.243,5 5,5h8c2.757,0 5,-2.243 5,-5v-8c0,-2.757 -2.243,-5 -5,-5zM8,5h8c1.654,0 3,1.346 3,3v8c0,1.654 -1.346,3 -3,3h-8c-1.654,0 -3,-1.346 -3,-3v-8c0,-1.654 1.346,-3 3,-3zM17,6c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM12,7c-2.757,0 -5,2.243 -5,5c0,2.757 2.243,5 5,5c2.757,0 5,-2.243 5,-5c0,-2.757 -2.243,-5 -5,-5zM12,9c1.654,0 3,1.346 3,3c0,1.654 -1.346,3 -3,3c-1.654,0 -3,-1.346 -3,-3c0,-1.654 1.346,-3 3,-3z'></path>
										</g>
									</g>
								</svg>
							</Badge>
						)}
						{influencer.tiktokUsername && (
							<Badge
								className='rounded-pill bg-l75-light text-warning p-1 px-3'
								onClick={() =>
									window.open(
										`https://www.tiktok.com/@${influencer.tiktokUsername}`,
										'_blank',
									)
								}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									x='0px'
									y='0px'
									width='20'
									height='20'
									viewBox='0 0 24 24'>
									<path d='M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 12 7 L 14 7 C 14 8.005 15.471 9 16 9 L 16 11 C 15.395 11 14.668 10.734156 14 10.285156 L 14 14 C 14 15.654 12.654 17 11 17 C 9.346 17 8 15.654 8 14 C 8 12.346 9.346 11 11 11 L 11 13 C 10.448 13 10 13.449 10 14 C 10 14.551 10.448 15 11 15 C 11.552 15 12 14.551 12 14 L 12 7 z'></path>
								</svg>
							</Badge>
						)}
						{influencer.twitchUsername && (
							<Badge
								className='rounded-pill bg-l90-light text-info p-1 px-3'
								onClick={() =>
									window.open(
										`https://www.twitch.tv/${influencer.twitchUsername}`,
										'_blank',
									)
								}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									x='0px'
									y='0px'
									width='20'
									height='20'
									viewBox='0,0,256,256'>
									<g
										fill='#8c44f7'
										fillRule='nonzero'
										stroke='none'
										strokeWidth='1'
										strokeLinecap='butt'
										strokeLinejoin='miter'
										strokeMiterlimit='10'
										strokeDasharray=''
										strokeDashoffset='0'
										fontFamily='none'
										fontWeight='none'
										fontSize='none'
										textAnchor='none'
										style={{ mixBlendMode: 'normal' }}>
										<g transform='scale(10.66667,10.66667)'>
											<path d='M5.36328,2l-3.36328,4.63672v13.36328h5v3h3l3,-3h4l5,-5v-13zM6,4h14v9l-3,3h-5l-3,3v-3h-3zM11,7v5h2v-5zM16,7v5h2v-5z'></path>
										</g>
									</g>
								</svg>
							</Badge>
						)}
					</div>
				</CardBody>
			</Card>
		);
	};

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize(); // Check initially
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<PageWrapper
			title='Lista Influencer'
			isProtected={true}
			showAds={!userSubscriptionInfo?.paidSubscription}>
			<Page
				container='fluid'
				className={`position-relative px-4 pt-0 ${!isMobile ? '' : 'mt-5'} `}>
				<div className='d-flex flex-column justify-content-start align-items-start'>
					<div className='shadow bg-body rounded d-flex flex-row justify-content-center align-items-center w-100 my-auto'>
						<label
							className='border-0 bg-transparent cursor-pointer me-0'
							htmlFor='searchInput'>
							<svg
								viewBox='0 0 24 24'
								fill='currentColor'
								className='svg-icon--material svg-icon svg-icon-2x text-primary'
								data-name='Material--Search'>
								<path d='M0 0h24v24H0V0z' fill='none' />
								<path d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path>
							</svg>
						</label>
						{renderSearchInput()}
					</div>
				</div>
				<div
					className={`mt-3 ${!isMobile ? '' : 'flex-column gap-1'}`}
					style={{
						display: !isMobile ? 'grid' : 'flex',
						gridTemplateColumns: !isMobile
							? 'repeat(auto-fill, minmax(320px, 1fr))'
							: '',
						columnGap: !isMobile ? '1.3rem' : '',
					}}>
					{newList?.map((influencer) => {
						return renderInfluencerCard(influencer);
					})}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default InfluencersList;
