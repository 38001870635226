import { apiBuilder } from '../api.config';
import {Profile, Subscription, UpdateUserPassword} from './profile.model';

export const findUserInfo = (): Promise<Profile> => {
	const URL = `users/personal`;
	return apiBuilder.get<Profile>(URL);
};

const updateUserInfo = (fieldsToUpdate: Partial<Profile>): Promise<Profile> => {
	const URL = `users/personal`;
	return apiBuilder.put<Profile, Partial<Profile>>(URL, fieldsToUpdate);
};

const deleteUser = (): Promise<void> => {
	const URL = `users/personal`;
	return apiBuilder.delete<void>(URL);
};

const updateUserPassword = (body: UpdateUserPassword): Promise<void> => {
	const URL = `users/password`;
	return apiBuilder.put<void, UpdateUserPassword>(URL, body);
};

export const getUserSubscriptionInfo = (): Promise<Subscription> => {
	const URL = `users/subscriptions`;
	return apiBuilder.get<Subscription>(URL);
};

export const profileAPI = {
	findUserInfo,
	updateUserInfo,
	updateUserPassword,
	deleteUser,
	getUserSubscriptionInfo
};
