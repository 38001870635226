import { createSlice } from '@reduxjs/toolkit';
import { Plan } from '../../api/plan/plan.model';
import { planAction } from './plan.action';

interface PlanState {
	list: Plan[];
	loading: boolean;
	error: boolean;
}

// Define the initial state using that type
const initialState: PlanState = {
	list: [],
	loading: false,
	error: false,
};

export const planSlice = createSlice({
	name: 'plan',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(planAction.findAll.pending, (state, action) => {
			state.loading = true;
			state.error = false;
		});
		builder.addCase(planAction.findAll.fulfilled, (state, action) => {
			state.list = action.payload;
			state.loading = false;
			state.error = false;
		});
		builder.addCase(planAction.findAll.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
		});
	},
});
