import { apiBuilder } from '../api.config';
import {
	ImportedLeague,
	League,
	LeagueData,
	LeagueTeams, PlayersRating,
	Rules,
	Team,
	TeamBasic,
} from './league.model';

export const findAll = (): Promise<League[]> => {
	const URL = 'leagues';
	return apiBuilder.get<League[]>(URL);
};

export const updateRules = (leagueId: string, newRules: Rules): Promise<League> => {
	const URL = `leagues/${leagueId}/settings/rules`;
	return apiBuilder.put<League, Rules>(URL, newRules);
};

export const getLeagueTeams = (leagueId: string): Promise<LeagueTeams> => {
	const URL = `leagues/${leagueId}/teams`;
	return apiBuilder.get<LeagueTeams>(URL);
};

export const updateLeagueTeams = (leagueId: string, teams: any): Promise<any> => {
	const URL = `leagues/${leagueId}/teams`;
	return apiBuilder.put<any, any>(URL, teams);
};

const updateTeams = (leagueId: string, newTeams: TeamBasic[]): Promise<League> => {
	const URL = `leagues/${leagueId}/settings/teams`;
	return apiBuilder.put<League, { teams: TeamBasic[] }>(URL, {
		teams: newTeams,
	});
};

// export const updateLeague = (leagueId: string, newName: string): Promise<League> => {
// 	const URL = `leagues/${leagueId}/settings`;
// 	return apiBuilder.put<League, { name: string }>(URL, {
// 		name: newName,
// 	});
// };

export const deleteLeague = (leagueId: string): Promise<void> => {
	const URL = `leagues/${leagueId}`;
	return apiBuilder.delete<void>(URL);
};

export const getLeagueData = (leagueId: string): Promise<LeagueData> => {
	const URL = `leagues/${leagueId}`;
	return apiBuilder.get<LeagueData>(URL);
};

export const updateLeague = (leagueId: string, request: any): Promise<any> => {
	const URL = `leagues/${leagueId}`;
	return apiBuilder.put<any, any>(URL, request);
};

export const importLeague = (leagueId: string, leagueName: string): Promise<ImportedLeague[]> => {
	const URL = `leagues/${leagueId}/imports/${leagueName}`;
	return apiBuilder.get<ImportedLeague[]>(URL);
};

export const getPlayers = (leagueId: string): Promise<PlayersRating> => {
	const URL = `leagues/${leagueId}/ratings`;
	return apiBuilder.get<PlayersRating>(URL);
};

export const addImportedLeague = (
	leagueId: string,
	leagueName: string,
	competitionId: string,
	importedTeamId: string,
	externalLeagueId: string,
): Promise<League> => {
	const URL = `leagues/${leagueId}/imports/${leagueName}`;
	return apiBuilder.post<League, { competitionId: string; teamId: string; externalLeagueId: string }>(URL, {
		competitionId,
		teamId: importedTeamId,
		externalLeagueId
	});
};

export const createLeague = (leagueName: string): Promise<League> => {
	const URL = `leagues`;
	return apiBuilder.post<League, { name: string }>(URL, { name: leagueName });
};

export const leagueAPI = {
	findAll,
	updateRules,
	updateTeams,
	//updateLeagueName,
	deleteLeague,
	importLeague,
	addImportedLeague,
	createLeague,
};
