import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak-config';
import { CustomEventName } from './event.type';
import { ToastContextProvider } from './contexts/toastContext';
import { InfluencerProvider } from './contexts/influencerContext';
import { LeagueProvider } from './contexts/leagueContext';
import { AuctionProvider } from './contexts/auctionContext';

const children = (
	<ReactKeycloakProvider
		authClient={keycloak}
		autoRefreshToken={true}
		onEvent={(event) => {
			if (event === 'onAuthSuccess') {
				const onAuthSuccessEvent = new CustomEvent(CustomEventName.ON_AUTH_SUCCESS);
				window.dispatchEvent(onAuthSuccessEvent);
			}
		}}
		onTokens={({ token }) => {
			if (token) {
				const tokenizeEvent = new CustomEvent(CustomEventName.ON_TOKEN_SUCCESS, {
					detail: token,
				});
				window.dispatchEvent(tokenizeEvent);
			}
		}}>
		<Router>
			<Provider store={store}>
				<ThemeContextProvider>
					<AuthContextProvider>
						<ToastContextProvider>
							<InfluencerProvider>
								<LeagueProvider>
									<AuctionProvider>
										<React.StrictMode>
											<App />
										</React.StrictMode>
									</AuctionProvider>
								</LeagueProvider>
							</InfluencerProvider>
						</ToastContextProvider>
					</AuthContextProvider>
				</ThemeContextProvider>
			</Provider>
		</Router>
	</ReactKeycloakProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
