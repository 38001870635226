import { useNavigate } from 'react-router-dom';
import { ShareModal } from '../../../../components/ShareModal';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Popovers from '../../../../components/bootstrap/Popovers';
import Icon from '../../../../components/icon/Icon';
import SubHeader from '../../../../layout/SubHeader/SubHeader';
import { AuctionContext } from '../../../../contexts/auctionContext';
import { useContext, useEffect } from 'react';
import { EndAuctionModal } from './endAuctionModal';
import { getOpen, setOpen } from '../../../../helpers/navigation';
import { StartAuctionModal } from './startAuctionModal';
import { UsersOffCanvas } from './usersOffCanvas';

interface SubHeaderAuctionActiveProps {
	parentUri: string;
	action: string | undefined;
	isMobile: boolean;
	giocatoriActive: boolean;
	squadreActive: boolean;
	rowView: boolean;
	setGiocatoriActive: (value: boolean) => void;
	setSquadreActive: (value: boolean) => void;
	setRowView: (value: boolean) => void;
}

export const SubHeaderAuctionActive = (props: SubHeaderAuctionActiveProps) => {
	const navigate = useNavigate();
	const { auctionStatus, auctionId, isAuctionActive } = useContext(AuctionContext)!;

	const isRiepilogoActive = Boolean(getOpen(props.action, 'riepilogo'));

	const renderRowView = () =>
		!props.giocatoriActive && (
			<Button
				isOutline={!props.rowView}
				color={'dark'}
				className='d-flex align-items-center fs-6 py-1 pr-3 gap-2'
				onClick={() => {
					props.setRowView(!props.rowView);
				}}>
				<Icon icon={`${props.rowView ? 'TableRows' : 'ViewColumn'}`}></Icon>
				{!props.isMobile && <p className='my-auto'>{props.rowView ? 'Riga' : 'Griglia'}</p>}
			</Button>
		);

	return (
		<>
			{!props.isMobile ? (
				<SubHeader className='subheader-row'>
					<div className='d-flex align-items-center justify-content-between gap-2'>
						<div className='d-flex align-items-center gap-3'>
							<p className='fw-bold fs-5 my-auto'>Vista</p>
							{renderRowView()}
							<ButtonGroup
								isToolbar={false}
								isVertical={false}
								size={'sm'}
								ariaLabel={'switchVista'}
								className='rounded-1 mr-2'>
								<Button
									isOutline={!props.squadreActive}
									isActive={props.squadreActive}
									color={'dark'}
									className='d-flex align-items-center fs-6 py-1 pr-3 gap-2'
									onClick={() => {
										props.setGiocatoriActive(!props.giocatoriActive);
										props.setSquadreActive(!props.squadreActive);
									}}>
									<Icon icon={'ViewList'}></Icon>
									<p className='my-auto'>Squadre</p>
								</Button>
								<Button
									isOutline={!props.giocatoriActive}
									isActive={props.giocatoriActive}
									color={'dark'}
									className='d-flex align-items-center fs-6 py-1 pr-3 gap-2'
									onClick={() => {
										props.setSquadreActive(!props.squadreActive);
										props.setGiocatoriActive(!props.giocatoriActive);
									}}>
									<Icon icon={'People'}></Icon>
									<p className='my-auto'>Giocatori</p>
								</Button>
							</ButtonGroup>
							<div className='subheader-separator pl-4'></div>
							<Button
								isOutline={!isRiepilogoActive}
								isActive={isRiepilogoActive}
								color={'dark'}
								className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 ml-2'
								onClick={() => {
									setOpen(
										navigate,
										props.parentUri,
										props.action,
										'riepilogo',
										[],
										!isRiepilogoActive,
									);
								}}>
								<Icon icon={'Summarize'}></Icon>
								<p className='my-auto'>Riepiloghi</p>
							</Button>
						</div>
						<div className='d-flex align-items-center gap-3'>
							<UsersOffCanvas
								isMobile={props.isMobile}
								parentUri='/attiva'
								action={props.action}
							/>
							<div className='subheader-separator pl-4'></div>
							<Button
								className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 bg-lo50-success text-white'
								onClick={() => {
									setOpen(
										navigate,
										'/attiva',
										props.action,
										'condividi',
										[],
										true,
									);
								}}>
								<Icon icon={'Share'}></Icon>
								<div>{!props.isMobile ? 'Condividi' : ''}</div>
							</Button>
							<ShareModal
								isMobile={props.isMobile}
								parentUri={props.parentUri}
								action={props.action}
								auctionId={auctionId}
							/>
							{isAuctionActive && (
								<EndAuctionModal action={props.action} isMobile={false} />
							)}
							{!isAuctionActive && (
								<StartAuctionModal
									action={props.action}
									isMobile={false}></StartAuctionModal>
							)}{' '}
						</div>
					</div>
				</SubHeader>
			) : (
				<SubHeader className='mobile-subheader d-flex'>
					<div className='d-flex align-items-center justify-content-between'>
						<div className='d-flex align-items-center gap-1'>
							{renderRowView()}
							<ButtonGroup
								isToolbar={false}
								isVertical={false}
								size={'sm'} // 'sm' || 'lg'
								ariaLabel={'switchVista'}
								className='rounded-1 mr-2'>
								<Button
									isOutline={!props.squadreActive}
									isActive={props.squadreActive}
									color={'dark'}
									className='d-flex align-items-center fs-6 py-1 pr-3 gap-2'
									onClick={() => {
										props.setGiocatoriActive(!props.giocatoriActive);
										props.setSquadreActive(!props.squadreActive);
									}}>
									<Icon icon={'ViewList'}></Icon>
								</Button>
								<Button
									isOutline={!props.giocatoriActive}
									isActive={props.giocatoriActive}
									color={'dark'}
									className='d-flex align-items-center fs-6 py-1 pr-3 gap-2'
									onClick={() => {
										props.setSquadreActive(!props.squadreActive);
										props.setGiocatoriActive(!props.giocatoriActive);
									}}>
									<Icon icon={'People'}></Icon>
								</Button>
							</ButtonGroup>
							<div className='subheader-separator pl-4'></div>
							<Button
								isOutline={!isRiepilogoActive}
								isActive={isRiepilogoActive}
								color={'dark'}
								className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 ml-2'
								onClick={() => {
									setOpen(
										navigate,
										props.parentUri,
										props.action,
										'riepilogo',
										[],
										!isRiepilogoActive,
									);
								}}>
								<Icon icon={'Summarize'}></Icon>
							</Button>
						</div>
						<div className='d-flex align-items-center gap-1'>
							<UsersOffCanvas
								isMobile={props.isMobile}
								parentUri='/attiva'
								action={props.action}
							/>
							<div className='subheader-separator pl-4'></div>
							<Button
								className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 bg-lo50-success text-white'
								onClick={() => {
									setOpen(
										navigate,
										'/attiva',
										props.action,
										'condividi',
										[],
										true,
									);
								}}>
								<Icon icon={'Share'}></Icon>
								<div>{!props.isMobile ? 'Condividi' : ''}</div>
							</Button>
							<ShareModal
								isMobile={props.isMobile}
								parentUri={props.parentUri}
								action={props.action}
								auctionId={auctionId}
							/>

							{isAuctionActive && (
								<EndAuctionModal action={props.action} isMobile={true} />
							)}
							{!isAuctionActive && (
								<StartAuctionModal
									action={props.action}
									isMobile={true}></StartAuctionModal>
							)}
						</div>
					</div>
				</SubHeader>
			)}
		</>
	);
};
