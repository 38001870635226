import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

interface PaymentResultProps {
	success: boolean;
}

const PaymentResult = (props: PaymentResultProps) => {
	return (
		<PageWrapper title={''} isProtected={false}>
			<Page>
				<Alert
					icon={props.success ? 'LocalPolice' : 'Dangerous'}
					color={props.success ? 'success' : 'danger'}>
					<AlertHeading>
						{props.success
							? 'Pagamento eseguito correttamente'
							: 'Ops, qualcosa è andato storto durante il pagamento, riprova'}
					</AlertHeading>
				</Alert>
			</Page>
		</PageWrapper>
	);
};

export default PaymentResult;
