import { apiBuilder } from '../api.config';
import {Payments, Plan} from './plan.model';

const findAll = (): Promise<Plan[]> => {
	const URL = 'users/plans';
	return apiBuilder.get<Plan[]>(URL);
};

const upgrade = (roleName: string): Promise<void> => {
	const URL = `payments/logs/roles/${roleName}`;
	return apiBuilder.post<void, null>(URL);
};

const payments = (body: Payments): Promise<string> => {
	const URL = `payments/`;
	return apiBuilder.post<string, Payments>(URL, body, {
		responseType: 'text',
		// headers: { 'Content-Type': 'text/html' },
	});
};

export const planAPI = {
	findAll,
	upgrade,
	payments,
};
