import { useContext, useEffect, useState } from 'react';
import { getLeagueData, updateLeague } from '../../../../api/league/league.api';
import { LeagueData, LeagueTeams, TeamBasic } from '../../../../api/league/league.model';
import { updateTeams } from '../../../../api/team/team.api';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import { AuctionContext } from '../../../../contexts/auctionContext';
import { LeagueContext } from '../../../../contexts/leagueContext';
import { ToastContext } from '../../../../contexts/toastContext';
import { auctionSettings, teamSettings } from './startWizardSteps';
import { createAuction } from '../../../../api/auction/auction.api';
import { set } from 'date-fns';
import SelectTeam from './SelectTeam';
import { useNavigate } from 'react-router-dom';
import WebSocketClient, {
	MessageTypes,
	WebSocketResponse,
} from '../../../../websockets/websockets.config';
import AuthContext from '../../../../contexts/authContext';
import { useFormik } from 'formik';

type Team = {
	teamId: string;
	name: string;
	available: boolean;
};

interface FormValues {
	auctionName: string;
	callMode: number | string | undefined;
	usage: number | string | undefined;
	firstCallTime: number | string | undefined;
	laterCallTime: number | string | undefined;
}

export const StartAuctionWizard = (props: { setStartAuctionOpen: (b: boolean) => void }) => {
	const { setStartAuctionOpen } = props;
	const { selectedLeague, setHasTeamsBeenUpdated, setSelectedLeague } =
		useContext(LeagueContext)!;
	const { setToast } = useContext(ToastContext);
	const { authToken } = useContext(AuthContext);

	const setContextTeams = useContext(AuctionContext)!.setTeams;
	const navigate = useNavigate();
	const [teams, setTeams] = useState<LeagueTeams>();
	const { teamsAuction, setTeamsAuction, selectedTeam, setSelectedTeam, setAuctionHistory } =
		useContext(AuctionContext)!;
	useEffect(() => {
		teams?.teams && setContextTeams(teams?.teams ?? []);
	}, [teams]);

	const [leagueData, setLeagueData] = useState<LeagueData | null>(null);

	const auctionId = useContext(AuctionContext)!;

	const [isCallAtQuotationPrice, setIsCallAtQuotationPrice] = useState<boolean>(false);
	const [isAuctionRepair, setIsAuctionRepair] = useState<boolean>(false);
	const [isVisitorsEnabled, setIsVisitorsEnabled] = useState<boolean>(false);
	const [newTeams, setNewTeams] = useState<any[]>();
	const [nrAttaccanti, setNrAttaccanti] = useState<number>(0);
	const [nrCentrocampisti, setNrCentrocampisti] = useState<number>(0);
	const [nrDifensori, setNrDifensori] = useState<number>(0);
	const [nrPortieri, setNrPortieri] = useState<number>(0);
	const [newRules, setNewRules] = useState<any>();
	useEffect(() => {
		if (leagueData) {
			setNewRules(leagueData.rules);

			setNrAttaccanti(leagueData.rules.numberForwards);
			setNrCentrocampisti(leagueData.rules.numberMidfielders);
			setNrDifensori(leagueData.rules.numberDefenders);
			setNrPortieri(leagueData.rules.numberGoalkeepers);
		}
	}, [leagueData]);

	useEffect(() => {
		if (selectedLeague?.id)
			getLeagueData(selectedLeague?.id).then((ret) => {
				setLeagueData(ret);
			});
	}, [selectedLeague]);

	useEffect(() => {
		if (teams && teams.teams) {
			setNewTeams(teams.teams);
		}
	}, [teams]);

	useEffect(() => {
		if (newTeams) {
			setNewRules({
				...newRules,
				participants: newTeams.length,
			});
			updateTeams(selectedLeague.id, newTeams).then((res: any) => {
				updateLeague(selectedLeague?.id, { ...selectedLeague, rules: newRules }).then(
					(res) => {
						setSelectedLeague(res);
					},
				);
				setHasTeamsBeenUpdated(true);
			});
		}
	}, [teams, newTeams]);

	function handleCreateAuction() {
		const requestBody = {
			leagueId: selectedLeague?.id,
			name: formik.values.auctionName,
			callMode: formik.values.callMode,
			usage: formik.values.usage,
			callQuotationCost: isCallAtQuotationPrice,
			secondsCounterFirstCall: formik.values.firstCallTime,
			secondsCounterOtherCalls: formik.values.laterCallTime,
			repairAuction: isAuctionRepair,
			enableVisitors: isVisitorsEnabled,
			numberGoalkeepers: nrPortieri,
			numberDefenders: nrDifensori,
			numberMidfielders: nrCentrocampisti,
			numberForwards: nrAttaccanti,
		};
		createAuction(requestBody).then((res) => {
			auctionId.setAuctionId(res.id);
		});
	}

	function openAuction() {
		if (auctionId.auctionId && authToken && selectedTeam) {
			WebSocketClient.getInstance(authToken, auctionId.auctionId).then((client) => {
				client.subscribe('private-teams', (data: WebSocketResponse) => {
					if (data.messageType === MessageTypes.TEAM_SELECTION_CONFIRMED) {
						setAuctionHistory([]);
						navigate('/attiva');
					}
				});
				client.publish(
					`/app/auctions/${auctionId.auctionId}/teams/${selectedTeam.teamId}`,
					'',
				);
			});
		}
	}

	const validate = (values: FormValues) => {
		const errors: Partial<FormValues> = {};
		if (values.auctionName === '') {
			errors.auctionName = 'Campo obbligatorio.';
		}
		if (values.auctionName.length > 32) {
			errors.auctionName = 'Deve essere inferiore a 32 caratteri.';
		}
		if (values.callMode === undefined) {
			errors.callMode = 'Campo obbligatorio.';
		}
		if (values.usage === undefined) {
			errors.usage = 'Campo obbligatorio.';
		}
		if (values.firstCallTime === '') {
			errors.firstCallTime = 'Campo obbligatorio.';
		}
		if (values.firstCallTime && isNaN(Number(values.firstCallTime))) {
			errors.firstCallTime = 'Deve essere un numero.';
		}
		if (values.firstCallTime && Number(values.firstCallTime) < 5) {
			errors.firstCallTime = 'Deve essere maggiore di 5 secondi.';
		}
		if (values.laterCallTime === '') {
			errors.laterCallTime = 'Campo obbligatorio.';
		}
		if (values.laterCallTime && isNaN(Number(values.laterCallTime))) {
			errors.laterCallTime = 'Deve essere un numero.';
		}
		if (values.laterCallTime && Number(values.laterCallTime) < 5) {
			errors.laterCallTime = 'Deve essere maggiore di 5 secondi.';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			auctionName: '',
			callMode: undefined,
			usage: undefined,
			firstCallTime: '',
			laterCallTime: '',
		},
		initialErrors: {
			auctionName: 'Campo obbligatorio.',
			callMode: 'Campo obbligatorio.',
			usage: 'Campo obbligatorio.',
			firstCallTime: 'Campo obbligatorio.',
			laterCallTime: 'Campo obbligatorio.',
		},
		validate,
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<Wizard
			isHeader={true}
			color={'primary'}
			stretch={'semi'}
			className='d-flex flex-column mb-3'
			onSubmit={() => {
				openAuction();
			}}
			isNextDisabled={Object.keys(formik.errors).length > 0}
			isSubmitDisabled={!selectedTeam?.teamId}
			style={{ height: '500px' }}
			prevlabel='Indietro'
			nextlabel={['Step Successivo', 'Conferma Squadre']}
			submitlabel='Avvia Asta'
			hideprevbutton={'true'}
			enableselectstep={'false'}
			icon0='Edit'
			icon1='Assignment'
			icon2='TouchApp'
			customAction1={handleCreateAuction}>
			{auctionSettings(
				formik,
				isCallAtQuotationPrice,
				setIsCallAtQuotationPrice,
				isAuctionRepair,
				setIsAuctionRepair,
				isVisitorsEnabled,
				setIsVisitorsEnabled,
			)}
			{teamSettings(
				selectedLeague?.id,
				teams,
				setTeams,
				leagueData,
				newTeams,
				setNewTeams,
				nrAttaccanti,
				setNrAttaccanti,
				nrCentrocampisti,
				setNrCentrocampisti,
				nrDifensori,
				setNrDifensori,
				nrPortieri,
				setNrPortieri,
				newRules,
				setNewRules,
			)}

			<WizardItem id={'lastStep'} title={'Seleziona Squadra'}>
				<SelectTeam teamsAuction={teamsAuction} setTeamsAuction={setTeamsAuction} />
			</WizardItem>
		</Wizard>
	);
};
