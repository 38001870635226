import { createSlice } from '@reduxjs/toolkit';
import { teamsAction } from './teams.action';
import { Team } from '../../api/team/team.model';
import { playerAction } from '../player/player.action';

// key is leagueId
type TeamsByLeagueId = { [index: string]: Team[] };
interface TeamsState {
	loading: boolean;
	error: boolean;
	teamsByLeagueId: TeamsByLeagueId;
}

// Define the initial state using that type
const initialState: TeamsState = {
	loading: false,
	error: false,
	teamsByLeagueId: {},
};

export const teamsSlice = createSlice({
	name: 'teams',

	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(teamsAction.findAll.pending, (state, action) => {
			const leagueId = action.meta.arg;
			state.teamsByLeagueId[leagueId] = [];
			state.loading = true;
			state.error = false;
		});
		builder.addCase(teamsAction.findAll.fulfilled, (state, action) => {
			state.teamsByLeagueId[action.payload.leagueId] = action.payload.teams;
			state.loading = false;
			state.error = false;
		});
		builder.addCase(teamsAction.findAll.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
		});
	},
});
