import React, { useCallback } from 'react';

export const useInfiniteScroll = (
	containerElement: React.RefObject<any>,
	page: number,
	totalPages: number,
	loadMoreItems: () => Promise<any>,
	isLoading?: boolean,
) => {
	const onScrollGrid = useCallback(() => {
		if (containerElement.current) {
			const gridElement = containerElement.current;
			const isBottomPage: boolean =
				gridElement.scrollTop + gridElement.clientHeight >= gridElement.scrollHeight - 1500;
			const hasNextPage: boolean = page < totalPages;
			if (isBottomPage && hasNextPage && !isLoading) {
				loadMoreItems();
			}
		}
	}, [containerElement, page, totalPages, loadMoreItems, isLoading]);

	return onScrollGrid;
};
