import React, { ChangeEvent, useContext, useState } from 'react';
import Input from '../../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../../components/bootstrap/forms/InputGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import { WizardItem } from '../../../../components/Wizard';
import { AuctionContext } from '../../../../contexts/auctionContext';
import { translateCode } from '../../../../api/auction/auction.api';

export const writeAuctionCode = (
	auctionCode: string,
	setAuctionCode: (v: string) => void,
	codeAttempt: string,
	setCodeAttempt: (v: string) => void,
) => {
	const { invalidCode, setInvalidCode } = useContext(AuctionContext)!;

	return (
		<WizardItem id={'firstStep'} title={'Inserisci Codice Asta'}>
			<div>
				<p className='text-muted mb-1'>Codice asta</p>
			</div>
			<InputGroup>
				<InputGroupText>app.fantalgoritmo.it/asta/</InputGroupText>
				<Input
					placeholder='Codice'
					type={'text'}
					value={codeAttempt}
					disabled={!invalidCode}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setCodeAttempt(e.target.value.trim());
						translateCode(e.target.value.trim())
							.then((res) => {
								setAuctionCode(res.id);
							})
							.catch((err) => {
								setInvalidCode(true);
								setCodeAttempt(e.target.value.trim());
							});
					}}
					ariaLabel='Input'
				/>
			</InputGroup>
			{invalidCode && codeAttempt !== '' && (
				<div className='text-danger mt-1'>Il codice inserito non è valido</div>
			)}
		</WizardItem>
	);
};

export const selectTeamStep = (selectedTeam: string, setSelectedTeam: (v: string) => void) => {
	const teamsBySelectedLeague = ['Milan', 'Inter', 'Napoli'];

	return (
		<WizardItem id={'lastStep'} title={'Seleziona Squadra'}>
			<div>
				<p className='text-muted mb-1'>Squadre disponibili</p>
			</div>
			<div>
				<Select
					className={'mb-3 text-muted'}
					id={'team'}
					value={'selectedTeamId - selectedTeamName'}
					onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
						setSelectedTeam(e.target.value);
					}}
					ariaLabel={'selectedTeam'}
					placeholder={selectedTeam || 'Visualizza Lista Squadre'}>
					{teamsBySelectedLeague.map((t) => {
						return (
							<option key={t} value={t}>
								{t}
							</option>
						);
					})}
				</Select>
			</div>
		</WizardItem>
	);
};
