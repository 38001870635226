import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { findAll } from '../../../../api/player/player.api';
import {
	ExtendedPlayerDTO,
	FindPlayersFilterBy,
	FindPlayersSortBy,
} from '../../../../api/player/player.model';
import { CardBody } from '../../../../components/bootstrap/Card';
import WithTwoActions from '../../../../components/ListaSwipeMobileWithTwoActions';
import AuthContext from '../../../../contexts/authContext';
import { ToastContext } from '../../../../contexts/toastContext';
import { debounce } from '../../../../helpers/helpers';
import { usePlayer } from '../../../../hooks/businessLogic/usePlayer';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';

interface PlayersListAuctionProps {
	isMobile: boolean;
	setSelectedPlayerId: (playerId: string) => void;
	setSelectedPlayerName: (playerName: string) => void;
	setShowAddPlayerToTeam: (isOpen: boolean) => void;
	onSearchPlayers: (
		property: FindPlayersSortBy[],
		filterBy: FindPlayersFilterBy,
	) => Promise<void>;
	players: ExtendedPlayerDTO[];
	selectedFilterBy: any;
	selectedSortBy: any;
}

export const PlayersListAuction = (props: PlayersListAuctionProps) => {
	const { userSubscriptionInfo, tokenReceived } = useContext(AuthContext);
	const listRef = useRef<HTMLDivElement | null>(null);
	const currentPage = useRef(0);

	let isLoading = false;
	const fetchNewPage = async () => {
		await props
			.onSearchPlayers(props.selectedSortBy, {
				...props.selectedFilterBy,
				page: currentPage.current,
			})
			.then(() => {
				currentPage.current += 1;
			})
			.finally(() => {
				isLoading = false;
			});
	};

	useEffect(() => {
		if (props.selectedSortBy || props.selectedFilterBy) {
			if (listRef.current) {
				listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}
	}, [props.selectedSortBy, props.selectedFilterBy]);

	useEffect(() => {
		if (tokenReceived && !isLoading) {
			fetchNewPage();
		}
	}, [tokenReceived]);

	const onScrollGrid = useInfiniteScroll(
		listRef,
		currentPage.current,
		14, // TODO: in the future use the total pages from the response
		async () => {
			if (!isLoading) {
				isLoading = true;
				debounce(fetchNewPage, 200)();
			}
		},
		isLoading,
	);

	return (
		<>
			<CardBody
				className={'px-3'}
				style={{ overflow: 'auto', height: '10rem' }}
				isScrollable={true}
				ref={listRef}
				onScroll={() => {
					onScrollGrid();
				}}>
				<WithTwoActions
					role={userSubscriptionInfo?.role ?? ''}
					players={props.players}
					onShowDetail={(playerId: string) => {
						props.setSelectedPlayerId(playerId);
					}}
				/>
			</CardBody>
		</>
	);
};
