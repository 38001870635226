import { useContext } from 'react';
import { useNavigate } from 'react-router';
import SockJS from 'sockjs-client';
import Stomp, { Client, Subscription } from 'webstomp-client';
import { ToastContext } from '../contexts/toastContext';

const clients: any = {};

export type WebSocketResponse = {
	content: any;
	messageType: string;
	messageTime: Date;
};

export enum MessageTypes {
	TEAMS_AVAILABLE = 'TEAMS_AVAILABLE',
	TEAM_SELECTION_CONFIRMED = 'TEAM_SELECTION_CONFIRMED',
	STATUS = 'STATUS',
	CALL_STARTED = 'CALL_STARTED',
	NEW_USER_JOINED = 'NEW_USER_JOINED',
	RAISE_ACCEPTED = 'RAISE_ACCEPTED',
	RAISE_FAILED = 'RAISE_FAILED',
	CALL_CLOSED = 'CALL_CLOSED',
	CALL_FAILED = 'CALL_FAILED',
	TEAM_UPDATE = 'TEAM_UPDATE',
	AUCTION_ENDED = 'AUCTION_ENDED',
	TEAM_ALREADY_SELECTED = 'TEAM_ALREADY_SELECTED',
	AUCTION_DETAILS = 'AUCTION_DETAILS',
	AUCTION_STARTED = 'AUCTION_STARTED',
	AUCTION_HISTORY = 'AUCTION_HISTORY',
	CALL_AUTO = "CALL_AUTO",
	ERROR = "ERROR"
}

const ERROR_CODES = {
	RECONNECT_CODES: [1006],
	CLOSE_CODES: [1000],
	KILL_AND_RECONNECT_CODES: [1002],
};

type Subject = {
	name: string;
	subscription: Subscription | undefined;
	callbacks: any[];
};

export default class WebSocketClient {
	public client: Client | null = null;
	private auctionId: string = '';
	private authToken: string = '';
	public subjects: Subject[] = [];

	constructor(authToken: string, auctionId: string) {
		this.auctionId = auctionId;
		this.authToken = authToken;
		this.onclose({});
	}
	private onclose(e: any) {
		const sockClient = new SockJS(`${process.env.REACT_APP_REST_URL}ws`);
		sockClient.onclose = this.onclose;

		this.client = Stomp.over(sockClient, { debug: false });
		this.client.connect(
			{ Authorization: `Bearer ${this.authToken}` },
			() => {
				// noop
			},
			(error: any) => {
				if (ERROR_CODES.RECONNECT_CODES.includes(error.code)) {
					this.onclose({});
				} else if (ERROR_CODES.CLOSE_CODES.includes(error.code)) {
					// console.log('Connection closed');
				} else if (ERROR_CODES.KILL_AND_RECONNECT_CODES.includes(error.code)) {
					this.client?.disconnect(() => {
						this.onclose({});
					});
				} else {
					if (error.code === 1002) {
						this.client?.disconnect();
					} else {
						console.error(error);
					}
				}
			},
		);
	}
	/**
	 * Parse the message and return a Response object
	 * @param message
	 * @returns Response
	 */
	private parseMessage(message: any): WebSocketResponse {
		const response = JSON.parse(message);
		return {
			content: response.content,
			messageType: response.messageType,
			messageTime: new Date(response.messageTime),
		};
	}
	/**
	 * Publish a message to the internal subjects broker
	 * @param subject
	 * @param data
	 * @returns void
	 */
	private internalPublish(subject: string, data: any) {
		const s = this.subjects.find((s) => s.name === subject);
		if (!s || s.callbacks.length < 1) {
			return false;
		}
		s.callbacks.forEach((callback: any) => {
			callback(data || {});
		});
	}
	/**
	 * Publish a message to the WebSocket broker
	 * @param subject
	 * @param data
	 * @returns void
	 */
	publish(path: string, data: any) {
		if (!this.client || !this.client.connected) {
			throw new Error('Client not connected');
		}

		this.client.send(path, JSON.stringify(data));
	}

	isConnected(): boolean {
		return Boolean(this.client && this.client.connected);
	}

	/**
	 * Subscribe to a internal subjects broker, subscribes to WS topic if not already subscribed
	 * @param subject
	 * @param callback
	 * @returns void
	 */
	subscribe(subject: string, callback: (message: any) => void) {
		let s = this.subjects.find((s) => s.name === subject);
		if (!s) {
			s = {
				name: subject,
				subscription: undefined,
				callbacks: [],
			};
			this.subjects.push(s);
		}
		if (s.subscription) {
			s.callbacks.push(callback);
			return;
		}
		switch (subject) {
			case 'private-teams':
				if (this.client && this.client.connected) {
					s.subscription = this.client?.subscribe(
						`/user/queue/auctions/${this.auctionId}/teams`,
						(message: any) => {
							this.internalPublish('private-teams', this.parseMessage(message.body));
						},
					);
				}
				break;
			case 'public-teams':
				if (this.client && this.client.connected) {
					s.subscription = this.client?.subscribe(
						`/topic/auctions/${this.auctionId}/teams`,
						(message: any) => {
							this.internalPublish('public-teams', this.parseMessage(message.body));
						},
					);
				}
				break;
			case 'private-auction':
				if (this.client && this.client.connected) {
					s.subscription = this.client?.subscribe(
						`/user/queue/auctions/${this.auctionId}`,
						(message: any) => {
							this.internalPublish(
								'private-auction',
								this.parseMessage(message.body),
							);
						},
					);
				}
				break;
			case 'public-auction':
				if (this.client && this.client.connected) {
					s.subscription = this.client?.subscribe(
						`/topic/auctions/${this.auctionId}`,
						(message: any) => {
							this.internalPublish('public-auction', this.parseMessage(message.body));
						},
					);
				}
				break;
			default:
				throw new Error('Invalid subject');
		}
		s.callbacks.push(callback);
	}
	/**
	 * Unsubscribe from a internal subjects broker, then disconnects the client
	 *
	 * @returns void
	 */
	disconnect() {
		for (const s of this.subjects) {
			if (s.subscription) {
				s.subscription.unsubscribe();
			}
		}
		this.subjects = [];
		this.client?.disconnect(() => {
			this.client = null;
		});
	}

	static getInstance(authToken: string, auctionId: string): Promise<WebSocketClient> {
		return new Promise((resolve, reject) => {
			if (!clients[auctionId]) {
				clients[auctionId] = new WebSocketClient(authToken, auctionId);
				let counter = 0;
				const interval: NodeJS.Timeout = setInterval(() => {
					if (!clients[auctionId].client) {
						return reject(clearInterval(interval));
					}
					if (clients[auctionId].client.connected) {
						resolve(clients[auctionId]);
					}
					if (counter > 10) reject('Connection timeout');
					counter++;
				}, 100);
			} else {
				resolve(clients[auctionId]);
			}
		});
	}
}
