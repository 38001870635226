import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	ExtendedPlayerDTO,
	FindPlayersFilterBy,
	FindPlayersSortBy,
} from '../../../../api/player/player.model';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardTitle } from '../../../../components/bootstrap/Card';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Select from '../../../../components/bootstrap/forms/Select';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../components/bootstrap/OffCanvas';
import Option from '../../../../components/bootstrap/Option';
import Icon from '../../../../components/icon/Icon';
import { findAll } from '../../../../api/player/player.api';
import { setOpen, getOpen } from '../../../../helpers/navigation';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../components/bootstrap/forms/Label';
import InputGroup, { InputGroupText } from '../../../../components/bootstrap/forms/InputGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import FormText from '../../../../components/bootstrap/forms/FormText';

interface OrderAndFilterOffCanvasProps {
	isMobile: boolean;
	parentUri: string;
	action: string | undefined;
	selectedFilterBy: FindPlayersFilterBy;
	setSelectedFilterBy: (v: any) => void;
	selectedSortBy: FindPlayersSortBy[];
	setSelectedSortBy: (v: FindPlayersSortBy[]) => void;
	onSearchPlayers: (
		property: FindPlayersSortBy[],
		filterBy: FindPlayersFilterBy,
	) => Promise<void>;
}

export const OrderAndFilterOffCanvas = (props: OrderAndFilterOffCanvasProps) => {
	const navigate = useNavigate();
	const isOpen = Boolean(getOpen(props.action, 'filtra'));

	const [sliderOwnershipValue, setSliderOwnershipValue] = useState(1);
	const handleSliderOwnershipChange = (e: {
		target: { value: React.SetStateAction<number> };
	}) => {
		setSliderOwnershipValue(e.target.value);
	};

	const [sliderReliabilityValue, setSliderReliabilityValue] = useState(1);
	const handleSliderReliabilityChange = (e: {
		target: { value: React.SetStateAction<number> };
	}) => {
		setSliderReliabilityValue(e.target.value);
	};

	const renderSortSelect = () => {
		return (
			<Select
				className='w-100 text-muted'
				ariaLabel='Sort and filter players'
				placeholder={'Visualizza Valori'}
				onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
					const selectedValue = event.target.value;
					props.setSelectedSortBy([selectedValue as FindPlayersSortBy]);
				}}>
				<option value='role,asc,staticPrice,desc' disabled={true}>
					Ruolo e Prezzo FA
				</option>
				<option value='role,asc,classicQuotation,desc'>Ruolo e Quotazione</option>
				<option value='mantraRole,asc,mantraQuotation,desc'>
					Ruolo Mantra e Quotazione Mantra
				</option>
				<option value='classicQuotation,desc'>Quotazione</option>
				<option value='mantraQuotation,desc'>Quotazione Mantra</option>
				<option value='role,asc,averageAuctionPrice,desc' disabled={true}>
					Ruolo e Prezzo Medio Aste
				</option>
				<option value='averageVote,desc' disabled={true}>
					Media Voto
				</option>
				<option value='averageFantaVote,desc' disabled={true}>
					Media Fanta Voto
				</option>
				<option value='staticPrice,desc' disabled={true}>
					Prezzo FA
				</option>
				<option value='averageAuctionPrice,desc' disabled={true}>
					Prezzo Medio Aste
				</option>
				<option value='team,asc,role,asc'>Squadra A-Z e Ruolo</option>
				<option value='team,desc,role,asc'>Squadra Z-A e ruolo</option>
				<option value='role,asc,surname,asc'>Ruolo e nome</option>
				<option value='surname,asc'>Nome A-Z</option>
				<option value='surname,desc'>Nome Z-A</option>
				<option value='team,asc,classicQuotation,desc'>Squadra A-Z e Quotazione</option>
				<option value='team,asc,mantraQuotation,desc'>
					Squadra A-Z e Quotazione Mantra
				</option>
				<option value='team,desc,classicQuotation,desc'>Squadra Z-A e Quotazione</option>
				<option value='team,desc,mantraQuotation,desc'>
					Squadra Z-A e Quotazione Mantra
				</option>

				<option value='goals,desc' disabled={true}>
					Goal Fatti
				</option>
				<option value='goalsConceded,asc' disabled={true}>
					Goal Subiti
				</option>
				<option value='assists,desc' disabled={true}>
					Assist
				</option>
				<option value='appearances,desc' disabled={true}>
					Presenze
				</option>
				<option value='minutesPlayed,desc' disabled={true}>
					Minuti Giocati
				</option>
				<option value='days,desc' disabled={true}>
					Giornate Infortunato
				</option>
				<option value='yellowCards,desc' disabled={true}>
					Ammonizioni
				</option>
			</Select>
		);
	};

	return (
		<>
			<Button
				className='mt-2'
				color='light'
				icon='sort'
				onClick={() => {
					setOpen(navigate, props.parentUri, props.action, 'filtra', [], true);
				}}>
				<span className='d-none d-lg-inline'>Ordina e Filtra</span>
			</Button>

			<OffCanvas
				tag={'div'}
				isBackdrop={true}
				isBodyScroll={false}
				placement={'end'}
				isOpen={isOpen}
				setOpen={() =>
					setOpen(navigate, props.parentUri, props.action, 'filtra', [], false)
				}>
				<OffCanvasHeader className='d-flex justify-content-between align-items-center'>
					<OffCanvasTitle id={'title'}>Impostazioni</OffCanvasTitle>
					<Button
						className='p-1 px-2 rounded-1'
						onClick={() => {
							setOpen(navigate, props.parentUri, props.action, 'filtra', [], false);
						}}
						style={{ color: 'gray' }}>
						<Icon icon={'Close'} size={'2x'}></Icon>
					</Button>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Card>
						<CardHeader>
							<CardTitle>Ordina</CardTitle>
						</CardHeader>
						<CardBody>
							<FormGroup>
								<Label>Scegli un ordinamento</Label>
								{renderSortSelect()}
							</FormGroup>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>Filtri Dati Giocatori</CardTitle>
						</CardHeader>
						<CardBody>
							<div className={'row'}>
								<FormGroup>
									<div className={'col-12 mb-4'}>
										<Label>Ruoli</Label>
										<div>
											{['-', 'P', 'D', 'C', 'A'].map((role, index) => {
												const labels = [
													'Tutti',
													'Portieri',
													'Difensori',
													'Centrocampisti',
													'Attaccanti',
												];
												// TODO: trattino va incluso nella stringa role?
												const isTuttiSelected = props.selectedFilterBy.role
													?.split(',')
													.includes('-');
												return (
													<div key={role}>
														<Checks
															id={labels[index].toLowerCase()}
															name={labels[index].toLowerCase()}
															className='custom-class'
															type='switch'
															label={labels[index]}
															value={role}
															checked={props.selectedFilterBy.role
																?.split(',')
																.includes(role)}
															disabled={
																isTuttiSelected && role !== '-'
															}
															onChange={() => {
																let roles = props.selectedFilterBy
																	.role
																	? props.selectedFilterBy.role.split(
																			',',
																	  )
																	: [];
																if (role === '-') {
																	if (roles.includes('-')) {
																		roles = [];
																	} else {
																		roles = [
																			'-',
																			'P',
																			'D',
																			'C',
																			'A',
																		];
																	}
																} else {
																	if (roles.includes(role)) {
																		roles = roles.filter(
																			(r: string) =>
																				r !== role,
																		);
																		if (roles.includes('-')) {
																			roles = roles.filter(
																				(r: string) =>
																					r !== '-',
																			);
																		}
																	} else {
																		roles.push(role);
																		if (
																			[
																				'P',
																				'D',
																				'C',
																				'A',
																			].every((r) =>
																				roles.includes(r),
																			)
																		) {
																			roles = [
																				'-',
																				'P',
																				'D',
																				'C',
																				'A',
																			];
																		}
																	}
																}
																props.setSelectedFilterBy(
																	(prevState: any) => ({
																		...prevState,
																		role: roles.join(','),
																	}),
																);
															}}
														/>
													</div>
												);
											})}
										</div>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Ruoli Mantra</Label>
										<div style={{ columnCount: 2 }}>
											{[
												'-',
												'Por',
												'Dc',
												'Ds',
												'Dd',
												'E',
												'M',
												'C',
												'T',
												'W',
												'A',
												'Pc',
											].map((role, index) => {
												const labels = [
													'Tutti',
													'Por',
													'Dc',
													'Ds',
													'Dd',
													'E',
													'M',
													'C',
													'T',
													'W',
													'A',
													'Pc',
												];
												const isTuttiSelected =
													props.selectedFilterBy.mantraRole
														?.split(',')
														.includes('-');
												return (
													<div key={role}>
														<Checks
															id={labels[index].toLowerCase()}
															name={labels[index].toLowerCase()}
															className='custom-class'
															type='switch'
															label={labels[index]}
															value={role}
															checked={props.selectedFilterBy.mantraRole
																?.split(',')
																.includes(role)}
															disabled={
																isTuttiSelected && role !== '-'
															}
															onChange={() => {
																let roles = props.selectedFilterBy
																	.mantraRole
																	? props.selectedFilterBy.mantraRole.split(
																			',',
																	  )
																	: [];
																if (role === '-') {
																	if (roles.includes('-')) {
																		roles = [];
																	} else {
																		roles = [
																			'-',
																			'Por',
																			'Dc',
																			'Ds',
																			'Dd',
																			'E',
																			'M',
																			'C',
																			'T',
																			'W',
																			'A',
																			'Pc',
																		];
																	}
																} else {
																	if (roles.includes(role)) {
																		roles = roles.filter(
																			(r) => r !== role,
																		);
																		if (roles.includes('-')) {
																			roles = roles.filter(
																				(r) => r !== '-',
																			);
																		}
																	} else {
																		roles.push(role);
																		if (
																			[
																				'Por',
																				'Dc',
																				'Ds',
																				'Dd',
																				'E',
																				'M',
																				'C',
																				'T',
																				'W',
																				'A',
																				'Pc',
																			].every((r) =>
																				roles.includes(r),
																			)
																		) {
																			roles = [
																				'-',
																				'Por',
																				'Dc',
																				'Ds',
																				'Dd',
																				'E',
																				'M',
																				'C',
																				'T',
																				'W',
																				'A',
																				'Pc',
																			];
																		}
																	}
																}
																props.setSelectedFilterBy(
																	(prevState: any) => ({
																		...prevState,
																		mantraRole: roles.join(','),
																	}),
																);
															}}
														/>
													</div>
												);
											})}
										</div>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Squadre</Label>
										<Select
											id={'team'}
											value={props.selectedFilterBy.team}
											onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
												props.setSelectedFilterBy((prevState: any) => {
													return {
														...prevState,
														team: e.target.value,
													};
												})
											}
											className='text-muted'
											ariaLabel={'team'}
											placeholder={'Visualizza Valori'}>
											<Option value={'-'}>Tutte</Option>
											<Option value={'Atalanta'}>Atalanta</Option>
											<Option value={'Bologna'}>Bologna</Option>
											<Option value={'Cagliari'}>Cagliari</Option>
											<Option value={'Como'}>Como</Option>
											<Option value={'Empoli'}>Empoli</Option>
											<Option value={'Fiorentina'}>Fiorentina</Option>
											<Option value={'Genoa'}>Genoa</Option>
											<Option value={'Inter'}>Inter</Option>
											<Option value={'Juventus'}>Juventus</Option>
											<Option value={'Lazio'}>Lazio</Option>
											<Option value={'Lecce'}>Lecce</Option>
											<Option value={'Milan'}>Milan</Option>
											<Option value={'Monza'}>Monza</Option>
											<Option value={'Napoli'}>Napoli</Option>
											<Option value={'Parma'}>Parma</Option>
											<Option value={'Roma'}>Roma</Option>
											<Option value={'Torino'}>Torino</Option>
											<Option value={'Udinese'}>Udinese</Option>
											<Option value={'Venezia'}>Venezia</Option>
											<Option value={'Verona'}>Verona</Option>
										</Select>
									</div>
									<div className={'col-12 mt-4'}>
										<Label>Età</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input
												type="number"
												value={props.selectedFilterBy.ageGte}
												min={1}
												max={99}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													props.setSelectedFilterBy((prevState: any) => ({
														...prevState,
														ageGte: e.target.value,
													}))
												}
											/>
											<InputGroupText>Max</InputGroupText>
											<Input
												type="number"
												value={props.selectedFilterBy.ageLte}
												min={1}
												max={99}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													props.setSelectedFilterBy((prevState: any) => ({
														...prevState,
														ageLte: e.target.value,
													}))
												}
											/>
										</InputGroup>
									</div>
								</FormGroup>
							</div>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>Filtri Creator</CardTitle>
						</CardHeader>
						<CardBody>
							<div className={'row'}>
								<FormGroup>
									<div className={'col-12 mb-4'}>
										<Checks
											type={'switch'}
											label={'Obiettivi di Mercato'}
											checked={props.selectedFilterBy.marketObjective}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												props.setSelectedFilterBy((prevState: any) => ({
													...prevState,
													marketObjective: e.target.checked,
												}))
											}></Checks>
										<Checks type={'switch'}
												label={'Consigliati'}
												checked={props.selectedFilterBy.recommendedPlayer}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													props.setSelectedFilterBy((prevState: any) => ({
														...prevState,
														recommendedPlayer: e.target.checked,
													}))
												}></Checks>
										<Checks type={'switch'}
												label={'Sconsigliati'}
												checked={props.selectedFilterBy.notRecommendedPlayer}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													props.setSelectedFilterBy((prevState: any) => ({
														...prevState,
														notRecommendedPlayer: e.target.checked,
													}))
												}></Checks>
									</div>
									<div className={'col-12 mt-4 mb-4'}>
										<Label>Prezzo</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.priceGte}
												   min={1}
												   max={1000}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   priceGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.priceLte}
												   min={1}
												   max={1000}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   priceLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mt-4 mb-4'}>
										<Label>Fascia</Label>
										<Select
											ariaLabel={'Fascia'}
											placeholder={'Seleziona Fascia'}
											defaultValue={'9'}
											value={props.selectedFilterBy.bracketLte}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											props.setSelectedFilterBy((prevState: any) => ({
												...prevState,
												bracketLte: e.target.value,
											}))
										}>
											<Option value={'9'}>Tutte le Fasce</Option>
											<Option value={'1'}>1ª Fascia</Option>
											<Option value={'2'}>2ª Fascia</Option>
											<Option value={'3'}>3ª Fascia</Option>
											<Option value={'4'}>4ª Fascia</Option>
											<Option value={'5'}>5ª Fascia</Option>
											<Option value={'6'}>6ª Fascia</Option>
											<Option value={'7'}>7ª Fascia</Option>
											<Option value={'8'}>8ª Fascia</Option>
										</Select>
									</div>
									<div className={'col-12 mt-4 mb-4'}>
										<Label>Classe</Label>
										<Select
											ariaLabel={'Classe'}
											placeholder={'Seleziona Classe'}
											defaultValue={''}
											value={props.selectedFilterBy.playerClass}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												props.setSelectedFilterBy((prevState: any) => ({
													...prevState,
													playerClass: e.target.value,
												}))
											}>
											<Option value={''}>Tutte le Classi</Option>
											<Option value={'Top'}>Top</Option>
											<Option value={'Semitop'}>Semitop</Option>
											<Option value={'Buono'}>Buono</Option>
											<Option value={'Titolare'}>Titolare</Option>
											<Option value={'Scommessa'}>Scommessa</Option>
											<Option value={'Jolly'}>Jolly</Option>
											<Option value={'Rischio'}>Rischio</Option>
											<Option value={'Incognita'}>Incognita</Option>
										</Select>
									</div>
									<div className={'col-12 mt-4 mb-4'}>
										<Label>Titolarità Minima</Label>
										<Input
											type={'range'}
											placeholder={'Titolarità'}
											value={props.selectedFilterBy.startingLineupGte}
											min={1}
											max={10}
											step={1}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												props.setSelectedFilterBy((prevState: any) => ({
													...prevState,
													startingLineupGte: e.target.value,
												}))
											}></Input>
										<FormText>Valore: {props.selectedFilterBy.startingLineupGte || 0}</FormText>
									</div>
									<div className={'col-12 mt-4'}>
										<Label>Affidabilità Minima</Label>
										<Input
											type={'range'}
											placeholder={'Titolarità'}
											value={props.selectedFilterBy.reliabilityGte}
											min={1}
											max={10}
											step={1}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												props.setSelectedFilterBy((prevState: any) => ({
													...prevState,
													reliabilityGte: e.target.value,
												}))
											}></Input>
										<FormText>Valore: {props.selectedFilterBy.reliabilityGte || 0}</FormText>
									</div>
								</FormGroup>
							</div>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>Filtri Asta</CardTitle>
						</CardHeader>
						<CardBody>
							<div className={'row'}>
								<FormGroup>
									<div className={'col-12 mb-4'}>
										<Checks type={'switch'} label={'Miei Preferiti'}
												checked={props.selectedFilterBy.goal}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													props.setSelectedFilterBy((prevState: any) => ({
														...prevState,
														goal: e.target.checked,
													}))
												}></Checks>
										<Checks
											type={'switch'}
											label={'Escludi "Da Evitare"'}
											checked={props.selectedFilterBy.failure}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												props.setSelectedFilterBy((prevState: any) => ({
													...prevState,
													failure: e.target.checked,
												}))
											}></Checks>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Prezzo Fantalgoritmo Classic</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.cFaPriceGte}
												   min={1}
												   max={1000}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   cFaPriceGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.cFaPriceLte}
												   min={1}
												   max={1000}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   cFaPriceLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Prezzo Fantalgoritmo Mantra</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.mFaPriceGte}
												   min={1}
												   max={1000}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   mFaPriceGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.mFaPriceLte}
												   min={1}
												   max={1000}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   mFaPriceLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Quotazione Classic</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.cQuotationGte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   cQuotationGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.cQuotationLte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   cQuotationLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Quotazione Mantra</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.mQuotationGte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   mQuotationGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.mQuotationLte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   mQuotationLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mt-4'}>
										<Label>Prezzo Medio Aste</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}></Input>
										</InputGroup>
									</div>
								</FormGroup>
							</div>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<CardTitle>Filtri Statistiche</CardTitle>
						</CardHeader>
						<CardBody>
							<div className={'row'}>
								<FormGroup>
									<div className={'col-12 mt-4 mb-4'}>
										<Label>Stagione</Label>
										<Select
											ariaLabel={'Stagione'}
											placeholder={'Seleziona Stagione'}
											defaultValue={''}
											value={props.selectedFilterBy.season}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												props.setSelectedFilterBy((prevState: any) => ({
													...prevState,
													season: e.target.value,
												}))
											}>
											<Option value={''}>Tutte le Stagioni</Option>
											<Option value={'2023'}>2023/2024</Option>
											<Option value={'2022'}>2022/2023</Option>
											<Option value={'2021'}>2021/2022</Option>
											<Option value={'2020'}>2020/2021</Option>
											<Option value={'2019'}>2019/2020</Option>
											<Option value={'2018'}>2018/2019</Option>
											<Option value={'2017'}>2017/2018</Option>
											<Option value={'2016'}>2016/2017</Option>
										</Select>
									</div>
									<div className={'col-12 mb-4'}>
										<Label>Media Voto</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.averageRatingGte}
												   step={0.01}
												   min={0}
												   max={10}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   averageRatingGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.averageRatingLte}
												   step={0.01}
												   min={0}
												   max={10}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   averageRatingLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Media Fanta Voto</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.averageFantaRatingGte}
												   step={0.01}
												   min={0}
												   max={10}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   averageFantaRatingGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.averageFantaRatingLte}
												   step={0.01}
												   min={0}
												   max={10}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   averageFantaRatingLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Goal</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.goalGte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   goalGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.goalLte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   goalLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Assist</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.assistGte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   assistGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.assistLte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   assistLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mb-4 mt-4'}>
										<Label>Ammonizioni</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.yellowCardGte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   yellowCardGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.yellowCardLte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   yellowCardLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
									<div className={'col-12 mt-4'}>
										<Label>Espulsioni</Label>
										<InputGroup>
											<InputGroupText>Min</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.redCardGte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   redCardGte: e.target.value,
													   }))
												   }></Input>
											<InputGroupText>Max</InputGroupText>
											<Input type={'number'}
												   value={props.selectedFilterBy.redCardLte}
												   min={1}
												   max={100}
												   step={1}
												   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
													   props.setSelectedFilterBy((prevState: any) => ({
														   ...prevState,
														   redCardLte: e.target.value,
													   }))
												   }></Input>
										</InputGroup>
									</div>
								</FormGroup>
							</div>
						</CardBody>
					</Card>
					<Button
						className={'w-100 sticky-bottom'}
						onClick={() => {
							setOpen(navigate, props.parentUri, props.action, 'filtra', [], false);
							props.onSearchPlayers(props.selectedSortBy, props.selectedFilterBy);
						}}
						type={'button'}
						color={'dark'}>
						Ordina e Filtra
					</Button>
				</OffCanvasBody>
			</OffCanvas>
		</>
	);
};
