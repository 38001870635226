import { apiBuilder } from '../api.config';
import { Championship } from './campionato.model';

const findDetail = (leagueId: string): Promise<Championship> => {
	const URL = `leagues/${leagueId}/teams/championships`;
	return apiBuilder.get<Championship>(URL);
};

export const campionatoApi = {
	findDetail,
};
