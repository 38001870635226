import { createAsyncThunk } from '@reduxjs/toolkit';
import { playerAPI } from '../../api/player/player.api';

enum PlayerDetailAction {
	FIND_DETAIL = 'playerDetail/findDetail',
	SET_NOTE = 'playerDetail/setNote',
}

const findDetail = createAsyncThunk(
	PlayerDetailAction.FIND_DETAIL,
	async (args: { leagueId: string; playerId: string }, thunkAPI) => {
		try {
			const { playerId } = args;
			const playerDetail = await playerAPI.findDetail(playerId);
			return playerDetail;
		} catch (e) {
			throw e;
		}
	},
);

const setNote = createAsyncThunk(
	PlayerDetailAction.SET_NOTE,
	async (args: { playerId: string; note: string }, thunkAPI) => {
		try {
			const { playerId, note } = args;
			await playerAPI.setNote(playerId, note);
			return note;
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response.data.message as string);
		}
	},
);

export const playerDetailAction = {
	findDetail,
	setNote,
};
