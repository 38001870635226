import React from 'react';
import { RouteProps } from 'react-router-dom';
import {upgradeMenu, pageLayoutTypesPagesMenu, paymentMenu} from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';
import PaymentResult from '../pages/presentation/paymentResult/paymentResult';

const asides: RouteProps[] = [
	// { path: upgradeMenu.update.path, element: null },
	// { path: demoPagesMenu.signUp.path, element: null },
	// { path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{
		path: paymentMenu.positive.path,
		element: <PaymentResult success={true}/>,
	},
	{
		path: paymentMenu.negative.path,
		element: <PaymentResult success={false}/>,
	},
	{ path: '*', element: <DefaultAside /> },
];

export default asides;
