import { useNavigate } from 'react-router-dom';
import RiepilogoMobile from './riepilogoMobile';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import TeamDetails from './teamDetails';
import Input from '../../../../components/bootstrap/forms/Input';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import { useContext, useEffect, useState } from 'react';
import {
	ExtendedPlayerDTO,
	FindPlayersFilterBy,
	FindPlayersSortBy,
	PlayerDetail,
} from '../../../../api/player/player.model';
import { findAll, findDetail } from '../../../../api/player/player.api';
import { AuctionContext } from '../../../../contexts/auctionContext';
import FormText from '../../../../components/bootstrap/forms/FormText';

interface SearchForCallProps {
	isMobile: boolean;
	parentUri: string;
	action: string | undefined;
	players: ExtendedPlayerDTO[];
	assigned: boolean | undefined;
	setCalled: (value: boolean) => void;
	selectCalledPlayer: (value: ExtendedPlayerDTO | undefined) => void;
	setSelectedPlayerId: (value: string | undefined) => void;
}

let timeout: NodeJS.Timeout;

export const SearchForCall = (props: SearchForCallProps) => {
	const [searchResult, setSearchResult] = useState<boolean>(false);
	const [searchResults, setSearchResults] = useState<any[]>([]);
	const isResult = (action: string | undefined) => Boolean(action?.includes('cerca'));

	const { isAuctionActive } = useContext(AuctionContext)!;
	const [selectedSortBy, setSelectedSortBy] = useState<FindPlayersSortBy[]>([]);
	const [selectedFilterBy, setSelectedFilterBy] = useState<FindPlayersFilterBy>({ sold: false });

	const navigate = useNavigate();

	const isOpen = isResult(props.action);

	const setOpen = (open: boolean) => {
		const newAction =
			(props.action
				? props.action
						.split('-')
						.filter((a) => a !== 'cerca')
						.join('-')
				: '') + (open ? '-cerca' : '');
		navigate(`${props.parentUri}/${newAction}`);
	};

	useEffect(() => {
		const handleBackButtonEvent = (e: any) => {
			setSelectedFilterBy({ search: '' });
		};

		window.addEventListener('popstate', handleBackButtonEvent);

		return () => {
			window.removeEventListener('popstate', handleBackButtonEvent);
		};
	}, []);

	const renderResultsModal = () => {
		return (
			<>
				<Modal
					id={'share'}
					titleId={'Ricerca Calciatori da Chiamare'}
					isOpen={isOpen}
					setIsOpen={setOpen}
					isStaticBackdrop={true}
					isScrollable={true}
					isCentered={true}
					size={'lg'}
					fullScreen={'md'}>
					<ModalHeader
						showClose={false}
						setIsOpen={setOpen}
					>
						<ModalTitle id={'share'}>Giocatori Disponibili</ModalTitle>
						<button
							type='button'
							className='btn-close'
							data-bs-dismiss='modal'
							aria-label='Close'
							onClick={() => {
								setSearchResult(false);
								setOpen(false);
								setSelectedFilterBy({ search: '' });
							}}
						/>
					</ModalHeader>
					<ModalBody className="d-flex flex-column">
						<div
							className="d-flex align-items-center border-1">
							<label
								className="cursor-pointer me-0 ps-2"
								htmlFor="searchInput">
								<svg
									viewBox="0 0 24 24"
									fill="currentColor"
									className="svg-icon--material svg-icon svg-icon-2x text-primary"
									data-name="Material--Search">
									<path d="M0 0h24v24H0V0z" fill="none"></path>
									<path
										d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
								</svg>
							</label>
							<Input
								id="searchInput"
								type="search"
								className="form-control border-0 shadow-none bg-transparent"
								placeholder="Cerca Giocatore"
								value={selectedFilterBy.search}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const newSelectedFilterBy = {
										...selectedFilterBy,
										search: e.target.value,
									};
									setSelectedFilterBy(newSelectedFilterBy);
									if (timeout) {
										clearTimeout(timeout);
									}
									timeout = setTimeout(() => {
										onSearchPlayers(selectedSortBy, newSelectedFilterBy);
									}, 500);
								}}
							/>
						</div>
						<FormText>
							{searchResults.length === 0 ? 'Nessun risultato trovato'
								: searchResults.length > 49 ? 'Più di 50 risultati'
								: `Risultati: ${searchResults.length}`}
						</FormText>

						{searchResults.map((playerDetail) => (
							<Card shadow={'none'}>
								<CardHeader
									size={'sm'}
									borderSize={1}
									borderColor={'dark'}
								>
									<CardLabel>
										<CardTitle>
											{playerDetail?.surname}
										</CardTitle>
										<CardSubTitle>
											{playerDetail?.name}
										</CardSubTitle>
									</CardLabel>
									<CardActions>
										<Button
											color={'success'}
											isOutline={true}
											isDisable={!isAuctionActive}
											onClick={() => {
												//TODO: this isn't setting called player
												props.selectCalledPlayer(playerDetail);
												props.setCalled(true);
												props.setSelectedPlayerId(playerDetail.id);

												setOpen(false);
												setSelectedFilterBy({ search: '' });
											}}>
											Chiama
										</Button>
										<Button
											color={'danger'}
											isOutline={true}
											isDisable={!isAuctionActive}
											onClick={() => {
												//TODO: this isn't setting called player
												props.selectCalledPlayer(playerDetail);
												props.setCalled(true);
												props.setSelectedPlayerId(playerDetail.id);

												setOpen(false);
												setSelectedFilterBy({ search: '' });
											}}>
											Assegna
										</Button>
									</CardActions>
								</CardHeader>
							</Card>
						))}
					</ModalBody>
				</Modal>
			</>
		);
	};

	const onSearchPlayers = (property: FindPlayersSortBy[], filterBy: FindPlayersFilterBy) => {
		findAll({ page: 0, size: 50, sort: property, ...filterBy }).then((res) => {
			setSearchResults(res.content);
			setSearchResult(true);
			setOpen(true);
		});
	};

	return (
		<Card className={'mt-2 mb-2'}
			  borderColor={'success'}
			  borderSize={3}>
			<CardBody className={'p-0 m-0 d-flex justify-content-center'}>
				<div
					className='d-flex align-items-center'>
					<label
						className='border-0 bg-transparent cursor-pointer me-0 ps-2'
						htmlFor='searchInput'>
						<svg
							viewBox='0 0 24 24'
							fill='currentColor'
							className='svg-icon--material svg-icon svg-icon-2x text-primary'
							data-name='Material--Search'>
							<path d='M0 0h24v24H0V0z' fill='none'></path>
							<path
								d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path>
						</svg>
					</label>
					<div>
						<Input
							id='searchInput'
							type='search'
							className='border-0 shadow-none bg-transparent'
							placeholder='Chiama Giocatore'
							value={selectedFilterBy.search}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								const newSelectedFilterBy = {
									...selectedFilterBy,
									search: e.target.value,
								};
								setSelectedFilterBy(newSelectedFilterBy);
								if (timeout) {
									clearTimeout(timeout);
								}
								timeout = setTimeout(() => {
									onSearchPlayers(selectedSortBy, newSelectedFilterBy);
								}, 500);
							}}
						/>
					</div>
				</div>

				<div>{searchResult && renderResultsModal()}</div>
			</CardBody>
		</Card>

	);
};
