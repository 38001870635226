import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { League } from '../../api/league/league.model';
import { leaguesAction } from './leagues.action';
import { UpdateRuleErrorMessage } from './types';

interface LeaguesState {
	list: League[];
	loading: boolean;
	error: boolean;
	updateRulesErrorMessages: UpdateRuleErrorMessage[];
	createLeagueErrorMessage: string;
}

// Define the initial state using that type
const initialState: LeaguesState = {
	list: [],
	loading: false,
	error: false,
	updateRulesErrorMessages: [],
	createLeagueErrorMessage: '',
};

export const leaguesSlice = createSlice({
	name: 'leagues',
	initialState,
	reducers: {
		updateLeaguesList: (
			state,
			action: PayloadAction<{ newLeague: League; selectedLeague?: League }>,
		) => {
			if (action.payload.selectedLeague) {
				state.list = state.list.map((league) => {
					if (league.id === action.payload.selectedLeague!.id) {
						return action.payload.newLeague;
					}
					return league;
				});
			} else {
				state.list = [...state.list, action.payload.newLeague];
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(leaguesAction.findAll.pending, (state, action) => {
			state.loading = true;
			state.error = false;
		});
		builder.addCase(leaguesAction.findAll.fulfilled, (state, action) => {
			state.list = action.payload;
			state.loading = false;
			state.error = false;
		});
		builder.addCase(leaguesAction.findAll.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
		});
		builder.addCase(leaguesAction.updateRules.fulfilled, (state, action) => {
			state.updateRulesErrorMessages = [];
			state.list = state.list.map((league) => {
				if (action.payload.id === league.id) {
					return action.payload;
				}
				return league;
			});
		});
		builder.addCase(leaguesAction.updateRules.rejected, (state, action) => {
			state.updateRulesErrorMessages = action.payload
				? (action.payload as UpdateRuleErrorMessage[])
				: [];
		});
		builder.addCase(leaguesAction.updateTeams.fulfilled, (state, action) => {
			state.list = state.list.map((league) => {
				if (action.payload.id === league.id) {
					return action.payload;
				}
				return league;
			});
		});
		// builder.addCase(leaguesAction.updateLeagueName.fulfilled, (state, action) => {
		// 	state.list = state.list.map((league) => {
		// 		if (action.payload.id === league.id) {
		// 			return action.payload;
		// 		}
		// 		return league;
		// 	});
		// });
		builder.addCase(leaguesAction.deleteLeague.fulfilled, (state, action) => {
			state.list = state.list.filter((league) => league.id !== action.payload);
		});
		builder.addCase(leaguesAction.createLeague.pending, (state, action) => {
			state.loading = true;
			state.createLeagueErrorMessage = '';
		});
		builder.addCase(leaguesAction.createLeague.fulfilled, (state, action) => {
			state.loading = false;
			state.createLeagueErrorMessage = '';
		});
		builder.addCase(leaguesAction.createLeague.rejected, (state, action) => {
			state.loading = false;
			state.createLeagueErrorMessage = action.payload as string;
		});
	},
});

export const { updateLeaguesList } = leaguesSlice.actions;
