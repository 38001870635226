import { createAsyncThunk } from '@reduxjs/toolkit';
import { playerAPI } from '../../api/player/player.api';
import { FindAllQueryParams } from '../../api/player/player.model';

enum PlayerAction {
	FIND_ALL = 'players/findAll',
	FIND_STATISTICS = 'players/findStatistics',
	COMPARE_PLAYERS = 'players/comparePlayers',
	REMOVE_PLAYER = 'players/removePlayer',
	ADD_PLAYER = 'players/addPlayer',
	EXPORT_ALL = 'players/export',
}

const findAll = createAsyncThunk(
	PlayerAction.FIND_ALL,
	async (args: { queryParams: FindAllQueryParams }) => {
		try {
			const { queryParams } = args;
			const newQueryParams: FindAllQueryParams = {
				page: queryParams.page,
				size: queryParams.size,
				sort: queryParams.sort,
			};
			for (let key in queryParams) {
				if (queryParams.hasOwnProperty(key)) {
					// @ts-ignore
					if (queryParams[key] !== '-') {
						// @ts-ignore
						newQueryParams[key] = queryParams[key];
					}
				}
			}
			const players = await playerAPI.findAll(newQueryParams);
			return { players, queryParams };
		} catch (e) {
			throw e;
		}
	},
);

const findStatistics = createAsyncThunk(
	PlayerAction.FIND_STATISTICS,
	async (args: { playerId: string }) => {
		try {
			const { playerId } = args;

			const playerStatistics = await playerAPI.findStatistics(playerId);
			return { playerStatistics };
		} catch (e) {
			throw e;
		}
	},
);

const comparePlayers = createAsyncThunk(
	PlayerAction.COMPARE_PLAYERS,
	async (args: { players: [] }, thunkAPI) => {
		try {
			const { players } = args;
			const playerDetail = await playerAPI.comparePlayers(players);
			return playerDetail;
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response.data.message as string);
		}
	},
);

const removePlayer = createAsyncThunk(
	PlayerAction.REMOVE_PLAYER,
	async (args: { playerId: string }, thunkAPI) => {
		try {
			const { playerId } = args;
			await playerAPI.removePlayer(playerId);
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response.data.message as string);
		}
	},
);

const addPlayer = createAsyncThunk(
	PlayerAction.ADD_PLAYER,
	async (args: { playerId: string; teamId: string; price: number }, thunkAPI) => {
		try {
			const { teamId, playerId, price } = args;
			const response = await playerAPI.addPlayerToTeam(playerId, teamId, price);
			return { teamId, playerId, price };
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response.data.message as string);
		}
	},
);

const exportAll = createAsyncThunk(PlayerAction.EXPORT_ALL, async (args: {}, thunkAPI) => {
	try {
		const file = await playerAPI.exportAll();
		return file;
	} catch (e: any) {
		return thunkAPI.rejectWithValue(e.response.data.message as string);
	}
});

export const playerAction = {
	findAll,
	findStatistics,
	comparePlayers,
	removePlayer,
	addPlayer,
	exportAll,
};
