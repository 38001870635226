import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import { AuctionContext } from '../../../../contexts/auctionContext';
import WebSocketClient from '../../../../websockets/websockets.config';
import AuthContext from '../../../../contexts/authContext';
import { getOpen, setOpen } from '../../../../helpers/navigation';

interface EndAuctionModalProps {
	action: string | undefined;
	isMobile: boolean;
}

export const EndAuctionModal = (props: EndAuctionModalProps) => {
	const { auctionId } = useContext(AuctionContext)!;
	const { authToken } = useContext(AuthContext)!;
	const navigate = useNavigate();

	const isOpen = Boolean(getOpen(props.action, 'termina'));

	const handleAuctionEnd = () => {
		if (authToken && auctionId) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.publish(`/app/auctions/${auctionId}/terminations`, '');
			});
			setOpen(navigate, '/attiva', props.action, 'termina', [], false);
		}
	};

	return (
		<>
			<Button
				className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 bg-lo50-danger text-white whitespace-nowrap'
				onClick={() => {
					setOpen(navigate, '/attiva', props.action, 'termina', [], true);
				}}>
				<Icon icon={'Stop'}></Icon>
				<p className='my-auto'>{props.isMobile ? '' : 'Termina'}</p>
			</Button>
			<Modal
				id={'termina'}
				titleId={'Assegna Giocatore'}
				isOpen={isOpen}
				setIsOpen={() => {
					setOpen(navigate, '/attiva', props.action, 'termina', [], !isOpen);
				}}
				isStaticBackdrop={true}
				isScrollable={true}
				isCentered={true}
				size={'sm'}
				fullScreen={false}>
				<ModalHeader
					showClose={false}
					setIsOpen={() => {
						setOpen(navigate, '/attiva', props.action, 'termina', [], !isOpen);
					}}>
					<ModalTitle id={'termina'}>Termina Asta</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							setOpen(navigate, '/attiva', props.action, 'termina', [], false);
						}}
					/>
				</ModalHeader>
				<ModalBody className='d-flex flex-column align-items-start justify-content-start'>
					Sei sicuro di voler terminare l’asta? Tutti i partecipanti verranno disconnessi.
				</ModalBody>
				<ModalFooter className='d-flex justify-content-between align-items-center'>
					<Button
						color={'danger'}
						isOutline={true}
						onClick={() => {
							setOpen(navigate, '/attiva', props.action, 'termina', [], false);
						}}>
						Indietro
					</Button>
					<Button
						color={'danger'}
						onClick={() => {
							handleAuctionEnd();
						}}>
						Conferma
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
