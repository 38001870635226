import { createAsyncThunk } from '@reduxjs/toolkit';
import { campionatoApi } from '../../api/campionato/campionato.api';

enum ChampionshipAction {
	FIND_DETAIL = 'campionato/findDetail',
}

const findDetail = createAsyncThunk(
	ChampionshipAction.FIND_DETAIL,
	async (args: { leagueId: string }, thunkAPI) => {
		try {
			const { leagueId } = args;
			const championship = await campionatoApi.findDetail(leagueId);
			return championship;
		} catch (e) {
			throw e;
		}
	},
);

export const championshipAction = {
	findDetail,
};
