import React, { SVGProps } from 'react';

const SvgPanoramaVerticalSelect = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M18.5 12c0-3.89.84-6.95 1.43-8.69A.993.993 0 0018.98 2H5c-.68 0-1.16.66-.95 1.31C4.74 5.36 5.5 8.1 5.5 12c0 3.87-.76 6.66-1.45 8.69-.21.65.27 1.31.95 1.31h14c.68 0 1.17-.66.95-1.31-.68-2.03-1.45-4.83-1.45-8.69z' />
		</svg>
	);
};

export default SvgPanoramaVerticalSelect;
