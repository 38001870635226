import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getInfluencers } from '../api/influencer/influencer.api';
import { Influencer } from '../api/influencer/influencer.model';
import { getUserPreferences } from '../api/user/user.api';
import { CustomEventName } from '../event.type';
import AuthContext from './authContext';

interface InfluencerContextType {
	selectedInfluencer: Influencer | undefined;
	setSelectedInfluencer: React.Dispatch<React.SetStateAction<Influencer | undefined>>;
	influencersList: Influencer[];
	setInfluencersList: React.Dispatch<React.SetStateAction<Influencer[]>>;
	setHasBeenReferred: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InfluencerContext = createContext<InfluencerContextType | undefined>(undefined);

export const InfluencerProvider = ({ children }: { children?: ReactNode }) => {
	const [mainInfluencerId, setMainInfluencerId] = useState<string | undefined>(undefined);
	const [selectedInfluencer, setSelectedInfluencer] = useState<Influencer | undefined>(undefined);
	const [influencersList, setInfluencersList] = useState<Influencer[]>([]);
	const [tokenReceived, setTokenReceived] = useState<boolean>(false);
	const [hasBeenReferred, setHasBeenReferred] = useState<boolean>(false);
	const { userSubscriptionInfo } = useContext(AuthContext);

	useEffect(() => {
		const handleTokenSuccess: () => void = () => {
			setTokenReceived(true);
		};

		window.addEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);

		return () => {
			window.removeEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);
		};
	}, []);

	useEffect(() => {
		if (tokenReceived) {
			const referralCode = localStorage.getItem('referralCode');

			if (referralCode && hasBeenReferred) {
				getUserPreferences().then((res) => {
					setMainInfluencerId(res.mainInfluencerId);
					setHasBeenReferred(false);
				});
			} else {
				getUserPreferences().then((res) => {
					setMainInfluencerId(res.mainInfluencerId);
				});
			}
		}
	}, [tokenReceived, hasBeenReferred]);

	useEffect(() => {
		if (tokenReceived) {
			getInfluencers()
				.then((ret) => {
					setInfluencersList(ret);
				})
				.catch((e) => {
					console.error(e);
				});
		}
	}, [tokenReceived]);

	useEffect(() => {
		if (userSubscriptionInfo.role === 'INFLUENCER') {
			setMainInfluencerId(userSubscriptionInfo.influencerId);
		}
	}, [userSubscriptionInfo]);

	useEffect(() => {
		if (influencersList.length !== 0 && mainInfluencerId) {
			const selectedInfluencer = influencersList.find(
				(influencer) => influencer.id === mainInfluencerId,
			);
			if (setSelectedInfluencer) {
				setSelectedInfluencer(selectedInfluencer);
			}
		}
	}, [mainInfluencerId, influencersList]);

	return (
		<InfluencerContext.Provider
			value={{
				selectedInfluencer,
				setSelectedInfluencer,
				influencersList,
				setInfluencersList,
				setHasBeenReferred,
			}}>
			{children}
		</InfluencerContext.Provider>
	);
};
