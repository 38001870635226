import { apiBuilder } from '../api.config';
import { Referral } from './user.model';

export const getUserPreferences = (): Promise<any> => {
	const URL = `users/preferences`;
	return apiBuilder.get<any>(URL);
};

export const setMainInfluencerIdAPI = (influencerId: string): Promise<any> => {
	const URL = `/users/preferences/influencers/${influencerId}`;
	return apiBuilder.post<any, any>(URL);
};

export const deleteMainInfluencerIdAPI = (): Promise<any> => {
	const URL = `/users/preferences/influencers`;
	return apiBuilder.delete<any>(URL);
};

export const setLastLeagueIdAPI = (leagueId: string): Promise<any> => {
	const URL = `/users/preferences/leagues/${leagueId}`;
	return apiBuilder.post<any, any>(URL);
};

export const getUserReferrals = (): Promise<Referral> => {
	const URL = `users/referrals`;
	return apiBuilder.get<Referral>(URL);
};

export const setUserReferrals = (): Promise<Referral> => {
	const URL = `users/referrals`;
	return apiBuilder.post<Referral, any>(URL);
};

export const joinReferralGroup = (referralCode: string): Promise<any> => {
	const URL = `/users/referrals/${referralCode}`;
	return apiBuilder.post<any, any>(URL);
};