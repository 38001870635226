import { RootState } from '../store';
import { ExportLink, ExtendedPlayerDTO, FindAllQueryParams, PlayerDoubt } from '../../api/player/player.model';
import {Plan} from "../../api/plan/plan.model";

const getAllPlayers = (state: RootState): ExtendedPlayerDTO[] => state.player.list;
const getTotalPages = (state: RootState): number => state.player.totalPages;
const getPageNumber = (state: RootState): number => state.player.pageNumber;
const getQueryParams = (state: RootState): FindAllQueryParams | undefined =>
	state.player.queryParams;
const isLoading = (state: RootState): boolean => state.player.loading;
const isError = (state: RootState): boolean => state.player.error;
const getAddPlayerMessageError = (state: RootState): string => state.player.addPlayerMessageError;
const getRemovePlayerMessageError = (state: RootState): string =>
	state.player.removePlayerMessageError;

const exportAll = (state: RootState): ExportLink => state.player.exportAll;
// const exportAll = (state: RootState): Blob => state.player.exportAll;
const isLoadingExport = (state: RootState): boolean => state.player.loadingExport;
const isErrorExport = (state: RootState): boolean => state.player.errorExport;

const comparePlayers = (state: RootState): PlayerDoubt[] => state.player.comparisonResult;
export const playerSelector = {
	getAllPlayers,
	getTotalPages,
	getPageNumber,
	getQueryParams,
	isLoading,
	isError,
	getAddPlayerMessageError,
	getRemovePlayerMessageError,
	exportAll,
	isLoadingExport,
	isErrorExport,
	comparePlayers
};
