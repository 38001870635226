import { useContext, useEffect, useState } from 'react';
import AuthContext from '../contexts/authContext';
import ad1 from '../assets/img/ad1.png';
import ad2 from '../assets/img/ad2.png';
import ad3 from '../assets/img/ad3.png';

const Ads = () => {
	const [isMobile, setIsMobile] = useState(false);
	const { userSubscriptionInfo } = useContext(AuthContext);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize(); // Check initially
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			{!userSubscriptionInfo?.paidSubscription && !isMobile && (
				<>
					<div className='col-12 mb-1 d-flex justify-content-between align-items-center gap-1 px-2 w-100'>
						{/* <a
							href='https://record.betsson.it/_KwlcCS55ieVEtDG9aSt81mNd7ZgqdRLk/1/'
							target='_blank'
							style={{ width: '100%' }}
							className='d-none d-md-inline-block'
							rel='noreferrer'>
							<img className='bg-success w-100 rounded-1' src={ad1}></img>
						</a>
						<a
							href='https://record.starcasino.it/_KwlcCS55ieVOEOCTjRhJuGNd7ZgqdRLk/1/'
							target='_blank'
							style={{ width: '100%' }}
							className='d-none d-md-inline-block'
							rel='noreferrer'>
							<img className='bg-success w-100 rounded-1' src={ad2}></img>
						</a>
						<a
							href='https://www.eurobet.it/it/' //TODO: inserire link corretto
							target='_blank'
							style={{ width: '100%' }}
							className='d-none d-md-inline-block'
							rel='noreferrer'>
							<img className='bg-success w-100 rounded-1' src={ad3}></img>
						</a> */}
					</div>
				</>
			)}
			{!userSubscriptionInfo.paidSubscription && isMobile && (
				<div className='d-flex justify-content-center align-items-center w-100'>
					{/* <div className='col-12 mb-1 d-flex flex-column justify-content-center align-items-center gap-1 px-2 w-75'>
						<a
							href='https://record.betsson.it/_KwlcCS55ieVEtDG9aSt81mNd7ZgqdRLk/1/'
							target='_blank'
							style={{ width: '100%' }}
							className='d-md-inline-block'
							rel='noreferrer'>
							<img className='bg-success w-100 rounded-1' src={ad1}></img>
						</a>
						<a
							href='https://record.starcasino.it/_KwlcCS55ieVOEOCTjRhJuGNd7ZgqdRLk/1/'
							target='_blank'
							style={{ width: '100%' }}
							className='d-md-inline-block'
							rel='noreferrer'>
							<img className='bg-success w-100 rounded-1' src={ad2}></img>
						</a>
						<a
							href='https://www.eurobet.it/it/' //TODO: inserire link corretto
							target='_blank'
							style={{ width: '100%' }}
							className='d-md-inline-block'
							rel='noreferrer'>
							<img className='bg-success w-100 rounded-1' src={ad3}></img>
						</a>
					</div> */}
				</div>
			)}
		</>
	);
};

export default Ads;
