import { useContext } from 'react';
import placeholderTeam from '../../../../assets/img/placeholder-logo-team.svg';
import Badge from '../../../../components/bootstrap/Badge';
import Card, { CardBody, CardHeader } from '../../../../components/bootstrap/Card';
import ListGroup, { ListGroupItem } from '../../../../components/bootstrap/ListGroup';
import { AuctionContext } from '../../../../contexts/auctionContext';

interface renderTeamGridViewProps {
	team: any;
	rowView: boolean;
	onePlayerListItem: (rowView: boolean, team: any, playerName: string) => JSX.Element;
}

export const RenderTeamGridView = (props: renderTeamGridViewProps) => {
	const renderPortieriGridView = (rowView: boolean) => {
		return (
			<>
				<ListGroupItem
					tag={'li'}
					className='d-flex justify-content-between align-items-center bg-l25-warning fs-5 px-4'>
					<p className='mb-0 fw-bold text-warning'>Portieri</p>
					<Badge color={'warning'}>
						{spentCreditsForCategory(props.team.goalkeepers)}
					</Badge>
				</ListGroupItem>

				{props.team.goalkeepers && props.team.goalkeepers.length > 0 ? (
					props.team.goalkeepers.map((gk: any) => {
						return props.onePlayerListItem(rowView, props.team, gk);
					})
				) : (
					<div className="text-center w-100">Non sono presenti giocatori</div>
				)}
			</>
		);
	};

	const renderDifensoriGridView = (rowView: boolean) => {
		return (
			<>
				<ListGroupItem
					tag={'li'}
					className='d-flex justify-content-between align-items-center bg-l25-success fs-5 px-4'>
					<p className='mb-0 fw-bold text-success'>Difensori</p>
					<Badge color={'success'}>{spentCreditsForCategory(props.team.defenders)}</Badge>
				</ListGroupItem>
				{props.team.defenders && props.team.defenders.length > 0 ? (
					props.team.defenders.map((df: any) => {
						return props.onePlayerListItem(rowView, props.team, df);
					})
				) : (
					<div className="text-center w-100">Non sono presenti giocatori</div>
				)}
			</>
		);
	};

	const renderCentrocampistiGridView = (rowView: boolean) => {
		return (
			<>
				<ListGroupItem
					tag={'li'}
					className='d-flex justify-content-between align-items-center bg-l25-info fs-5 px-4'>
					<p
						className='mb-0 fw-bold text-info'
						style={{
							maxWidth: '100%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}>
						Centrocampisti
					</p>
					<Badge color={'info'}>{spentCreditsForCategory(props.team.midfielders)}</Badge>
				</ListGroupItem>
				{props.team.midfielders && props.team.midfielders.length > 0 ? (
					props.team.midfielders.map((mf: any) => {
						return props.onePlayerListItem(rowView, props.team, mf);
					})
				) : (
					<div className="text-center w-100">Non sono presenti giocatori</div>
				)}
			</>
		);
	};

	const renderAttaccantiGridView = (rowView: boolean) => {
		return (
			<>
				<ListGroupItem
					tag={'li'}
					className='d-flex justify-content-between align-items-center bg-l25-danger fs-5 px-4'>
					<p className='mb-0 fw-bold text-danger'>Attaccanti</p>
					<Badge color={'danger'}>{spentCreditsForCategory(props.team.forwards)}</Badge>
				</ListGroupItem>
				{props.team.forwards && props.team.forwards.length > 0 ? (
					props.team.forwards.map((fw: any) => {
						return props.onePlayerListItem(rowView, props.team, fw);
					})
				) : (
					<div className="text-center w-100">Non sono presenti giocatori</div>
				)}
			</>
		);
	};

	function spentCreditsForCategory(category: any) {
		let spent = 0;
		category.forEach((gk: any) => {
			spent += gk.price;
		});
		return spent;
	}
	function spentCredits() {
		return props.team.initialCredits - props.team.remainingCredits;
	}

	return (
		<>
			<Card style={{ minWidth: '200px' }}>
				<CardHeader className='bg-l25-dark p-1 px-4'>
					<div className='d-flex justify-content-between align-items-center w-100 fs-5'>
						<div className='d-flex justify-content-start align-items-center gap-1'>
							<img
								style={{ maxWidth: 40, maxHeight: 40 }}
								className='rounded-1'
								src={props.team.placeholderTeam}></img>
							<p className='mb-0 fw-bold text-white'>{props.team.name}</p>
						</div>
						<Badge color={'dark'}>{spentCredits()}</Badge>
					</div>
				</CardHeader>
				<CardBody className='p-0'>
					<ListGroup tag={'ol'} isNumbered={false} isFlush={true}>
						{renderPortieriGridView(props.rowView)}
						{renderDifensoriGridView(props.rowView)}
						{renderCentrocampistiGridView(props.rowView)}
						{renderAttaccantiGridView(props.rowView)}
					</ListGroup>
				</CardBody>
			</Card>
		</>
	);
};
