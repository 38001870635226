import { useNavigate } from 'react-router-dom';
import RiepilogoMobile from './riepilogoMobile';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import TeamDetails from './teamDetails';
import { useState } from 'react';
import { getOpen, setOpen } from '../../../../helpers/navigation';

interface RiepilogoColumnProps {
	isMobile: boolean;
	parentUri: string;
	action: string | undefined;
}

export const isRiepilogoActive = (action: string | undefined) =>
	Boolean(getOpen(action, 'riepilogo'));

export const RiepilogoColumn = (props: RiepilogoColumnProps) => {
	const navigate = useNavigate();
	const isOpen = Boolean(getOpen(props.action, 'riepilogo'));

	return !props.isMobile ? (
		isOpen ? (
			<div className={`col-md-4`}>
				<Card className='card-stretch-full overflow-hidden'>
					<CardBody isScrollable={true}>
						<TeamDetails></TeamDetails>
					</CardBody>
				</Card>
			</div>
		) : null
	) : (
		<RiepilogoMobile
			riepiloghiActive={isOpen}
			setRiepiloghiActive={() => {
				setOpen(navigate, props.parentUri, props.action, 'riepilogo', [], !isOpen);
			}}
		/>
	);
};
