import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { updateLeague } from '../../../../../api/league/league.api';
import Button from '../../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../../components/bootstrap/Card';
import Checks from '../../../../../components/bootstrap/forms/Checks';
import Input from '../../../../../components/bootstrap/forms/Input';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../../components/bootstrap/Modal';
import { LeagueContext } from '../../../../../contexts/leagueContext';
import { ToastContext } from '../../../../../contexts/toastContext';
import { useNavigate, useParams } from 'react-router-dom';

const RulesSection = () => {
	const { setHasRulesBeenUpdated, selectedLeague, setSelectedLeague, newRules, setNewRules } =
		useContext(LeagueContext)!;
	const { setToast } = useContext(ToastContext);
	const { action } = useParams();
	const navigate = useNavigate();

	const [canOpenModal, setCanOpenModal] = useState(false);

	const [nrPartecipanti, setNrPartecipanti] = useState<number>();
	const [credits, setCredits] = useState<number>();
	const [maxSubstitutions, setMaxSubstitutions] = useState<number>();
	const [nrDifensori, setNrDifensori] = useState<number>();
	const [nrCentrocampisti, setNrCentrocampisti] = useState<number>();
	const [nrAttaccanti, setNrAttaccanti] = useState<number>();
	const [nrPortieri, setNrPortieri] = useState<number>();
	const [undefeatedGoalkeeper, setUndefeatedGoalkeeper] = useState<boolean>(false);
	const [modificatoreDifesa, setModificatoreDifesa] = useState<boolean>(false);
	const [modificatoreAttacco, setModificatoreAttacco] = useState<boolean>(false);
	const [modificatoreCentrocampo, setModificatoreCentrocampo] = useState<boolean>(false);
	const [modificatoreFairplay, setModificatoreFairplay] = useState<boolean>(false);

	const showRulesSettings = Boolean(action?.includes('modifica_regole'));
	const setShowRulesSettings = (open: boolean) => {
		const newAction =
			(action
				? action
						.split('-')
						.filter((a) => a !== 'modifica_regole')
						.join('-')
				: '') + (open ? 'modifica_regole' : '');
		navigate(`/setup-lega/${newAction}`);
	};

	useEffect(() => {
		if (showRulesSettings) {
			setNewRules(selectedLeague.rules);
			setNrPartecipanti(selectedLeague.rules.participants);
			setCredits(selectedLeague.rules.credits);
			setMaxSubstitutions(selectedLeague.rules.maxSubstitutions);
			setNrDifensori(selectedLeague.rules.numberDefenders);
			setNrCentrocampisti(selectedLeague.rules.numberMidfielders);
			setNrAttaccanti(selectedLeague.rules.numberForwards);
			setNrPortieri(selectedLeague.rules.numberGoalkeepers);
			setUndefeatedGoalkeeper(selectedLeague.rules.undefeatedGoalkeeper);
			setModificatoreAttacco(selectedLeague.rules.forwardModifier);
			setModificatoreCentrocampo(selectedLeague.rules.midfieldModifier);
			setModificatoreDifesa(selectedLeague.rules.defenseModifier);
			setModificatoreFairplay(selectedLeague.rules.fairplayModifier);
			setCanOpenModal(true);
		}
	}, [showRulesSettings]);

	const onSave = async () => {
		if (newRules) {
			const newSelectedLeague = {
				...selectedLeague,
				rules: { ...selectedLeague.rules, ...newRules },
			};
			updateLeague(selectedLeague?.id, newSelectedLeague).then((res) => {
				setToast({
					level: 'success',
					title: 'Impostazioni salvate',
					message: `Le impostazioni della lega ${selectedLeague?.name} sono state aggiornate`,
				});
				setShowRulesSettings(false);
				setHasRulesBeenUpdated(true);
			});
		}
	};

	return (
		<>
			<Card>
				<CardHeader borderSize={3} borderColor={'light'}>
					<CardLabel icon='Settings' className={'d-flex align-items-center mb-0'}>
						<CardTitle>
							<span className='h3 fw-bold m-0 d-inline-block'>Impostazioni</span>
						</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							color='light'
							icon='Edit'
							onClick={() => {
								setNewRules(selectedLeague.rules);
								setShowRulesSettings(true);
							}}>
							<span className='d-none d-sm-inline'>Modifica</span>
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th scope='col'>Regola</th>
								<th scope='col'>Valore</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Partecipanti</td>
								<td>{selectedLeague.rules.participants}</td>
							</tr>
							<tr>
								<td>Crediti</td>
								<td>{selectedLeague.rules.credits}</td>
							</tr>
							<tr>
								<td>Sostituzioni Max</td>
								<td>{selectedLeague.rules.maxSubstitutions}</td>
							</tr>
							<tr>
								<td>Numero Difensori</td>
								<td>{selectedLeague.rules.numberDefenders}</td>
							</tr>
							<tr>
								<td>Numero Centrocampisti</td>
								<td>{selectedLeague.rules.numberMidfielders}</td>
							</tr>
							<tr>
								<td>Numero Attaccanti</td>
								<td>{selectedLeague.rules.numberForwards}</td>
							</tr>
							<tr>
								<td>Numero Portieri</td>
								<td>{selectedLeague.rules.numberGoalkeepers}</td>
							</tr>
							<tr>
								<td>Portiere Imbattuto Bonus</td>
								<td>
									<Checks
										type={'switch'}
										disabled={true}
										checked={
											selectedLeague.rules.undefeatedGoalkeeper
										}></Checks>
								</td>
							</tr>
							<tr>
								<td>Modificatore Difesa</td>
								<td>
									<Checks
										type={'switch'}
										disabled={true}
										checked={selectedLeague.rules.defenseModifier}></Checks>
								</td>
							</tr>
							<tr>
								<td>Modificatore Centrocampo</td>
								<td>
									<Checks
										type={'switch'}
										disabled={true}
										checked={selectedLeague.rules.midfieldModifier}></Checks>
								</td>
							</tr>
							<tr>
								<td>Modificatore Attacco</td>
								<td>
									<Checks
										type={'switch'}
										disabled={true}
										checked={selectedLeague.rules.forwardModifier}></Checks>
								</td>
							</tr>
							<tr>
								<td>Modificatore Fairplay</td>
								<td>
									<Checks
										type={'switch'}
										disabled={true}
										checked={selectedLeague.rules.fairplayModifier}></Checks>
								</td>
							</tr>
						</tbody>
					</table>
				</CardBody>
			</Card>
			<Modal
				isOpen={showRulesSettings}
				setIsOpen={setShowRulesSettings}
				isCentered={true}
				isAnimation={true}>
				<ModalHeader showClose={false} className={''} setIsOpen={setShowRulesSettings}>
					<ModalTitle id={''}>Impostazioni</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							// setHasModalBeenClosed(true);
							setShowRulesSettings(false);
						}}
					/>
				</ModalHeader>
				<ModalBody className={''}>
					{canOpenModal && (
						<div className='container p-0'>
							<form className='row'>
								<>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Partecipanti</label>
										</div>
										<div className='col-6'>
											<Input
												type='text'
												placeholder={`${nrPartecipanti}`}
												required={true}
												disabled={true}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setNrPartecipanti(Number(e.target.value));
													setNewRules({
														...newRules,
														participants: Number(e.target.value),
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Crediti</label>
										</div>
										<div className='col-6'>
											<Input
												type='text'
												value={credits}
												required={true}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setCredits(Number(e.target.value));
													setNewRules({
														...newRules,
														credits: Number(e.target.value),
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Sostituzioni Max</label>
										</div>
										<div className='col-6'>
											<Input
												type='text'
												value={maxSubstitutions}
												required={true}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setMaxSubstitutions(Number(e.target.value));
													setNewRules({
														...newRules,
														maxSubstitutions: Number(e.target.value),
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Numero Difensori</label>
										</div>
										<div className='col-6'>
											<Input
												type='text'
												value={nrDifensori}
												required={true}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setNrDifensori(Number(e.target.value));
													setNewRules({
														...newRules,
														numberDefenders: Number(e.target.value),
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Numero Centrocampisti</label>
										</div>
										<div className='col-6'>
											<Input
												type='text'
												value={nrCentrocampisti}
												required={true}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setNrCentrocampisti(Number(e.target.value));
													setNewRules({
														...newRules,
														numberMidfielders: Number(e.target.value),
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Numero Attaccanti</label>
										</div>
										<div className='col-6'>
											<Input
												type='text'
												value={nrAttaccanti}
												required={true}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setNrAttaccanti(Number(e.target.value));
													setNewRules({
														...newRules,
														numberForwards: Number(e.target.value),
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Numero Portieri</label>
										</div>
										<div className='col-6'>
											<Input
												type='text'
												value={nrPortieri}
												required={true}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setNrPortieri(Number(e.target.value));
													setNewRules({
														...newRules,
														numberGoalkeepers: Number(e.target.value),
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Portiere Imbattuto Bonus</label>
										</div>
										<div className='col-6'>
											<Checks
												type='switch'
												style={{ height: '22px', width: '33px' }}
												checked={undefeatedGoalkeeper}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setUndefeatedGoalkeeper(e.target.checked);
													setNewRules({
														...newRules,
														numberGoalkeepers: e.target.checked,
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Modificatore Difesa</label>
										</div>
										<div className='col-6'>
											<Checks
												type='switch'
												style={{ height: '22px', width: '33px' }}
												checked={modificatoreDifesa}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setModificatoreDifesa(e.target.checked);
													setNewRules({
														...newRules,
														defenseModifier: e.target.checked,
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Modificatore Centrocampo</label>
										</div>
										<div className='col-6'>
											<Checks
												type='switch'
												style={{ height: '22px', width: '33px' }}
												checked={modificatoreCentrocampo}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setModificatoreCentrocampo(e.target.checked);
													setNewRules({
														...newRules,
														midfieldModifier: e.target.checked,
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Modificatore Attacco</label>
										</div>
										<div className='col-6'>
											<Checks
												type='switch'
												style={{ height: '22px', width: '33px' }}
												checked={modificatoreAttacco}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setModificatoreAttacco(e.target.checked);
													setNewRules({
														...newRules,
														forwardModifier: e.target.checked,
													});
												}}
											/>
										</div>
									</div>
									<div className={'d-flex mb-3'}>
										<div className='col-6 d-flex align-items-center'>
											<label>Modificatore Fairplay</label>
										</div>
										<div className='col-6'>
											<Checks
												type='switch'
												style={{ height: '22px', width: '33px' }}
												checked={modificatoreFairplay}
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													setModificatoreFairplay(e.target.checked);
													setNewRules({
														...newRules,
														fairplayModifier: e.target.checked,
													});
												}}
											/>
										</div>
									</div>
								</>
							</form>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button icon='save' color='info' onClick={onSave}>
						Salva
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default RulesSection;
