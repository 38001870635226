import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import Select from '../../../../components/bootstrap/forms/Select';
import { AuctionContext } from '../../../../contexts/auctionContext';
import Input from '../../../../components/bootstrap/forms/Input';
import { getOpen, setOpen } from '../../../../helpers/navigation';

interface AssignPlayerModalProps {
	assigned: boolean | undefined;
	calledPlayer: any;
	selectCalledPlayer: any;
	action: string | undefined;
	parentUri: string;
	assignPlayer: (assignedTeam: any, price: number) => void;
}

export const isAssignPlayerModalActive = (action: string | undefined) =>
	Boolean(action?.includes('assegna'));

export const AssignPlayerModal = (props: AssignPlayerModalProps) => {
	const navigate = useNavigate();
	const { teams } = useContext(AuctionContext)!;
	const [assignedTeam, setAssignedTeam] = useState<any>();
	const [price, setPrice] = useState<number>(0);

	const isOpen = Boolean(getOpen(props.action, 'assegna'));

	return (
		<>
			<Modal
				id={'assegna'}
				titleId={'Assegna Giocatore'}
				isOpen={isOpen}
				setIsOpen={() => {
					setOpen(navigate, props.parentUri, props.action, 'assegna', [], !isOpen);
				}}
				isStaticBackdrop={true}
				isScrollable={true}
				isCentered={true}
				size={'sm'}
				fullScreen={false}>
				<ModalHeader showClose={false} setIsOpen={setOpen}>
					<ModalTitle id={'share'}>Assegnazione Manuale</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							setOpen(navigate, props.parentUri, props.action, 'assegna', [], false);
						}}
					/>
				</ModalHeader>
				<ModalBody className='d-flex flex-column align-items-start justify-content-start'>
					<div>
						<p className='text-muted mb-1 mt-1'>Seleziona Squadra</p>
					</div>
					<Select
						className={'mb-3 text-muted'}
						id={'team'}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
							setAssignedTeam(teams.find((team: any) => team.id === e.target.value));
						}}
						ariaLabel={'selectedTeam'}
						placeholder={'Seleziona Squadra'}>
						{teams?.map((t: any) => {
							return (
								<option key={t.id} value={t.id}>
									{t.name}
								</option>
							);
						})}
					</Select>

					<div>
						<p className='text-muted mb-1 mt-1'>Costo</p>
					</div>
					<Input
						placeholder='Nome'
						type={'number'}
						value={price}
						isValid={price >= 0}
						invalidFeedback={'Inserisci un valore valido'}
						required={true}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setPrice(Number(e.target.value));
						}}
						ariaLabel='Input'
					/>
				</ModalBody>
				<ModalFooter className='d-flex justify-content-between align-items-center'>
					<Button
						color={'danger'}
						isOutline={true}
						onClick={() => {
							setOpen(navigate, props.parentUri, props.action, 'assegna', [], false);
						}}>
						Indietro
					</Button>
					<Button
						className=''
						color={'danger'}
						isDisable={!assignedTeam}
						onClick={() => {
							props.assignPlayer(assignedTeam, price);
							setOpen(navigate, props.parentUri, props.action, 'assegna', [], false);
						}}>
						Assegna
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
