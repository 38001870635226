import React, { SVGProps } from 'react';

const SvgBugReport = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M15.83 9.65L15.46 9c-.3-.53-.71-.96-1.21-1.31l-.61-.42-.68-.16C12.63 7.04 12.32 7 12 7c-.31 0-.63.04-.94.11l-.74.18-.57.4A4.1 4.1 0 008.54 9l-.37.65-.1.65c-.04.23-.07.48-.07.7v4c0 .22.03.47.07.7l.1.65.37.65c.72 1.24 2.04 2 3.46 2s2.74-.77 3.46-2l.37-.64.1-.65c.04-.24.07-.49.07-.71v-4c0-.22-.03-.47-.07-.7l-.1-.65zM14 16h-4v-2h4v2zm0-4h-4v-2h4v2z'
				opacity={0.3}
			/>
			<path d='M20 8h-2.81a5.985 5.985 0 00-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5s-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-4 4v3c0 .22-.03.47-.07.7l-.1.65-.37.65c-.72 1.24-2.04 2-3.46 2s-2.74-.77-3.46-2l-.37-.64-.1-.65C8.03 15.47 8 15.22 8 15v-4c0-.22.03-.47.07-.7l.1-.65.37-.65c.3-.52.72-.97 1.21-1.31l.57-.39.74-.18a3.787 3.787 0 011.89 0l.68.16.61.42c.5.34.91.78 1.21 1.31l.38.65.1.65c.04.22.07.47.07.69v1zm-6 2h4v2h-4zm0-4h4v2h-4z' />
		</svg>
	);
};

export default SvgBugReport;
