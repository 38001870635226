import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import { InfluencerContext } from '../../contexts/influencerContext';
import placeHolderInfluencer from '../../assets/img/placeholder-influencer.svg';

const Influencers = () => {
	const navigate = useNavigate();
	const { selectedInfluencer } = useContext(InfluencerContext) || {};

	return (
		<>
			<div
				className='user open d-flex align-items-center justify-content-between'
				role='presentation'
				data-tour='handle-leagues'
				onClick={() => {
					navigate('/lista-creator');
				}}>
				<div className='d-flex align-items-center justify-content-start gap-2'>
					<img
						style={{ width: 30, height: 30, borderRadius: '50%' }}
						className='rounded-1'
						src={
							!selectedInfluencer?.pathLogo
								? placeHolderInfluencer
								: selectedInfluencer.pathLogo
						}></img>

					<div className='user-info'>
						{selectedInfluencer && (
							<div className='user-name d-flex align-items-center'>
								{selectedInfluencer.name}
							</div>
						)}
						{!selectedInfluencer && (
							<div className='user-name d-flex align-items-center'>Seleziona</div>
						)}
						<div className='user-sub-title'>Il tuo creator</div>
					</div>
				</div>
				<div>
					<Icon icon={'KeyboardArrowRight'} size={'lg'} />
				</div>
			</div>
		</>
	);
};

export default Influencers;
