import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { getUserSubscriptionInfo } from '../api/profile/profile.api';
import { Subscription } from '../api/profile/profile.model';
import { CustomEventName } from '../event.type';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userSubscriptionInfo: Subscription;
	authToken?: string;
	tokenReceived?: boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [tokenReceived, setTokenReceived] = useState<boolean>(false);

	const [userSubscriptionInfo, setUserSubscriptionInfo] = useState<any>({
		influencerId: '',
		firstName: '',
		lastName: '',
		email: '',
		role: 'BOMBER',
		paidSubscription: true,
		subscriptionExpiration: '',
	});
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');

	const [authToken, setAuthToken] = useState<string>('');

	useEffect(() => {
		const handleTokenSuccess: (e: any) => void = (e) => {
			setAuthToken(e.detail);
			setTokenReceived(true);
		};

		window.addEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);

		return () => {
			window.removeEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);
		};
	}, []);

	useEffect(() => {
		if (tokenReceived)
			getUserSubscriptionInfo().then((res) => {
				setUserSubscriptionInfo(res);
			});
	}, [tokenReceived]);

	useEffect(() => {
		localStorage.setItem('facit_authUsername', user);
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userSubscriptionInfo,
			authToken,
			tokenReceived,
		}),
		[user, userSubscriptionInfo, authToken],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
