import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/bootstrap/Button';
import Collapse from '../../components/bootstrap/Collapse';
import Spinner from '../../components/bootstrap/Spinner';
import Icon from '../../components/icon/Icon';
import { ToastContext } from '../../contexts/toastContext';
import { useCreateLeague } from '../../hooks/businessLogic/useCreateLeague';
import { useFindLeagues } from '../../hooks/businessLogic/useFindLeagues';
import { mainMenu } from '../../menu';
import { NavigationLine } from '../Navigation/Navigation';
import { setLastLeagueIdAPI } from '../../api/user/user.api';
import { LeagueContext } from '../../contexts/leagueContext';
import placeholderLogoLega from '../../assets/img/placeholder-logo-lega.svg';
import { createLeague, findAll } from '../../api/league/league.api';
import AuthContext from '../../contexts/authContext';

const Leagues = () => {
	const navigate = useNavigate();
	const {
		leagues,
		selectedLeague,
		setSelectedLeague,
		hasNameBeenUpdated,
		hasDeletedLeague,
		setHasDeletedLeague,
		setLeagues,
		setLastLeagueId,
	} = useContext(LeagueContext)!;
	const { userSubscriptionInfo } = useContext(AuthContext);
	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const { setToast } = useContext(ToastContext);

	const [localLeagues, setLocalLeagues] = useState<any[]>([]);

	useEffect(() => {
		findAll()
			.then((res) => {
				setLocalLeagues(res);
			})
			.catch((err) => {});
	}, [hasNameBeenUpdated]);

	useEffect(() => {
		findAll()
			.then((res) => {
				setLocalLeagues(res);
				setLeagues(res);
				if (res.length === 0) {
					setSelectedLeague(undefined);
				}
				setHasDeletedLeague(false);
			})
			.catch((err) => {});
	}, [hasDeletedLeague]);

	useEffect(() => {
		findAll()
			.then((res) => {
				setLocalLeagues(res);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		if (leagues) setLocalLeagues(leagues);
	}, [leagues]);

	return (
		<>
			<div
				className='user open d-flex align-items-center justify-content-between'
				role='presentation'
				data-tour='handle-leagues'
				onClick={() => {
					setCollapseStatus(!collapseStatus);
				}}>
				<div className='d-flex align-items-center justify-content-start gap-2'>
					<img
						style={{ width: 30, height: 30, borderRadius: '50%' }}
						src={placeholderLogoLega}></img>
					<div className='user-info'>
						{selectedLeague && (
							<div className='user-name d-flex align-items-center'>
								{selectedLeague?.name}
							</div>
						)}
						<div className='user-sub-title'>La Tua Lega</div>
					</div>
				</div>
				<div>
					<Icon
						icon={collapseStatus ? 'KeyboardArrowDown' : 'KeyboardArrowRight'}
						size={'lg'}
					/>
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div className='d-flex flex-column px-3'>
							{localLeagues.map((league) => {
								return (
									<span
										key={league.id}
										className={`navigation-link navigation-link-pill ${
											selectedLeague?.id === league.id ? 'active' : ''
										}`}
										onClick={() => {
											if (selectedLeague?.id !== league.id) {
												setSelectedLeague(league);
											}
										}}>
										<span className='navigation-link-info'>
											<img
												style={{
													width: 30,
													height: 30,
													borderRadius: '50%',
												}}
												src={placeholderLogoLega}></img>
											<span className='navigation-text'>{league.name}</span>
										</span>
									</span>
								);
							})}
							<Button
								color='primary'
								className={'mt-2 w-100 text-start mb-2'}
								icon='Add'
								onClick={async (e: any) => {
									e.stopPropagation();
									setCollapseStatus(true);
									if (!userSubscriptionInfo?.paidSubscription) {
										if (localLeagues.length >= 1) {
											setToast({
												level: 'error',
												title: 'Lega non creata',
												message: `Passa a premium per creare più di una lega`,
											});
										} else {
											createLeague('Nuova Lega')
												.then((res) => {
													setLeagues([...localLeagues, res]);
													setLocalLeagues([...localLeagues, res]);
													setToast({
														level: 'success',
														title: 'Lega creata',
														message:
															'È stata creata la lega: Nuova Lega',
													});
												})
												.catch((err) => {
													setToast({
														level: 'error',
														title: 'Lega non creata',
														message:
															'Hai raggiunto il limite di leghe!',
													});
												});
										}
									} else {
										createLeague('Nuova Lega')
											.then((res) => {
												setLeagues([...localLeagues, res]);
												setLocalLeagues([...localLeagues, res]);
												setToast({
													level: 'success',
													title: 'Lega creata',
													message: 'È stata creata la lega: Nuova Lega',
												});
											})
											.catch((err) => {
												setToast({
													level: 'error',
													title: 'Lega non creata',
													message: 'Hai raggiunto il limite di leghe!',
												});
											});
									}
									navigate(`./${mainMenu.leagueSetup.path}`);
								}}>
								<span className={'navigation-text'}>Aggiungi lega</span>
							</Button>
						</div>
					</div>
				</nav>
				<NavigationLine />
			</Collapse>
		</>
	);
};

export default Leagues;
