import placeholderTeam from '../../../../assets/img/placeholder-logo-team.svg';
import Accordion, { AccordionItem } from '../../../../components/bootstrap/Accordion';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import ListGroup from '../../../../components/bootstrap/ListGroup';
import Progress from '../../../../components/bootstrap/Progress';
import Icon from '../../../../components/icon/Icon';
import Button from '../../../../components/bootstrap/Button';
import React, { useContext, useEffect, useState } from 'react';
import { AuctionContext } from '../../../../contexts/auctionContext';
import { getAuctionData } from '../../../../api/auction/auction.api';

interface RenderTeamRowViewProps {
	isMobile: boolean;
	rowView: boolean;
	onePlayerListItem: (rowView: boolean, team: any, playerName: string) => JSX.Element;
	team: any;
}
export const RenderTeamRowView = (props: RenderTeamRowViewProps) => {
	const { auctionId } = useContext(AuctionContext)!;
	const [playerLimits, setPlayersLimits] = useState<string[]>(['3', '8', '8', '6']);
	useEffect(() => {
		if (auctionId) {
			getAuctionData(auctionId).then((data) => {
				setPlayersLimits([
					data.numberGoalkeepers ?? '3',
					data.numberDefenders ?? '8',
					data.numberMidfielders ?? '8',
					data.numberForwards ?? '6',
				]);
			});
		}
	}, [auctionId]);

	const oneTeamStatistics = (team: any) => {
		return (
			<>
				<div className={'d-flex flex-grow-1 flex-column p-2'}>
					<div className={'d-flex justify-content-between mb-2'}>
						<div className='d-flex flex-grow-1 justify-content-start align-items-center gap-2'>
							<img
								style={{ maxWidth: 40, maxHeight: 40 }}
								className='rounded-1'
								src={placeholderTeam}></img>
							<p
								className={`mb-1 fw-bold ${
									!props.isMobile ? 'fs-4' : 'fs-5'
								} text-nowrap`}>
								{team.name}
							</p>
						</div>
						<div className='d-flex gap-2'>
							<Button isDisable={true} shadow={'lg'} icon={'MonetizationOn'}>
								{team.remainingCredits}/{team.initialCredits}
							</Button>
						</div>
					</div>
					<div className={'d-flex justify-content-between mt-2'}>
						<div className='d-flex flex-column flex-grow-1'>
							<Progress>
								<Progress
									value={
										(spentCreditsForCategory(props.team.goalkeepers) /
											team.initialCredits) *
										100
									}
									color={'warning'}></Progress>
								<Progress
									value={
										(spentCreditsForCategory(props.team.defenders) /
											team.initialCredits) *
										100
									}
									color={'success'}></Progress>
								<Progress
									value={
										(spentCreditsForCategory(props.team.midfielders) /
											team.initialCredits) *
										100
									}
									color={'info'}></Progress>
								<Progress
									value={
										(spentCreditsForCategory(props.team.forwards) /
											team.initialCredits) *
										100
									}
									color={'danger'}></Progress>
							</Progress>
							<div className='row mt-3'>
								<div className='col-3 px-1 px-lg-2'>
									<Card
										className='d-flex justify-content-center align-items-center text-warning rounded-1 py-3 mb-1'
										shadow={'3d'}>
										<p
											className='d-flex align-items-center justify-content-center mb-0 w-100'
											style={{ whiteSpace: 'nowrap' }}>
											<span className='d-lg-inline d-xl-none fs-4 fw-normal'>
												P{' '}
											</span>
											<span
												className='d-none d-xl-inline fs-4 fw-normal text-truncate'
												style={{ maxWidth: '70%' }}>
												Portieri{' '}
											</span>
											<span className='fs-4 fw-bold ms-1'>
												{team?.goalkeepers?.length ?? 0}/{playerLimits[0]}
											</span>
										</p>
									</Card>
								</div>
								<div className='col-3 px-1 px-lg-2'>
									<Card
										className='d-flex justify-content-center align-items-center text-success rounded-1 py-3 mb-1'
										shadow={'3d'}>
										<p
											className='d-flex align-items-center justify-content-center mb-0 w-100'
											style={{ whiteSpace: 'nowrap' }}>
											<span className='d-lg-inline d-xl-none fs-4 fw-normal'>
												D{' '}
											</span>
											<span
												className='d-none d-xl-inline fs-4 fw-normal text-truncate'
												style={{ maxWidth: '70%' }}>
												Difensori{' '}
											</span>
											<span className='fs-4 fw-bold ms-1'>
												{team?.defenders?.length ?? 0}/{playerLimits[1]}
											</span>
										</p>
									</Card>
								</div>
								<div className='col-3 px-1 px-lg-2'>
									<Card
										className='d-flex justify-content-center align-items-center text-info rounded-1 py-3 mb-1'
										shadow={'3d'}>
										<p
											className='d-flex align-items-center justify-content-center mb-0 w-100'
											style={{ whiteSpace: 'nowrap' }}>
											<span className='d-lg-inline d-xl-none fs-4 fw-normal'>
												C{' '}
											</span>
											<span
												className='d-none d-xl-inline fs-4 fw-normal text-truncate'
												style={{ maxWidth: '70%' }}>
												Centrocampisti{' '}
											</span>
											<span className='fs-4 fw-bold ms-1'>
												{team?.midfielders?.length ?? 0}/{playerLimits[2]}
											</span>
										</p>
									</Card>
								</div>
								<div className='col-3 px-1 px-lg-2'>
									<Card
										className='d-flex justify-content-center align-items-center text-danger rounded-1 py-3 mb-1'
										shadow={'3d'}>
										<p
											className='d-flex align-items-center justify-content-center mb-0 w-100'
											style={{ whiteSpace: 'nowrap' }}>
											<span className='d-lg-inline d-xl-none fs-4 fw-normal'>
												A{' '}
											</span>
											<span
												className='d-none d-xl-inline fs-4 fw-normal text-truncate'
												style={{ maxWidth: '70%' }}>
												Attacanti{' '}
											</span>
											<span className='fs-4 fw-bold ms-1'>
												{team?.forwards?.length ?? 0}/{playerLimits[3]}
											</span>
										</p>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	function spentCreditsForCategory(category: any) {
		let spent = 0;
		category?.forEach((gk: any) => {
			spent += gk.price;
		});
		return spent;
	}

	const renderTeamPlayers = (team: any) => {
		return (
			<div className={`row`}>
				<div className={'col-12 col-md-6 col-xl-3'}>
					<Card stretch={'full'} shadow={'3d'}>
						<CardHeader
							size={'sm'}
							borderSize={3}
							borderColor={'warning'}
							className='bg-warning bg-gradient text-dark'>
							<CardLabel>
								<CardTitle>Portieri</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									isOutline={true}
									isDisable={true}
									shadow={'lg'}
									icon={'MonetizationOn'}>
									{spentCreditsForCategory(props.team.goalkeepers)}
								</Button>
							</CardActions>
						</CardHeader>
						<CardBody className=' p-2 px-2'>
							<ListGroup tag={'ol'} isNumbered={false} isFlush={true}>
								{team?.goalkeepers && team.goalkeepers.length > 0 ? (
									team.goalkeepers.map((gk: any) => {
										return props.onePlayerListItem(props.rowView, team, gk);
									})
								) : (
									<div className='text-center w-100'>
										Non sono presenti portieri
									</div>
								)}
							</ListGroup>
						</CardBody>
					</Card>
				</div>
				<div className={'col-12 col-md-6 col-xl-3'}>
					<Card stretch={'full'} shadow={'3d'}>
						<CardHeader
							size={'sm'}
							borderSize={3}
							borderColor={'success'}
							className='bg-success bg-gradient text-white'>
							<CardLabel>
								<CardTitle>Difensori</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									isOutline={true}
									isDisable={true}
									shadow={'lg'}
									icon={'MonetizationOn'}>
									{spentCreditsForCategory(props.team.defenders)}
								</Button>
							</CardActions>
						</CardHeader>
						<CardBody className=' p-2 px-2'>
							<ListGroup tag={'ol'} isNumbered={false} isFlush={true}>
								{team?.defenders && team.defenders.length > 0 ? (
									team.defenders.map((df: any) => {
										return props.onePlayerListItem(props.rowView, team, df);
									})
								) : (
									<div className='text-center w-100'>
										Non sono presenti difensori
									</div>
								)}
							</ListGroup>
						</CardBody>
					</Card>
				</div>
				<div className={'col-12 col-md-6 col-xl-3'}>
					<Card stretch={'full'} shadow={'3d'}>
						<CardHeader
							size={'sm'}
							borderSize={3}
							borderColor={'info'}
							className='bg-info bg-gradient text-dark'>
							<CardLabel>
								<CardTitle>Centrocampisti</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									isOutline={true}
									isDisable={true}
									shadow={'lg'}
									icon={'MonetizationOn'}>
									{spentCreditsForCategory(props.team.midfielders)}
								</Button>
							</CardActions>
						</CardHeader>
						<CardBody className=' p-2 px-2'>
							<ListGroup tag={'ol'} isNumbered={false} isFlush={true}>
								{team?.midfielders && team.midfielders.length > 0 ? (
									team.midfielders.map((mf: any) => {
										return props.onePlayerListItem(props.rowView, team, mf);
									})
								) : (
									<div className='text-center w-100'>
										Non sono presenti centrocampisti
									</div>
								)}
							</ListGroup>
						</CardBody>
					</Card>
				</div>
				<div className={'col-12 col-md-6 col-xl-3'}>
					<Card stretch={'full'} shadow={'3d'}>
						<CardHeader
							size={'sm'}
							borderSize={3}
							borderColor={'danger'}
							className='bg-danger bg-gradient text-white'>
							<CardLabel>
								<CardTitle>Attaccanti</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									isOutline={true}
									isDisable={true}
									shadow={'lg'}
									icon={'MonetizationOn'}>
									{spentCreditsForCategory(props.team.forwards)}
								</Button>
							</CardActions>
						</CardHeader>
						<CardBody className=' p-2 px-2'>
							<ListGroup tag={'ol'} isNumbered={false} isFlush={true}>
								{team?.forwards && team.forwards.length > 0 ? (
									team.forwards.map((fw: any) => {
										return props.onePlayerListItem(props.rowView, team, fw);
									})
								) : (
									<div className='text-center w-100'>
										Non sono presenti attaccanti
									</div>
								)}
							</ListGroup>
						</CardBody>
					</Card>
				</div>
			</div>
		);
	};

	return (
		<>
			<Accordion id={'teams'} tag={'div'} color={'dark'} isFlush={false}>
				<AccordionItem
					key={props.team.id}
					id={`${props.team.id}`}
					title={`${props.team.name}`}
					personalizeHeader={true}
					headerContent={oneTeamStatistics(props.team)}>
					{renderTeamPlayers(props.team)}
				</AccordionItem>
			</Accordion>
		</>
	);
};
