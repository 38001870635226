// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import 'react-swipeable-list/dist/styles.css';
import {
	ExportLink,
	ExtendedPlayerDTO,
	FindPlayersFilterBy,
	FindPlayersSortBy,
	PlayerDetail,
} from '../../../api/player/player.model';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import {
	exportAll,
	findAll,
	findDetail,
	setDislike,
	setLike,
} from '../../../api/player/player.api';
import placeholderSelectPlayer from '../../../assets/img/placeholder-select-player.svg';
import Ads from '../../../components/Ads';
import AddPlayerToTeam from '../../../components/addPlayerToTeam';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import AuthContext from '../../../contexts/authContext';
import { InfluencerContext } from '../../../contexts/influencerContext';
import { getOpen, setOpen } from '../../../helpers/navigation';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { OrderAndFilterOffCanvas } from '../auction2/components/orderAndFilterOffCanvas';
import { PlayersListAuction } from '../auction2/components/playersListAuction';
import PlayerDetailsDesktop from './components/playerDetailsDesktop';
import PlayerDetailsMobile from './components/playerDetailsMobile';

let timeout: NodeJS.Timeout;
const PlayersList = () => {
	const [isMobile, setIsMobile] = useState(false);
	const { action } = useParams();
	const navigate = useNavigate();
	const { userSubscriptionInfo, tokenReceived } = useContext(AuthContext);
	const { selectedInfluencer } = useContext(InfluencerContext) || {};

	const [selectedSortBy, setSelectedSortBy] = useState<FindPlayersSortBy[]>([
		'role,asc',
		'surname,asc',
	]);
	const [selectedFilterBy, setSelectedFilterBy] = useState<FindPlayersFilterBy>({});
	const [showAddPlayerToTeam, setShowAddPlayerToTeam] = useState<boolean>(false);
	const [selectedPlayerId, setSelectedPlayerId] = useState<string | undefined>(undefined);
	const [selectedPlayerName, setSelectedPlayerName] = useState<string>('');
	const [playerDetail, setPlayerDetail] = useState<PlayerDetail | null>(null);
	const [players, setPlayers] = useState<ExtendedPlayerDTO[]>([]);
	const [exportPlayers, setExportPlayers] = useState<ExportLink | null>(null);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (tokenReceived) {
			const open = getOpen(action, 'dettagli');
			if (open && open.length > 0) {
				setSelectedPlayerId(open[0]);
			}
		}
	}, [tokenReceived]);

	useEffect(() => {
		if (selectedPlayerId) {
			setOpen(navigate, '/lista-calciatori', action, 'dettagli', [selectedPlayerId], true);
			findDetail(selectedPlayerId).then((res) => {
				setPlayerDetail(res);
			});
		}
	}, [selectedPlayerId]);

	const SIZE = 50;
	const onSearchPlayers = (property: FindPlayersSortBy[], filterBy: FindPlayersFilterBy) => {
		setSelectedSortBy(property);
		const filters = { page: 0, size: SIZE, sort: property, ...filterBy };
		if (selectedInfluencer) {
			filters.influencerId = selectedInfluencer.id;
		}
		return findAll(filters).then((res) => {
			if (filters.page > 0) {
				setPlayers(players.concat(res.content));
			} else {
				setPlayers(res.content);
			}
		});
	};

	useEffect(() => {
		if (selectedInfluencer) {
			onSearchPlayers(selectedSortBy, selectedFilterBy);
		}
	}, [selectedInfluencer]);

	function downloadBlob(blob: Blob) {
		const url = window.URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.href = url;
		a.download = 'Fantalgoritmo_Listone_' + new Date().toLocaleDateString() + '.xlsx';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	function openLinkExport() {
		if (exportPlayers) {
			window.open(exportPlayers.url, '_blank');
		} else {
			exportAll().then((response) => {
				setExportPlayers(response);
				window.open(response.url, '_blank');
			});
		}
	}

	const renderSearchInput = () => {
		return (
			<Input
				id='searchInput'
				type='search'
				className='form-control border-0 shadow-none bg-transparent'
				placeholder='Cerca Giocatore'
				value={selectedFilterBy.search}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const newSelectedFilterBy = {
						...selectedFilterBy,
						search: e.target.value,
					};
					setSelectedFilterBy(newSelectedFilterBy);
					if (timeout) {
						clearTimeout(timeout);
					}
					timeout = setTimeout(() => {
						onSearchPlayers(selectedSortBy, newSelectedFilterBy);
					}, 500);
				}}
			/>
		);
	};

	const renderPlayerPreferenceButtons = (playerId: string) => {
		return (
			<>
				<Button
					className='flex-grow-1'
					isOutline={!playerDetail?.goal}
					color={'success'}
					onClick={() => {
						if (playerDetail) {
							setPlayerDetail({
								...playerDetail,
								goal: !playerDetail.goal,
								failure: playerDetail.failure ? false : playerDetail.failure,
							});
							setLike(playerDetail.id);
						}
					}}>
					<Icon icon={'ThumbUp'}></Icon> Preferito
				</Button>
				<Button
					className='flex-grow-1'
					isOutline={!playerDetail?.failure}
					color={'danger'}
					onClick={() => {
						if (playerDetail) {
							setPlayerDetail({
								...playerDetail,
								goal: playerDetail.goal ? false : playerDetail.goal,
								failure: !playerDetail.failure,
							});
							setDislike(playerDetail.id);
						}
					}}>
					<Icon icon={'ThumbDown'}></Icon> Da Evitare
				</Button>
			</>
		);
	};

	const isFreemiumOrBase =
		userSubscriptionInfo?.role === 'FREEMIUM' || userSubscriptionInfo?.role === 'BASE';

	return (
		<PageWrapper title='Lista Calciatori' isProtected={true} showAds={false}>
			{/* TODO: turn ads back on */}
			<div className='subheader row'>
				<div className='subheader-left col-sm'></div>
				<div className='subheader-right col-sm-auto'>
					{isFreemiumOrBase ? (
						<Button color="info"
								icon={'Lock'}
								isLight={true}
								onClick={() => {
									navigate('/upgrade');
								}}>
							Scarica Excel
						</Button>
					) : (
						<Button
							color='info'
							onClick={openLinkExport}
							// onClick={() => {
							// 	if (tokenReceived) {
							// 		exportAll().then((res) => {
							// 			downloadBlob(res);
							// 		});
							// 	}
							// }}
						>
							Scarica Excel
						</Button>
					)}
				</div>
			</div>
			<Page container='fluid' className='pt-0'>
				<div className='row'>
					<div className='col-12'>
						<Ads />
					</div>
				</div>
				<div className='row h-100'>
					{!isMobile ? (
						<>
							<div className={`col-6`}>
								<Card stretch={'full'} className={'overflow-hidden'}>
									<CardHeader className='d-flex flex-row gap-2 align-items-center my-auto'>
										<div className='shadow bg-body rounded d-flex flex-row justify-content-center align-items-center flex-grow-1 my-auto'>
											<label
												className='border-0 bg-transparent cursor-pointer me-0'
												htmlFor='searchInput'>
												<svg
													viewBox='0 0 24 24'
													fill='currentColor'
													className='svg-icon--material svg-icon svg-icon-2x text-primary ps-2'
													data-name='Material--Search'>
													<path d='M0 0h24v24H0V0z' fill='none' />
													<path d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path>
												</svg>
											</label>
											{renderSearchInput()}
										</div>
										<OrderAndFilterOffCanvas
											isMobile={isMobile}
											parentUri={'/lista-calciatori'}
											action={action}
											selectedFilterBy={selectedFilterBy}
											setSelectedFilterBy={setSelectedFilterBy}
											selectedSortBy={selectedSortBy}
											onSearchPlayers={onSearchPlayers}
											setSelectedSortBy={setSelectedSortBy}
										/>
									</CardHeader>
									<PlayersListAuction
										selectedFilterBy={selectedFilterBy}
										selectedSortBy={selectedSortBy}
										isMobile={isMobile}
										setSelectedPlayerId={setSelectedPlayerId}
										setSelectedPlayerName={setSelectedPlayerName}
										setShowAddPlayerToTeam={setShowAddPlayerToTeam}
										players={players}
										onSearchPlayers={onSearchPlayers}
									/>
								</Card>
							</div>
							<div className={`col-6`}>
								<Card stretch={'full'} className={'overflow-hidden'}>
									{selectedPlayerId && (
										<CardHeader className='d-flex flex-row justify-center items-center gap-2'>
											{renderPlayerPreferenceButtons(selectedPlayerId)}
										</CardHeader>
									)}
									<CardBody
										isScrollable={true}
										className={
											!selectedPlayerId
												? 'd-flex justify-content-center align-items-center'
												: ''
										}>
										{!selectedPlayerId && (
											<div className='d-flex flex-column justify-content-center align-items-center'>
												<img
													src={placeholderSelectPlayer}
													alt='Placeholder'
													style={{ width: '50%' }}
												/>
												<div>
													Seleziona un giocatore per vedere le sue
													informazioni
												</div>
											</div>
										)}
										{selectedPlayerId && (
											<div>
												<PlayerDetailsDesktop
													playerId={selectedPlayerId}
													selectedInfluencer={selectedInfluencer}
													userSubscriptionInfo={userSubscriptionInfo}
													playerDetail={playerDetail}
												/>
											</div>
										)}
									</CardBody>
								</Card>
							</div>
						</>
					) : (
						<>
							<div className='col-12'>
								<Card stretch={'full'}>
									<CardHeader className='py-0 d-flex align-items-center justify-content-between'>
										<div className='shadow bg-body rounded d-flex flex-row align-items-center my-auto w-100'>
											<label
												className='border-0 bg-transparent cursor-pointer me-0 ml-2'
												htmlFor='searchInput'>
												<svg
													viewBox='0 0 24 24'
													fill='currentColor'
													className='svg-icon--material svg-icon svg-icon-2x text-primary'
													data-name='Material--Search'>
													<path d='M0 0h24v24H0V0z' fill='none' />
													<path d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path>
												</svg>
											</label>
											{renderSearchInput()}
										</div>
										<OrderAndFilterOffCanvas
											isMobile={isMobile}
											parentUri={'/lista-calciatori'}
											action={action}
											selectedFilterBy={selectedFilterBy}
											setSelectedFilterBy={setSelectedFilterBy}
											selectedSortBy={selectedSortBy}
											onSearchPlayers={onSearchPlayers}
											setSelectedSortBy={setSelectedSortBy}
										/>
									</CardHeader>
									<PlayersListAuction
										selectedFilterBy={selectedFilterBy}
										selectedSortBy={selectedSortBy}
										isMobile={isMobile}
										setSelectedPlayerId={setSelectedPlayerId}
										setSelectedPlayerName={setSelectedPlayerName}
										setShowAddPlayerToTeam={setShowAddPlayerToTeam}
										players={players}
										onSearchPlayers={onSearchPlayers}
									/>
								</Card>

								<PlayerDetailsMobile
									refreshList={() => {
										onSearchPlayers(selectedSortBy, selectedFilterBy);
										//refreshTeamDetail();
									}}
									playerId={selectedPlayerId}
									setPlayerId={setSelectedPlayerId}
									renderPlayerPreferenceButtons={renderPlayerPreferenceButtons}
									userSubscriptionInfo={userSubscriptionInfo}
									selectedInfluencer={selectedInfluencer}
									playerDetail={playerDetail}
									parentUri='/lista-calciatori'
								/>
							</div>
						</>
					)}
				</div>
			</Page>
			{/*@ts-ignore*/}

			<AddPlayerToTeam
				refreshList={() => {
					onSearchPlayers(selectedSortBy, selectedFilterBy);
					//refreshTeamDetail();
				}}
				isVisible={showAddPlayerToTeam}
				playerId={selectedPlayerId}
				playerName={selectedPlayerName}
				action={action}
				setIsVisible={setShowAddPlayerToTeam}
				parentUri='/lista-calciatori'
			/>
		</PageWrapper>
	);
};

export default PlayersList;
