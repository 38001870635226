import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import asides from '../../routes/asideRoutes';
import Auction from '../../pages/presentation/auction/auction';

const AsideRoutes = () => {
	const auctionProps: RouteProps = {
		path: '/asta/:astaId',
		element: <Auction></Auction>,
	};
	return (
		<Routes>
			{asides.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
		</Routes>
	);
};

export default AsideRoutes;
