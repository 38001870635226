import { useContext, useEffect, useState } from 'react';
import Wizard, { WizardItem } from '../../../../components/Wizard';
import { selectTeamStep, writeAuctionCode } from './joinWizardSteps';
import SelectTeam from './SelectTeam';
import { AuctionContext } from '../../../../contexts/auctionContext';
import AuthContext from '../../../../contexts/authContext';
import WebSocketClient, {
	MessageTypes,
	WebSocketResponse,
} from '../../../../websockets/websockets.config';
import { useNavigate, useParams } from 'react-router-dom';
import { translateCode } from '../../../../api/auction/auction.api';

export const JoinAuctionWizard = () => {
	const { auctionId, invalidCode, setAuctionId, setInvalidCode } = useContext(AuctionContext)!;
	const { action } = useParams();
	const navigate = useNavigate();
	const { authToken } = useContext(AuthContext);
	const { teamsAuction, setTeamsAuction, selectedTeam, setAuctionHistory } =
		useContext(AuctionContext)!;
	const [codeAttempt, setCodeAttempt] = useState<string>('');

	useEffect(() => {
		setAuctionId('');
	}, []);

	useEffect(() => {
		if (
			action !== undefined &&
			action !== 'avvia' &&
			action !== 'unisciti' &&
			action !== 'condividi'
		) {
			setCodeAttempt(action.trim());
			setTimeout(() => {
				translateCode(action.trim())
					.then((res) => {
						setAuctionId(res.id);
					})
					.catch((err) => {
						setInvalidCode(true);
						setCodeAttempt(action.trim());
					});
			}, 1000);
		}
	}, [action]);

	function openAuction() {
		if (auctionId && authToken && selectedTeam) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.subscribe('private-teams', (data: WebSocketResponse) => {
					if (data.messageType === MessageTypes.TEAM_SELECTION_CONFIRMED) {
						setAuctionHistory([]);
						navigate('/attiva');
					}
				});

				client.publish(`/app/auctions/${auctionId}/teams/${selectedTeam.teamId}`, '');
			});
		}
	}

	return (
		<Wizard
			isHeader={true}
			color={'primary'}
			stretch={'semi'}
			className='d-flex flex-column'
			onSubmit={() => {
				openAuction();
			}}
			style={{ height: '330px' }}
			prevlabel='Indietro'
			nextlabel={['Prossimo Step']}
			isNextDisabled={auctionId === '' || invalidCode}
			icon0='QrCode'
			icon1='TouchApp'
			submitlabel='Partecipa'
			hideprevbutton={'true'}
			enableselectstep={'false'}>
			{writeAuctionCode(auctionId || '', setAuctionId, codeAttempt, setCodeAttempt)}

			<WizardItem id='lastStep'>
				<SelectTeam teamsAuction={teamsAuction} setTeamsAuction={setTeamsAuction} />
			</WizardItem>
		</Wizard>
	);
};
