import { Route, RouteProps, Routes } from 'react-router-dom';
import DefaultAside from '../../pages/_layout/_asides/DefaultAside';
import Auction from '../../pages/presentation/auction/auction';
import AuctionActive from '../../pages/presentation/auction2/auctionActive';
import DashboardPage from '../../pages/presentation/dashboard/DashboardPage';
import InfluencersList from '../../pages/presentation/influencersList/InfluencersList';
import LeagueSetup from '../../pages/presentation/leagueSetup/leagueSetup';
import PlayersList from '../../pages/presentation/playersList/playersList';
import AuctionSummary from '../../pages/presentation/teamsSummary/teamsSummary';
import contents from '../../routes/contentRoutes';

// const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	const auctionProps: RouteProps = {
		path: '/asta/:action?/:parameter?',
		element: <Auction />,
	};

	const playerDetailsProps: RouteProps = {
		path: '/lista-calciatori/:action?/:id?',
		element: <PlayersList />,
	};

	const influencersListProps: RouteProps = {
		path: '/lista-creator',
		element: <InfluencersList />,
	};

	const ImportFromLegheProps: RouteProps = {
		path: '/setup-lega/:action?',
		element: <LeagueSetup />,
	};

	const EditLegheProps: RouteProps = {
		path: '/setup-lega/modifica/:action?',
		element: <LeagueSetup />,
	};

	const auctionActiveProps: RouteProps = {
		path: '/attiva/:action?/:parameter?',
		element: <AuctionActive />,
	};

	

	const auctionSummaryProps: RouteProps = {
		path: '/riepilogo/:action?',
		element: <AuctionSummary />,
	};

	return (
		<Routes>
			{contents.map((page) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))}
			{/* <Route path='*' element={<PAGE_404 />} /> */}

			<Route {...auctionProps} />
			<Route {...playerDetailsProps} />
			<Route {...influencersListProps} />
			<Route {...ImportFromLegheProps} />
			<Route {...EditLegheProps} />
			<Route {...auctionActiveProps} />
			<Route {...auctionSummaryProps} />
			<Route path='/ref/:code' element={<DashboardPage />} />
			<Route path='/ref/:code' element={<DefaultAside />} />
		</Routes>
	);
};

export default ContentRoutes;
