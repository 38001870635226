import { createSlice } from '@reduxjs/toolkit';
import { playerAction } from './player.action';
import { ExportLink, ExtendedPlayerDTO, FindAllQueryParams, PlayerDoubt } from '../../api/player/player.model';
import {planAction} from "../plan/plan.action";

interface PlayerState {
	list: ExtendedPlayerDTO[];
	pageNumber: number;
	totalPages: number;
	loading: boolean;
	error: boolean;
	queryParams?: FindAllQueryParams;
	addPlayerMessageError: string;
	removePlayerMessageError: string;
	// exportAll: Blob;
	exportAll: ExportLink;
	loadingExport: boolean;
	errorExport: boolean;
	comparisonResult: PlayerDoubt[];
	comparisonLoading: boolean;
	comparisonError: string | null;
}

// Define the initial state using that type
const initialState: PlayerState = {
	list: [],
	pageNumber: 0,
	totalPages: 1,
	loading: false,
	error: false,
	addPlayerMessageError: '',
	removePlayerMessageError: '',
	// exportAll: new Blob,
	exportAll: { url: '#' },
	loadingExport: false,
	errorExport: false,
	comparisonResult: [],
	comparisonLoading: false,
	comparisonError: null,
};

export const playerSlice = createSlice({
	name: 'player',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(playerAction.findAll.pending, (state, action) => {
			state.loading = true;
			state.queryParams = action.meta.arg.queryParams;
			state.pageNumber = action.meta.arg.queryParams.page;
			state.error = false;
		});
		builder.addCase(playerAction.findAll.fulfilled, (state, action) => {
			state.list =
				action.payload.players.pageable.pageNumber > 0
					? [...state.list, ...action.payload.players.content]
					: action.payload.players.content;
			state.totalPages = action.payload.players.totalPages;
			state.pageNumber = action.payload.players.pageable.pageNumber;
			state.loading = false;
			state.error = false;
		});
		builder.addCase(playerAction.findAll.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
		});

		builder
			.addCase(playerAction.comparePlayers.pending, (state) => {
				state.comparisonLoading = true;
				state.comparisonError = null;
			})
			.addCase(playerAction.comparePlayers.fulfilled, (state, action) => {
				state.comparisonLoading = false;
				state.comparisonResult = action.payload;
				state.comparisonError = null;
			})
			.addCase(playerAction.comparePlayers.rejected, (state, action) => {
				state.comparisonLoading = false;
				state.comparisonError = action.error.message ?? null;
			});
			
		builder.addCase(playerAction.addPlayer.pending, (state, action) => {
			state.addPlayerMessageError = '';
		});
		builder.addCase(playerAction.addPlayer.fulfilled, (state, action) => {
			state.addPlayerMessageError = '';
		});
		builder.addCase(playerAction.addPlayer.rejected, (state, action) => {
			state.addPlayerMessageError = action.payload as string;
		});

		builder.addCase(playerAction.removePlayer.pending, (state, action) => {
			state.removePlayerMessageError = '';
		});
		builder.addCase(playerAction.removePlayer.fulfilled, (state, action) => {
			state.removePlayerMessageError = '';
		});
		builder.addCase(playerAction.removePlayer.rejected, (state, action) => {
			state.removePlayerMessageError = action.payload as string;
		});

		builder.addCase(playerAction.exportAll.pending, (state, action) => {
			state.loadingExport = true;
			state.errorExport = false;
		});
		builder.addCase(playerAction.exportAll.fulfilled, (state, action) => {
			state.exportAll = action.payload;
			state.loadingExport = false;
			state.errorExport = false;
		});
		builder.addCase(playerAction.exportAll.rejected, (state, action) => {
			state.errorExport = true;
			state.loadingExport = false;
		});
	},
});
