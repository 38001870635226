import React, { SVGProps } from 'react';

const SvgWbTwilight = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M16.955 8.662l2.12-2.122 1.416 1.414-2.121 2.122zM2 18h20v2H2zM11 4h2v3h-2zM3.543 7.925L4.957 6.51l2.121 2.12-1.414 1.415zM5 16h14c0-3.87-3.13-7-7-7s-7 3.13-7 7z' />
		</svg>
	);
};

export default SvgWbTwilight;
