import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectedLeagueSelector } from '../../store/selectedLeague/selectedLeague.selector';
import { playerAction } from '../../store/player/player.action';
import { playerSelector } from '../../store/player/player.selector';

export const usePlayer = (): {
	removePlayer: (playerId: string) => Promise<any>;
	addPlayer: (teamId: string, playerId: string, price: number) => Promise<any>;
	addPlayerErrorMessage: string;
	removePlayerErrorMessage: string;
} => {
	const dispatch = useAppDispatch();

	// For remove action
	const removePlayerErrorMessage = useAppSelector(playerSelector.getRemovePlayerMessageError);

	// For add action
	const addPlayerErrorMessage = useAppSelector(playerSelector.getAddPlayerMessageError);

	const selectedLeague = useAppSelector(selectedLeagueSelector.getSelectedLeague);

	const removePlayer = async (playerId: string): Promise<any> => {
		if (selectedLeague && playerId) {
			return dispatch(
				playerAction.removePlayer({
					playerId,
				}),
			);
		}
	};

	const addPlayer = async (teamId: string, playerId: string, price: number): Promise<any> => {
		if (selectedLeague && teamId && playerId) {
			return dispatch(
				playerAction.addPlayer({
					teamId,
					playerId,
					price,
				}),
			);
		}
	};

	return { removePlayer, addPlayer, addPlayerErrorMessage, removePlayerErrorMessage };
};
