import React, { lazy, useEffect } from 'react';
import { mainMenu, upgradeMenu, pageLayoutTypesPagesMenu, loginMenu, paymentMenu, footerMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import InfluencersList from '../pages/presentation/influencersList/InfluencersList';
import { Navigate } from 'react-router-dom';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	// PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGES = {
	LEAGUE_SETUP: lazy(() => import('../pages/presentation/leagueSetup/leagueSetup')),
	PREMIUM: lazy(() => import('../pages/presentation/premium/premium')),
	INFLUENCERS: lazy(() => import('../pages/presentation/influencersList/InfluencersList')),
	PLAYERS_LIST: lazy(() => import('../pages/presentation/playersList/playersList')),
	AUCTION: lazy(() => import('../pages/presentation/auction/auction')),
	AUCTION2: lazy(() => import('../pages/presentation/auction2/auctionActive')),
	// TEAMS_SUMMARY: lazy(() => import('../pages/presentation/teamsSummary/teamsSummary')),
	CAMPIONATO: lazy(() => import('../pages/presentation/campionato/campionato')),
	// DUBBIONE: lazy(() => import('../pages/presentation/dubbione/dubbione')),
	PROFILE: lazy(() => import('../pages/presentation/profile/profile')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

// const RedirectToExternalInNewTab = () => {
// 	useEffect(() => {
// 		window.open('https://fantalgoritmo.it/campionato', '_blank');
// 	}, []);
//
// 	return null;
// };

const presentation = [
	/**
	 * Landing
	 */
	{
		path: mainMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	// {
	// 	path: demoPagesMenu.page404.path,
	// 	element: <AUTH.PAGE_404 />,
	// },
	{
		path: loginMenu.login.path,
		element: <Login />,
	},
	// {
	// 	path: mainMenu.influencersList.path,
	// 	element: <InfluencersList />,
	// },
	{
		path: mainMenu.leagueSetup.path,
		element: <PAGES.LEAGUE_SETUP />,
	},
	{
		path: mainMenu.listaCalciatori.path,
		element: <PAGES.PLAYERS_LIST />,
	},
	// {
	// 	path: mainMenu.asta.path,
	// 	element: <PAGES.AUCTION />,
	// },
	// {
	// 	path: mainMenu.asta2.path,
	// 	element: <PAGES.AUCTION2 />,
	// },
	// {
	// 	path: mainMenu.riepilogoSquadre.path,
	// 	element: <PAGES.TEAMS_SUMMARY />,
	// },
	{
		path: mainMenu.campionato.path,
		element: <PAGES.CAMPIONATO />,
	},
	// {
	// 	path: mainMenu.dubbione.path,
	// 	element: <RedirectToExternalInNewTab />,
	// },
	{
		path: footerMenu.profile.path,
		element: <PAGES.PROFILE />,
	},
	{
		path: footerMenu.update.path,
		element: <PAGES.PREMIUM />,
	},

	// {
	// 	path: dashboardPagesMenu.premium.path,
	// 	element: <PAGES.PREMIUM />,
	// },
	// {
	// 	path: demoPagesMenu.signUp.path,
	// 	element: <Login isSignUp />,
	// },

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	// {
	// 	path: pageLayoutTypesPagesMenu.blank.path,
	// 	element: <PAGE_LAYOUTS.BLANK />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PAGE_LAYOUTS.HEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
	// 	element: <PAGE_LAYOUTS.SUBHEADER />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
	// 	element: <PAGE_LAYOUTS.CONTENT />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
	// 	element: <PAGE_LAYOUTS.ASIDE />,
	// },
	// {
	// 	path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
	// 	element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	// },
];
const contents = [...presentation];

export default contents;
