import { apiBuilder } from '../api.config';
import { Event } from './advertisement.model';
import {ImportedLeague} from "../league/league.model";

const getOdds = (): Promise<Event[]> => {
	const URL = `advertisement/starcasino`;
	return apiBuilder.get<Event[]>(URL);
};

export const advertisementAPI = {
	getOdds,
};
