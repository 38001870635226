import { createAsyncThunk } from '@reduxjs/toolkit';
import { advertisementAPI } from "../../api/advertisement/advertisement.api";

enum AdvertisementAction {
	ODDS = 'advertisement/getOdds',
}

const getOdds = createAsyncThunk(
	AdvertisementAction.ODDS,
	async (params: { }, thunkAPI) => {
		try {
			return await advertisementAPI.getOdds();
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response.data.message);
		}
	},
);

export const advertisementAction = {
	getOdds,
};
