import { useState } from 'react';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import SubHeader from '../../../../layout/SubHeader/SubHeader';
import { JoinAuctionModal } from './joinAuctionModal';
import { StartAuctionModal } from './startAuctionModal';

interface SubHeaderAuctionProps {
	action: string | undefined;
	auctionCode: string;
	setAuctionCode: (auctionCode: string) => void;
}

export const SubHeaderAuction = (props: SubHeaderAuctionProps) => {
	return (
		<div className='subheader row'>
			{/* <SubHeader className='desktop-subheader p-0'> */}
			<ButtonGroup
				isToolbar={false}
				isVertical={false}
				size={'sm'}
				ariaLabel={'subheader-auction'}
				className='rounded-1 d-flex flex-grow-1 h-100 w-100 p-0'>
				<JoinAuctionModal
					action={props.action}
					auctionCode={props.auctionCode}
					setAuctionCode={props.setAuctionCode}
				/>
				<StartAuctionModal action={props.action} />
			</ButtonGroup>
			{/* </SubHeader> */}
		</div>
	);
};
