import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import AuthContext from '../../../contexts/authContext';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader from '../../../layout/SubHeader/SubHeader';
import { TeamView } from '../auction2/components/teamView';
import { LeagueContext } from '../../../contexts/leagueContext';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Ads from '../../../components/Ads';

const AuctionSummary = () => {
	const { userSubscriptionInfo } = useContext(AuthContext);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const { action } = useParams();
	const [rowView, setRowView] = useState<boolean>(true);
	const { selectedLeague } = useContext(LeagueContext)!;

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize(); // Check initially
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const renderRowView = () => (
		<Button
			isOutline={!rowView}
			color={'dark'}
			className='d-flex align-items-center fs-6 py-1 pr-3 gap-2'
			onClick={() => {
				setRowView(!rowView);
			}}>
			<Icon icon={`${rowView ? 'TableRows' : 'ViewColumn'}`}></Icon>
			{!isMobile && <p className='my-auto'>{rowView ? 'Riga' : 'Griglia'}</p>}
		</Button>
	);

	if (!selectedLeague) {
		return (
			<PageWrapper
				title='Riepilogo Squadre'
				isProtected={true}
				showAds={!userSubscriptionInfo?.paidSubscription}>
				<Page
					container='fluid'
					className='d-flex justify-content-between align-items-center'>
					<h4>Devi prima creare o selezionare una lega</h4>
				</Page>
			</PageWrapper>
		);
	}

	return (
		<PageWrapper
			title='Riepilogo Squadre'
			isProtected={true}
			showAds={!userSubscriptionInfo?.paidSubscription}>
			{/* <SubHeader>
				<div className='d-flex justify-content-start align-items-center gap-2'>
					<p className='fw-bold fs-5 my-auto'>Vista</p>
					{renderRowView()}
				</div>
			</SubHeader> */}
			<Page container='fluid' className='pt-0 px-4'>
				<Ads />

				<div className='row h-100 d-flex justify-content-center align-items-start'>
					<TeamView isMobile={isMobile} rowView={rowView}></TeamView>

					<h3 className='text-center w-50 mt-2'>
						Tutte le informazioni sulle squadre saranno disponibili solo al termine
						dell'asta o importando i dati da Leghe Fantacalcio
					</h3>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AuctionSummary;
