import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../../components/bootstrap/Card';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import Icon from '../../../../components/icon/Icon';
import { setOpen, getOpen } from '../../../../helpers/navigation';
import { AuctionContext } from '../../../../contexts/auctionContext';

interface UsersOffCanvasProps {
	isMobile: boolean;
	parentUri: string;
	action: string | undefined;
}

export const UsersOffCanvas = (props: UsersOffCanvasProps) => {
	const navigate = useNavigate();
	const isOpen = Boolean(getOpen(props.action, 'utenti'));
	const { auctionStatus } = useContext(AuctionContext)!;

	// Verifica se auctionStatus è undefined
	if (!auctionStatus) {
		return (
			<span
				className=''
				onClick={() => {
					setOpen(navigate, props.parentUri, props.action, 'utenti', [], true);
				}}
				style={{ cursor: 'pointer' }}>
				<p className='my-auto'>
					<b>0</b>{' '}
					<span className={'d-none d-xl-inline'}>Utente online</span>
				</p>
			</span>
		);
	}

	return (
		<>
			<span
				className=''
				onClick={() => {
					setOpen(navigate, props.parentUri, props.action, 'utenti', [], true);
				}}
				style={{ cursor: 'pointer' }}>
				<p className='my-auto'>
					<b>{auctionStatus.numberUsers || 1}</b>{' '}
					<span className={'d-none d-xl-inline'}>
						{auctionStatus.numberUsers > 1 ? 'Utenti' : 'Utente'} online
					</span>
				</p>
			</span>

			<OffCanvas
				tag={'div'}
				isBackdrop={true}
				isBodyScroll={false}
				placement={'end'}
				isOpen={isOpen}
				setOpen={() =>
					setOpen(navigate, props.parentUri, props.action, 'utenti', [], false)
				}>
				<OffCanvasHeader className='d-flex justify-content-between align-items-center'>
					<OffCanvasTitle id={'title'}>Utenti e Squadre</OffCanvasTitle>
					<Button
						className='p-1 px-2 rounded-1'
						onClick={() => {
							setOpen(navigate, props.parentUri, props.action, 'utenti', [], false);
						}}
						style={{ color: 'gray' }}>
						<Icon icon={'Close'} size={'2x'}></Icon>
					</Button>
				</OffCanvasHeader>
				<OffCanvasBody>
					{Object.entries(auctionStatus.users).map(([key, user]) => (
						<Card key={key}
							  shadow={'3d'}>
							<CardHeader borderSize={3}
										borderColor={'info'}>
								<CardLabel>
									<CardTitle>{user.teamName}</CardTitle>
									<CardSubTitle>{user.name}</CardSubTitle>
								</CardLabel>
							</CardHeader>
						</Card>
					))}
				</OffCanvasBody>
			</OffCanvas>
		</>
	);
};
