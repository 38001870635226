import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';

import TeamDetails from './teamDetails';

interface PlayerDetailProps {
	riepiloghiActive: boolean;
	setRiepiloghiActive: (value: boolean) => void;
}

const RiepilogoMobile = (props: PlayerDetailProps) => {
	return (
		<>
			<Modal
				id={`player-detail`}
				setIsOpen={props.setRiepiloghiActive}
				titleId='playerDetails'
				isOpen={props.riepiloghiActive}
				isStaticBackdrop={true}
				isScrollable={true}>
				<ModalHeader className='d-flex flex-column '>
					<div className='d-flex justify-content-between align-items-center w-100'>
						<ModalTitle id={`riepilogo`}>Riepilogo</ModalTitle>
						<Button
							className='p-1 px-2 rounded-1'
							onClick={() => {
								props.setRiepiloghiActive(false);
							}}
							style={{ color: 'gray' }}>
							<Icon icon={'Close'} size={'2x'}></Icon>
						</Button>
					</div>
				</ModalHeader>
				<ModalBody>
					<TeamDetails></TeamDetails>
				</ModalBody>
			</Modal>
		</>
	);
};

export default RiepilogoMobile;
