import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { findAll, getLeagueData } from '../api/league/league.api';
import { League } from '../api/league/league.model';
import { getUserPreferences, setLastLeagueIdAPI } from '../api/user/user.api';
import { CustomEventName } from '../event.type';

interface LeagueContextType {
	leagues: League[];
	setLeagues: (leagues: League[]) => void;
	selectedLeague: any | undefined;
	setSelectedLeague: React.Dispatch<React.SetStateAction<any | undefined>>;
	newName: string;
	setNewName: (newName: string) => void;
	hasNameBeenUpdated: boolean;
	setHasNameBeenUpdated: (hasNameBeenUpdated: boolean) => void;
	newRules?: any;
	setNewRules: (newRules: any) => void;
	hasRulesBeenUpdated: boolean;
	setHasRulesBeenUpdated: (hasRulesBeenUpdated: boolean) => void;
	newTeams?: any[];
	setNewTeams: (newTeams: any[]) => void;
	hasTeamsBeenUpdated: boolean;
	setHasTeamsBeenUpdated: (hasTeamsBeenUpdated: boolean) => void;
	hasDeletedLeague: boolean;
	setHasDeletedLeague: (hasDeletedLeague: boolean) => void;
	setLastLeagueId: (lastLeagueId: string) => void;
}

export const LeagueContext = createContext<LeagueContextType | undefined>(undefined);

export const LeagueProvider = ({ children }: { children?: ReactNode }) => {
	const [tokenReceived, setTokenReceived] = useState<boolean>(false);

	const [leagues, setLeagues] = useState<League[]>([]);
	const [lastLeagueId, setLastLeagueId] = useState<string | undefined>(() => {
		// Retrieve the lastLeagueId from localStorage when the component mounts
		const savedLeagueId = localStorage.getItem('lastLeagueId');
		return savedLeagueId || undefined;
	});
	const [selectedLeague, setSelectedLeague] = useState<any | undefined>(leagues?.[0]);
	const [newName, setNewName] = useState<string>('');
	const [hasNameBeenUpdated, setHasNameBeenUpdated] = useState<boolean>(false);
	const [newRules, setNewRules] = useState<any | undefined>(undefined);
	const [hasRulesBeenUpdated, setHasRulesBeenUpdated] = useState<boolean>(false);
	const [newTeams, setNewTeams] = useState<any[]>();
	const [hasTeamsBeenUpdated, setHasTeamsBeenUpdated] = useState<boolean>(false);
	const [hasDeletedLeague, setHasDeletedLeague] = useState<boolean>(false);

	useEffect(() => {
		const handleTokenSuccess: () => void = () => {
			setTokenReceived(true);
		};

		window.addEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);

		return () => {
			window.removeEventListener(CustomEventName.ON_TOKEN_SUCCESS, handleTokenSuccess);
		};
	}, []);

	useEffect(() => {
		if (tokenReceived) {
			if (selectedLeague) {
				findAll()
					.then((res) => {
						setLeagues(res);
					})
					.catch((err) => {});
			}
		}
	}, [tokenReceived]);

	useEffect(() => {
		if (selectedLeague && selectedLeague.id !== undefined) {
			localStorage.setItem('lastLeagueId', selectedLeague.id);
			setLastLeagueIdAPI(selectedLeague.id);
		}
	}, [selectedLeague]);

	useEffect(() => {
		if (tokenReceived && hasNameBeenUpdated) {
			getUserPreferences().then((res) => {
				setLastLeagueId(res.lastLeagueId);
				localStorage.setItem('lastLeagueId', res.lastLeagueId);
				setNewName('');
				setHasNameBeenUpdated(false);
			});
		}
	}, [tokenReceived, hasNameBeenUpdated]);

	useEffect(() => {
		if (tokenReceived && hasTeamsBeenUpdated) {
			getUserPreferences().then((res) => {
				setLastLeagueId(res.lastLeagueId);
				localStorage.setItem('lastLeagueId', res.lastLeagueId);
				setHasTeamsBeenUpdated(false);
			});
		}
	}, [tokenReceived, hasTeamsBeenUpdated]);

	useEffect(() => {
		if (leagues.length === 0) {
			return;
		}
		if (lastLeagueId && leagues.length > 0) {
			leagues.find((league) => {
				if (league.id === lastLeagueId) {
					getLeagueData(lastLeagueId).then((res) => {
						setSelectedLeague(res);
						localStorage.setItem('lastLeagueId', lastLeagueId);
					});
				}
			});
		} else localStorage.removeItem('lastLeagueId');
	}, [lastLeagueId, leagues]);

	useEffect(() => {
		if (tokenReceived && hasRulesBeenUpdated) {
			const updatedLeagues = leagues.map((league) => {
				if (league.id === selectedLeague?.id) {
					return { ...league, rules: newRules };
				}
				return league; // Return the league unchanged if not the selected one
			});
			setLeagues(updatedLeagues);
			//setLastLeagueId(selectedLeague.id);
			setNewRules(undefined);

			getUserPreferences().then((res) => {
				setLastLeagueId(res.lastLeagueId);
				localStorage.setItem('lastLeagueId', res.lastLeagueId);
				setHasRulesBeenUpdated(false);
			});
		}
	}, [tokenReceived, hasRulesBeenUpdated]);

	useEffect(() => {
		if (hasDeletedLeague) {
			setSelectedLeague(undefined);
		}
	}, [hasDeletedLeague]);

	return (
		<LeagueContext.Provider
			value={{
				leagues,
				setLeagues,
				selectedLeague,
				setSelectedLeague,
				newName,
				setNewName,
				hasNameBeenUpdated,
				setHasNameBeenUpdated,
				newRules,
				setNewRules,
				hasRulesBeenUpdated,
				setHasRulesBeenUpdated,
				newTeams,
				setNewTeams,
				hasTeamsBeenUpdated,
				setHasTeamsBeenUpdated,
				hasDeletedLeague,
				setHasDeletedLeague,
				setLastLeagueId,
			}}>
			{children}
		</LeagueContext.Provider>
	);
};
