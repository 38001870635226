import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuctionCSV } from '../../../../api/auction/auction.api';
import Badge from '../../../../components/bootstrap/Badge';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader } from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import { ShareModal } from '../../../../components/ShareModal';
import { AuctionData } from '../../../../api/auction/auction.model';
import { AuctionContext } from '../../../../contexts/auctionContext';
import { setOpen } from '../../../../helpers/navigation';

interface PastAuctionsProps {
	isMobile: boolean;
	action: string | undefined;
	auctionHistoryValue: AuctionData;
}

export const PastAuctions = (props: PastAuctionsProps) => {
	const [auctionCSV, setAuctionCSV] = useState<any | null>(null);
	const navigate = useNavigate();
	const { setAuctionId, setAuctionHistory, auctionHistory } = useContext(AuctionContext)!;

	useEffect(() => {
		if (auctionCSV) {
			downloadCSV(auctionCSV); // TODO: verificare se il file è quello giusto
		}
	}, [auctionCSV]);

	const downloadCSV = (auctionCSV: any) => {
		const csvData = auctionCSV;
		const blob = new Blob([csvData], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `Fantalgoritmo-export-${props.auctionHistoryValue.id}-${new Date()
			.toISOString()
			.replaceAll('-', '_')
			.replaceAll('T', '_')
			.replaceAll(':', '_')
			.replaceAll(/\..*Z/g, '')}`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	function calculateTimeBetweenDates(date1: Date, date2?: Date) {
		const date1Time = new Date(date1).getTime();
		const date2Time = date2 ? new Date(date2).getTime() : new Date().getTime();

		const diffInMilliseconds = Math.abs(date2Time - date1Time);
		const totalMinutes = diffInMilliseconds / (1000 * 60);

		const hours = Math.floor(totalMinutes / 60);
		const minutes = Math.floor(totalMinutes % 60);

		const hoursString = hours.toString().padStart(2, '0');
		const minutesString = minutes.toString().padStart(2, '0');

		return `${hoursString}:${minutesString}`;
	}

	return (
		<>
			<Card>
				<CardHeader className='d-flex justify-content-between'>
					<div className='d-flex flex-row justify-content-start align-items-center gap-2 my-auto'>
						<Icon icon={'HowToVote'} size={'2x'}></Icon>
						<h5 className='mb-0 fw-bold'>{props.auctionHistoryValue.name}</h5>
					</div>

					<div
						className={`d-flex align-items-center ${
							!props.isMobile ? 'gap-3' : 'gap-2'
						} justify-content-between`}>
						<p className='my-auto text-end'>
							{!props.isMobile ? 'Asta ' : ''}
							{props.auctionHistoryValue.active === true && <b>In Corso</b>}
							{props.auctionHistoryValue.active === false &&
								!props.auctionHistoryValue.startDate && <b>Non Iniziata</b>}
							{props.auctionHistoryValue.active === false &&
								props.auctionHistoryValue.startDate &&
								props.auctionHistoryValue.endDate && <b>Terminata</b>}
						</p>
						<div className='subheader-separator pl-4'></div>
						{props.auctionHistoryValue.active ||
						(props.auctionHistoryValue.active === false &&
							!props.auctionHistoryValue.startDate) ? (
							<>
								<Button
									className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 bg-lo50-success text-white'
									onClick={() => {
										setAuctionId(props.auctionHistoryValue.id);
										setOpen(
											navigate,
											'/asta',
											props.action,
											'condividi',
											[],
											true,
										);
									}}>
									<Icon icon={'Share'}></Icon>
									<div>{!props.isMobile ? 'Condividi' : ''}</div>
								</Button>

								<Button
									className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 bg-lo50-warning text-white'
									onClick={() => {
										localStorage.setItem(
											'auctionId',
											props.auctionHistoryValue.id,
										);
										setAuctionId(props.auctionHistoryValue.id);
										navigate(`/attiva`);
									}}>
									<Icon icon={'DoubleArrow'}></Icon>
									{!props.isMobile && <p className='my-auto'>Unisciti</p>}
								</Button>
							</>
						) : (
							<Button
								className='d-flex align-items-center fs-6 py-1 pr-3 gap-2 rounded-1 bg-lo50-info text-white'
								onClick={() => {
									getAuctionCSV(props.auctionHistoryValue.id).then((ret) => {
										setAuctionCSV(ret.response);
									});
								}}>
								<Icon icon={'FileDownload'}></Icon>
								{!props.isMobile && <p className='my-auto'>Esporta Squadre</p>}
							</Button>
						)}
					</div>
				</CardHeader>
				<CardBody className='pt-0'>
					<div
						className={`d-flex ${
							!props.isMobile ? ' gap-4' : 'flex-column gap-3'
						} align-items-center justify-content-around `}>
						<div
							className={`d-flex ${
								!props.isMobile ? 'w-50 gap-4' : 'w-100 gap-3'
							} align-items-center justify-content-between`}>
							{props.auctionHistoryValue.completedTeams && (
								<Badge
									className={`bg-l10-warning d-flex w-50 justify-content-start align-items-center gap-2 py-3`}>
									<Icon icon={'Check'} color={'warning'} size={'3x'}></Icon>
									<div className='d-flex flex-column align-items-start justify-content-center'>
										<h5 className='mb-0 fw-bold text-black'>
											{props.auctionHistoryValue.completedTeams}
										</h5>
										<p className='mb-0 text-muted fw-normal'>
											{!props.isMobile
												? 'Squadre Completate'
												: 'Sq. Completate'}
										</p>
									</div>
								</Badge>
							)}
							{props.auctionHistoryValue.totalCredits && (
								<Badge
									className={`bg-l10-info d-flex w-50 justify-content-start align-items-center gap-2 py-3`}>
									<Icon icon={'Savings'} color={'info'} size={'3x'}></Icon>
									<div className='d-flex flex-column align-items-start justify-content-center'>
										<h5 className='mb-0 fw-bold text-black'>
											{props.auctionHistoryValue.totalCredits}
										</h5>
										<p className='mb-0 text-muted fw-normal'>Crediti Spesi</p>
									</div>
								</Badge>
							)}
						</div>
						<div
							className={`${
								!props.isMobile ? 'd-flex w-50 gap-4' : 'd-flex w-100 gap-3'
							}`}>
							{props.auctionHistoryValue.playersSold && (
								<Badge className='bg-l10-primary w-50 d-flex justify-content-start align-items-center gap-2 py-3'>
									<Icon icon={'Celebration'} color={'primary'} size={'3x'}></Icon>
									<div className='d-flex flex-column align-items-start justify-content-center'>
										<h5 className='mb-0 fw-bold text-black'>
											{props.auctionHistoryValue.playersSold}
										</h5>
										<p className='mb-0 text-muted fw-normal'>
											{!props.isMobile
												? 'Giocatori Acquistati'
												: 'Gioc. Acquistati'}
										</p>
									</div>
								</Badge>
							)}
							{props.auctionHistoryValue.startDate &&
								props.auctionHistoryValue.endDate && (
									<Badge className='bg-l10-success w-50 d-flex justify-content-start align-items-center gap-2 py-3'>
										<Icon icon={'Timer'} color={'success'} size={'3x'}></Icon>
										<div className='d-flex flex-column align-items-start justify-content-center'>
											{props.auctionHistoryValue.endDate && (
												<h5 className='mb-0 fw-bold text-black'>
													{calculateTimeBetweenDates(
														props.auctionHistoryValue.startDate,
														props.auctionHistoryValue.endDate,
													)}
												</h5>
											)}
											<p className='mb-0 text-muted fw-normal'>
												Ore e Minuti
											</p>
										</div>
									</Badge>
								)}
						</div>
					</div>
					{!props.auctionHistoryValue.active && (
						<>
							{props.auctionHistoryValue.bestTeams && (
								<>
									<h6 className='fw-bold my-3'>Le 3 Migliori Squadre</h6>
									<div
										className={`d-flex ${
											!props.isMobile ? ' gap-4' : 'flex-column gap-2'
										} align-items-center justify-content-between`}>
										{props.auctionHistoryValue.bestTeams.map((team, index) => (
											<Badge className='bg-l25-success flex-grow-1 d-flex flex-column py-4 px-4'>
												<h6 className='fw-bold text-black text-start'>
													Squadra {index + 1}
												</h6>
												<div className='d-flex justify-content-start align-items-center gap-2'>
													<Icon
														icon={'LooksOne'}
														color={'success'}
														size={'3x'}></Icon>

													{props.auctionHistoryValue.bestTeams && (
														<div className='d-flex flex-column align-items-start justify-content-center'>
															<h5 className='mb-1 fw-bold text-black'>
																~
																{
																	props.auctionHistoryValue
																		.bestTeams[index].value
																}
																/100
															</h5>
															<p className='mb-0 text-muted fw-normal'>
																Valore Medio Giocatore
															</p>
														</div>
													)}
												</div>
											</Badge>
										))}
									</div>
								</>
							)}
							{props.auctionHistoryValue.worstTeams && (
								<>
									<h6 className='fw-bold my-3'>Le 3 Peggiori Squadre</h6>
									<div
										className={`d-flex ${
											!props.isMobile ? ' gap-4' : 'flex-column gap-2'
										} align-items-center justify-content-between`}>
										{props.auctionHistoryValue.worstTeams.map((team, index) => (
											<Badge className='bg-l25-danger flex-grow-1 d-flex flex-column py-4 px-4'>
												<h6 className='fw-bold text-black text-start'>
													Squadra {index + 1}
												</h6>
												<div className='d-flex justify-content-start align-items-center gap-2'>
													<Icon
														icon={'LooksOne'}
														color={'danger'}
														size={'3x'}></Icon>

													{props.auctionHistoryValue.worstTeams && (
														<div className='d-flex flex-column align-items-start justify-content-center'>
															<h5 className='mb-1 fw-bold text-black'>
																~
																{
																	props.auctionHistoryValue
																		.worstTeams[index].value
																}
																/100
															</h5>
															<p className='mb-0 text-muted fw-normal'>
																Valore Medio Giocatore
															</p>
														</div>
													)}
												</div>
											</Badge>
										))}
									</div>
								</>
							)}
						</>
					)}
				</CardBody>
			</Card>
		</>
	);
};
