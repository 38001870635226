import { createSlice } from '@reduxjs/toolkit';
import { teamDetailAction } from './teamDetail.action';
import { TeamDetail } from '../../api/team/team.model';
import { playerAction } from '../player/player.action';

interface TeamDetailState {
	loading: boolean;
	error: boolean;
	detail?: TeamDetail;
}

// Define the initial state using that type
const initialState: TeamDetailState = {
	loading: false,
	error: false,
};

export const teamDetailSlice = createSlice({
	name: 'teamDetail',

	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(teamDetailAction.findDetail.pending, (state, action) => {
			state.loading = true;
			state.error = false;
		});
		builder.addCase(teamDetailAction.findDetail.fulfilled, (state, action) => {
			state.detail = action.payload;
			state.loading = false;
			state.error = false;
		});
		builder.addCase(teamDetailAction.findDetail.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
		});
	},
});
