import { configureStore } from '@reduxjs/toolkit';
import { leaguesSlice } from './leagues/leagues.slice';
import { selectedLeagueSlice } from './selectedLeague/selectedLeague.slice';
import { teamsSlice } from './teams/teams.slice';
import { teamDetailSlice } from './teamDetail/teamDetail.slice';
import { playerSlice } from './player/player.slice';
import { playerDetailSlice } from './playerDetail/playerDetail.slice';
import { importLeagueSlice } from './importLeague/importLeague.slice';
import { profileSlice } from './profile/profile.slice';
import { planSlice } from './plan/plan.slice';
import { championshipSlice } from './campionato/championship.slice';
import { advertisementSlice } from './advertisement/advertisement.slice';

export const store = configureStore({
	reducer: {
		leagues: leaguesSlice.reducer,
		selectedLeague: selectedLeagueSlice.reducer,
		teams: teamsSlice.reducer,
		teamDetail: teamDetailSlice.reducer,
		player: playerSlice.reducer,
		playerDetail: playerDetailSlice.reducer,
		importLeague: importLeagueSlice.reducer,
		profile: profileSlice.reducer,
		plan: planSlice.reducer,
		championship: championshipSlice.reducer,
		advertisement: advertisementSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [],
				ignoredPaths: ['player.exportAll'],
			},
		}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {...storeName: reducer...}
export type AppDispatch = typeof store.dispatch;
