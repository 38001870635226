import { apiBuilder } from '../api.config';
import { Influencer, InfluencerStatistics } from './influencer.model';

export const getInfluencerStatistics = (
	influencerId: string,
	playerId: string,
): Promise<InfluencerStatistics> => {
	const URL = `/influencers/${influencerId}/players/${playerId}`;
	return apiBuilder.get<InfluencerStatistics>(URL);
};

export const getInfluencers = (): Promise<Influencer[]> => {
	const URL = `/influencers`;
	return apiBuilder.get<Influencer[]>(URL);
};

export const setInfluencerData = (
	playerId: string,
	influencerId: string,
	data: InfluencerStatistics,
): Promise<InfluencerStatistics> => {
	const URL = `/influencers/${influencerId}/players/${playerId}`;
	return apiBuilder.post<InfluencerStatistics, any>(URL, data);
};
