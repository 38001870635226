import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Button from './bootstrap/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from './bootstrap/Modal';
import Option from './bootstrap/Option';
import Select from './bootstrap/forms/Select';
import { usePlayer } from '../hooks/businessLogic/usePlayer';
import { useTeams } from '../hooks/businessLogic/useTeams';
import { ToastContext } from '../contexts/toastContext';
import { useNavigate } from 'react-router';
import Icon from './icon/Icon';
import { LeagueContext } from '../contexts/leagueContext';
import { findAll } from '../api/team/team.api';
import { TeamBasic } from '../api/league/league.model';

interface AddPlayerToTeamProps {
	playerId?: string;
	playerName?: string;
	refreshList: () => void;
	action: string | undefined;
	parentUri: string;
	isVisible: boolean;
	setIsVisible: (isVisible: boolean) => void;
}

const AddPlayerToTeam = (props: AddPlayerToTeamProps) => {
	const { setToast } = useContext(ToastContext);
	const { selectedLeague } = useContext(LeagueContext)!;
	//const { teamsBySelectedLeague } = useTeams();
	const { addPlayer, addPlayerErrorMessage } = usePlayer();
	const navigate = useNavigate();

	const [selectedTeamId, setSelectedTeamId] = useState<string>();
	const [selectedTeamName, setSelectedTeamName] = useState<string>('');
	const [price, setPrice] = useState<number>(0);
	const [hasClickedOnSave, setHasClickedOnSave] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [teamsBySelectedLeague, setTeamsBySelectedLeague] = useState<TeamBasic[]>([]);

	useEffect(() => {
		if (selectedLeague) {
			findAll(selectedLeague.id).then((res) => {
				setTeamsBySelectedLeague(res);
			});
		}
	}, [selectedLeague]);

	const isResult = (action: string | undefined) => Boolean(action?.includes('aggiungi'));

	const isOpen = isResult(props.action);

	const setOpen = (open: boolean) => {
		const newAction =
			(props.action
				? props.action
						.split('-')
						.filter((a) => a !== 'aggiungi')
						.join('-')
				: '') + (open ? '-aggiungi' : '');
		navigate(`${props.parentUri}/${newAction}`);
	};

	useEffect(() => {
		if (props.isVisible) {
			setOpen(true);
		}
		props.setIsVisible(false);
	}, [props.isVisible]);

	return (
		<>
			<Modal isOpen={isOpen} setIsOpen={setOpen} isCentered={true} isAnimation={true}>
				<ModalHeader
					className={'d-flex justify-content-between align-items-center'}
					setIsOpen={setOpen}
					showClose={false}>
					<ModalTitle id={'Add player to team'}>
						Assegna giocatore ad una squadra
					</ModalTitle>
					<Button
						className='p-1 px-2 rounded-1'
						onClick={() => {
							setOpen(false);
						}}
						style={{ color: 'gray' }}>
						<Icon icon={'Close'} size={'2x'}></Icon>
					</Button>
				</ModalHeader>
				<ModalBody>
					<div className='container'>
						<form className='row'>
							<>
								<label id={'selectedTeam'} className={'form-label'}>
									Seleziona team
								</label>
								<Select
									className={'mb-3'}
									disabled={isLoading}
									id={'team'}
									value={selectedTeamId + '-' + selectedTeamName}
									onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
										const id = e.target.value.split('-')[0];
										const teamName = e.target.value.split('-')[1];
										if (id && teamName) {
											setSelectedTeamId(id);
											setSelectedTeamName(teamName);
										}
									}}
									ariaLabel={'selectedTeam'}
									placeholder={'Seleziona squadra'}>
									{teamsBySelectedLeague.length > 0 &&
										teamsBySelectedLeague.map((t) => {
											return (
												<Option key={t.id} value={t.id + '-' + t.name}>
													{t.name}
												</Option>
											);
										})}
								</Select>
								<label id={'price'} className={'form-label'}>
									Digita il prezzo
								</label>
								{/* <Input
								type="number"
								value={price < 1 ? ('') : (price)}
								required={true}
								isValid={price > 0}
								isTouched={hasClickedOnSave}
								disabled={isLoading}
								name={'price'}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setPrice(parseFloat(e.target.value))
								}
							/> */}
								<input
									type='number'
									inputMode={'numeric'}
									value={price < 1 ? '' : price}
									required
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										setPrice(parseFloat(e.target.value))
									}
									name='price'
									pattern='[0-9]*'
									disabled={isLoading}
									className='form-control'
									placeholder='Inserisci il prezzo'
								/>
							</>
						</form>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isDisable={isLoading}
						onClick={async () => {
							setHasClickedOnSave(true);
							if (selectedTeamId && props.playerId) {
								setIsLoading(true);
								await addPlayer(selectedTeamId, props.playerId, price).then(
									(value) => {
										if (value.type.includes('rejected')) {
											setToast({
												level: 'error',
												title: 'Giocatore non aggiunto',
												message: '',
											});
										} else if (value.type.includes('fulfilled')) {
											setToast({
												level: 'success',
												title: 'Giocatore aggiunto',
												message: `La squadra ${selectedTeamName} ha acquistato ${props.playerName} per ${price} fantamilioni`,
											});
											props.refreshList();
										}
										setIsLoading(false);
										setOpen(false);
									},
								);
							}
						}}>
						Aggiungi
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default AddPlayerToTeam;
