// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Progress from '../../../../components/bootstrap/Progress';
import Icon from '../../../../components/icon/Icon';
import Accordion, { AccordionItem } from '../../../../components/bootstrap/Accordion';
import ListGroup, { ListGroupItem } from '../../../../components/bootstrap/ListGroup';
import Badge from '../../../../components/bootstrap/Badge';
import { AuctionContext, AuctionHistory } from '../../../../contexts/auctionContext';
import AuthContext from '../../../../contexts/authContext';
import { TColor } from '../../../../type/color-type';

type Role = 'goalkeepers' | 'defenders' | 'midfielders' | 'forwards';

const TeamDetails = () => {
	const { userSubscriptionInfo } = useContext(AuthContext);
	const { teams, auctionStatus, auctionHistory } = useContext(AuctionContext)!;
	const [isMobile, setIsMobile] = useState<boolean>(false);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize(); // Check initially
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const personalTeamId = auctionStatus?.users[userSubscriptionInfo.userId]?.teamId;
	const [selectedRole, setSelectedRole] = useState<Role>('goalkeepers');
	const personalTeam = teams.find((team: any) => team.id === personalTeamId);

	const roleConfig: Record<Role, { color: TColor; title: string; players: any[] }> = {
		goalkeepers: {
			color: 'warning',
			title: 'Portieri',
			players: personalTeam?.goalkeepers || []
		},
		defenders: {
			color: 'success',
			title: 'Difensori',
			players: personalTeam?.defenders || []
		},
		midfielders: {
			color: 'info',
			title: 'Centroc.',
			players: personalTeam?.midfielders || []
		},
		forwards: {
			color: 'danger',
			title: 'Attaccanti',
			players: personalTeam?.forwards || []
		},
	};

	const { color, title, players } = roleConfig[selectedRole];

	const renderTeamItem = (team: string) => {
		return (
			<ListGroupItem
				tag={'li'}
				className='d-flex justify-content-start align-items-center gap-5'>
				<p className='fw-bold my-auto'>5</p>
				<div className='d-flex align-items-center justify-content-start gap-2'>
					<img
						src={require(`../../../../assets/img/teams-logos/${team.toLowerCase()}.png`)}
						alt='logo'
						width={20}
					/>
					<p className='my-auto'>
						{team
							.split(' ')
							.map(
								(word) =>
									word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
							)
							.join(' ')}
					</p>
				</div>
			</ListGroupItem>
		);
	};

	function spentCreditsForCategory(category: any) {
		let spent = 0;
		category?.forEach((gk: any) => {
			spent += gk.price;
		});
		return spent;
	}

	const renderTeamSummary = () => {
		return (
			<Card>
				<CardHeader className='pb-0 d-flex align-items-center'>
					<CardTitle className='d-flex align-items-center gap-2'>
						<Icon icon={'AccountBox'} color={'info'} size={'2x'}></Icon>

						<h5 className='fw-bold mb-0'>Riepilogo Squadra</h5>
					</CardTitle>
					<CardActions>
						{personalTeam?.remainingCredits && personalTeam?.initialCredits && (
							<Button
								isOutline={true}
								isDisable={true}
								shadow={'lg'}
								icon={'MonetizationOn'}>
								{personalTeam?.remainingCredits}/{personalTeam?.initialCredits}
							</Button>
						)}
					</CardActions>
				</CardHeader>
				<CardBody className="pt-2">
					<p className="text-muted">Distribuzione budget</p>
					<Progress>
						<Progress
							value={
								(spentCreditsForCategory(personalTeam?.goalkeepers) /
									personalTeam?.initialCredits) *
								100
							}
							color={'warning'}></Progress>
						<Progress
							value={
								(spentCreditsForCategory(personalTeam?.defenders) /
									personalTeam?.initialCredits) *
								100
							}
							color={'success'}></Progress>
						<Progress
							value={
								(spentCreditsForCategory(personalTeam?.midfielders) /
									personalTeam?.initialCredits) *
								100
							}
							color={'info'}></Progress>
						<Progress
							value={
								(spentCreditsForCategory(personalTeam?.forwards) /
									personalTeam?.initialCredits) *
								100
							}
							color={'danger'}></Progress>
					</Progress>
					<div className="row mt-3 mb-1">
						{Object.keys(roleConfig).map((role) => {
							const typedRole = role as Role;
							return (
								<div className="col-3" key={typedRole}>
									<Button
										className={`d-flex flex-column justify-content-center align-items-center rounded-1 text-${roleConfig[typedRole].color} fw-bold w-100 h-100`}
										shadow={'sm'}
										onClick={() => setSelectedRole(typedRole)}
									>
										<p className="mb-0">{roleConfig[typedRole].title}</p>
										<p className="fs-4 mb-0">
											{roleConfig[typedRole].players.length}/{typedRole === 'goalkeepers' ? 3 : 8}
										</p>
									</Button>
								</div>
							);
						})}
					</div>


					<Accordion id="giocatori" tag="div" color={color}>
						<AccordionItem id="giocatori" title={title} tag="div" overWriteColor={color}>
							<ListGroup tag="ol" isNumbered={false} isFlush={true}>
								{players.length > 0 ? (
									players.map((player, index) => (
										<ListGroupItem
											key={index}
											tag="li"
											className="d-flex justify-content-between align-items-center"
										>
											<div className="d-flex align-items-center justify-content-start gap-2">
												<Icon icon="People" />
												<p className="my-auto">{player.name}</p>
											</div>
											<Badge color="dark">{player.price}</Badge>
										</ListGroupItem>
									))
								) : (
									<div className="text-center w-100">Nessun giocatore disponibile</div>
								)}
							</ListGroup>
						</AccordionItem>
					</Accordion>
					<Accordion id={'teams'} tag={'div'} color={'dark'} className="mt-3">
						<AccordionItem
							id={'serieA'}
							title={'Numero per Squadre Serie A'}
							tag={'div'}
							overWriteColor={'dark'}>
							<ListGroup tag={'ol'} isNumbered={false} isFlush={true}>
								{renderTeamItem('AC Milan')}
								{renderTeamItem('Inter')}
								{renderTeamItem('Hellas Verona')}
							</ListGroup>
						</AccordionItem>
						<AccordionItem
							id={'fattoriChiave'}
							title={'Andamento per Fattori Chiave'}
							tag={'div'}
							overWriteColor={'dark'}>
							<div className="d-flex gap-2 justify-content-between">
								<Card
									className="rounded-1 text-center"
									shadow={'sm'}
									style={{ minWidth: '32%' }}>
									<CardBody>
										<p>Fascia</p>
										<Progress isAutoColor={true} value={10}></Progress>
									</CardBody>
								</Card>
								<Card
									className="rounded-1 text-center"
									shadow={'sm'}
									style={{ minWidth: '32%' }}>
									<CardBody>
										<p>Titolarità</p>
										<Progress isAutoColor={true} value={55}></Progress>
									</CardBody>
								</Card>
								<Card
									className="rounded-1 text-center"
									shadow={'sm'}
									style={{ minWidth: '32%' }}>
									<CardBody>
										<p>Affidabilità</p>
										<Progress isAutoColor={true} value={90}></Progress>
									</CardBody>
								</Card>
							</div>
						</AccordionItem>
					</Accordion>
				</CardBody>
			</Card>
		);
	};

	const oneTeamStatistics = (teamName: String) => {
		return (
			<div className="d-flex flex-row justify-content-between align-items-center gap-3 pt-2">
				<div className="d-flex flex-column justify-content-between align-items-center my-auto w-20">
					<p className="mb-1 fw-bold fs-6">{teamName}</p>
					<Button color={'dark'}>32/100</Button>
				</div>
				<div className="d-flex flex-column flex-grow-1">
					<p className="text-muted mb-1">Distribuzione Budget e Slot</p>
					<Progress>
						<Progress value={12} color={'warning'}></Progress>
						<Progress value={32} color={'success'}></Progress>
						<Progress value={32} color={'info'}></Progress>
						<Progress value={20} color={'danger'}></Progress>
					</Progress>
					<div className="d-flex justify-content-around gap-2">
						<Card
							className="d-flex justify-content-center align-items-center rounded-1 w-25 text-warning fw-bold mb-0"
							shadow={'none'}>
							<p className="mb-0">Portieri</p>
							<p className="fs-4 mb-0">3/3</p>
						</Card>
						<Card
							className="d-flex justify-content-center align-items-center text-success rounded-1 w-25 mb-0"
							shadow={'none'}>
							<p className="mb-0">Difensori</p>
							<p className="fs-4 mb-0 fw-bold">8/8</p>
						</Card>
						<Card
							className="d-flex justify-content-center align-items-center text-info rounded-1 w-25 mb-0"
							shadow={'none'}>
							<p className="mb-0">Centroc.</p>
							<p className="fs-4 mb-0 fw-bold">8/8</p>
						</Card>
						<Card
							className="d-flex justify-content-center align-items-center text-danger rounded-1 w-25 mb-0"
							shadow={'none'}>
							<p className="mb-0">Attaccanti</p>
							<p className="fs-4 mb-0 fw-bold">5/6</p>
						</Card>
					</div>
				</div>
			</div>
		);
	};

	const renderTeamStatistics = () => {
		return (
			<Card>
				<CardHeader className="pb-0 d-flex align-items-center">
					<CardTitle className="d-flex align-items-center gap-2">
						<Icon icon={'AccountBox'} color={'info'} size={'2x'}></Icon>

						<h5 className="fw-bold mb-0">Riepilogo Squadra</h5>
					</CardTitle>
				</CardHeader>
				<CardBody style={{ overflowX: 'scroll' }}>
					{oneTeamStatistics('Squadra 1')}
					{oneTeamStatistics('Squadra 2')}
					{oneTeamStatistics('Squadra 3')}
				</CardBody>
			</Card>
		);
	};

	function calculateTime(time: Date): string {
		const inputDate = new Date(parseInt(time.toString(), 10));
		const now = new Date();

		const diffInMs = now.getTime() - inputDate.getTime();

		const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

		if (diffInMinutes > 60) {
			const diffInHours = Math.floor(diffInMinutes / 60);
			return `${diffInHours === 1 ? `${diffInHours} ora` : `${diffInHours} ora`} `;
		}

		return `${diffInMinutes} min`;
	}

	const renderAttivita = () => {
		return (
			<Card>
				<CardHeader className='pb-0 d-flex align-items-center'>
					<CardTitle className='d-flex align-items-center gap-2'>
						<Icon icon={'NotificationsActive'} color={'warning'} size={'2x'}></Icon>

						<h5 className='fw-bold mb-0'>Ultime Attività</h5>
					</CardTitle>
				</CardHeader>
				<CardBody isScrollable={true} style={{ maxHeight: isMobile ? '' : '200px' }}>
					<div className='timeline'>
						{auctionHistory.map((history: AuctionHistory) => (
							<>
								<div className='timeline-item'>
									<div className='timeline-label text-truncate d-inline-block fw-bold'>
										<span className=''>
											{calculateTime(history.dateTime)} fa
										</span>
									</div>
									<div className='timeline-badge'>
										<svg
											viewBox='0 0 24 24'
											fill='currentColor'
											className='svg-icon--material svg-icon svg-icon-lg text-warning'
											data-name='Material--Circle'>
											<path fill='none' d='M0 0h24v24H0z'></path>
											<circle cx='12' cy='12' opacity='0.3' r='8'></circle>
											<path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'></path>
										</svg>
									</div>
									<div className='timeline-content ps-3'>
										{/* <b>Squadra 2 - Meret</b> a <b>12</b> */}
										{history.message}
									</div>
								</div>
							</>
						))}

						{/* TODO: questa qua sotto è la grafica di inizio asta, diversa da quella che viene attualmente utilizzata nella map sopra. */}
						{/*<div className='timeline-item'>
							<div className='timeline-label text-truncate d-inline-block fw-bold'>
								<span className=''>3 min fa</span>
							</div>
							<div className='timeline-badge'>
								<svg
									viewBox='0 0 24 24'
									fill='currentColor'
									className='svg-icon--material svg-icon svg-icon-lg text-primary'
									data-name='Material--Circle'>
									<path fill='none' d='M0 0h24v24H0z'></path>
									<circle cx='12' cy='12' opacity='0.3' r='8'></circle>
									<path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'></path>
								</svg>
							</div>
							<div className='timeline-content ps-3'>
								<b>Inizio Asta</b>
							</div>
						</div> */}
					</div>
				</CardBody>
			</Card>
		);
	};

	return (
		<>
			{renderTeamSummary()}
			{/* {renderTeamStatistics()} */}
			{renderAttivita()}
		</>
	);
};

export default TeamDetails;
