import { RootState } from '../store';
import { PlayerDetail } from '../../api/player/player.model';

const getPlayerDetail = (state: RootState): PlayerDetail | undefined => state.playerDetail.detail;

const isLoading = (state: RootState): boolean => state.playerDetail.loading;
const isError = (state: RootState): boolean => state.playerDetail.error;
const getSetNoteMessageError = (state: RootState): string => state.playerDetail.setNoteMessageError;

export const playerDetailSelector = {
	getPlayerDetail,
	isLoading,
	isError,
	getSetNoteMessageError,
};
