import { createAsyncThunk } from '@reduxjs/toolkit';
import { leagueAPI } from '../../api/league/league.api';
import { RootState } from '../store';
import { setSelectedLeague } from '../selectedLeague/selectedLeague.slice';
import { Rules, TeamBasic } from '../../api/league/league.model';
import { UpdateRuleErrorMessage } from './types';
import { updateLeaguesList } from './leagues.slice';

enum LeaguesAction {
	FIND_ALL = 'leagues/findAll',
	UPDATE_RULES = 'leagues/updateRules',
	UPDATE_TEAMS = 'leagues/updateTeams',
	UPDATE_LEAGUE_NAME = 'leagues/updateLeagueName',
	DELETE_LEAGUE = 'leagues/deleteLeague',
	CREATE_LEAGUE = 'leagues/createLeague',
}

const findAll = createAsyncThunk(
	LeaguesAction.FIND_ALL,
	async (_, thunkAPI) => {
		try {
			// when fetch leagues then set the first one as selected league
			const leagues = await leagueAPI.findAll();
			if (leagues.length > 0) {
				thunkAPI.dispatch(setSelectedLeague(leagues[0]));
			}
			return leagues;
		} catch (e) {
			throw e;
		}
	},
	{
		condition(arg, api): boolean {
			const { leagues } = api.getState() as RootState;
			return leagues.list.length === 0 || leagues.error;
		},
	},
);

const updateRules = createAsyncThunk(
	LeaguesAction.UPDATE_RULES,
	async (params: { leagueId: string; newRules: Rules }, thunkAPI) => {
		try {
			const newLeague = await leagueAPI.updateRules(params.leagueId, params.newRules);
			thunkAPI.dispatch(setSelectedLeague(newLeague));
			return newLeague;
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response.data.messages as UpdateRuleErrorMessage[]);
		}
	},
);

const updateTeams = createAsyncThunk(
	LeaguesAction.UPDATE_TEAMS,
	async (params: { leagueId: string; newTeams: TeamBasic[] }, thunkAPI) => {
		try {
			const newLeague = await leagueAPI.updateTeams(params.leagueId, params.newTeams);
			thunkAPI.dispatch(setSelectedLeague(newLeague));
			return newLeague;
		} catch (e) {
			throw e;
		}
	},
);

// const updateLeagueName = createAsyncThunk(
// 	LeaguesAction.UPDATE_LEAGUE_NAME,
// 	async (params: { leagueId: string; newName: string }, thunkAPI) => {
// 		try {
// 			const newLeague = await leagueAPI.updateLeagueName(params.leagueId, params.newName);
// 			thunkAPI.dispatch(setSelectedLeague(newLeague));
// 			return newLeague;
// 		} catch (e) {
// 			throw e;
// 		}
// 	},
// );

const deleteLeague = createAsyncThunk(
	LeaguesAction.DELETE_LEAGUE,
	async (params: { leagueId: string }, thunkAPI) => {
		try {
			const { leagues } = thunkAPI.getState() as RootState;
			await leagueAPI.deleteLeague(params.leagueId);
			const newSelectedLeague = leagues.list.find((league) => league.id !== params.leagueId);
			if (newSelectedLeague) {
				thunkAPI.dispatch(setSelectedLeague(newSelectedLeague));
			}
			return params.leagueId;
		} catch (e) {
			throw e;
		}
	},
);

const createLeague = createAsyncThunk(LeaguesAction.CREATE_LEAGUE, async (_, thunkAPI) => {
	try {
		
		const newLeague = await leagueAPI.createLeague('Nuova Lega');
		thunkAPI.dispatch(setSelectedLeague(newLeague));
		thunkAPI.dispatch(
			updateLeaguesList({
				newLeague,
			}),
		);
		return;
	} catch (e: any) {
		return thunkAPI.rejectWithValue(e.response.data.message as string);
	}
});

export const leaguesAction = {
	findAll,
	updateRules,
	updateTeams,
	//updateLeagueName,
	deleteLeague,
	createLeague,
};
