import { createAsyncThunk } from '@reduxjs/toolkit';
import { leagueAPI } from '../../api/league/league.api';
import { setSelectedLeague } from '../selectedLeague/selectedLeague.slice';
import { RootState } from '../store';
import { updateLeaguesList } from '../leagues/leagues.slice';

enum ImportLeagueAction {
	IMPORT = 'importLeague/importLeague',
	ADD_IMPORTED_LEAGUE = 'importLeague/addImportedLeague',
}

const importLeague = createAsyncThunk(
	ImportLeagueAction.IMPORT,
	async (params: { leagueId: string; leagueName: string }, thunkAPI) => {
		try {
			return await leagueAPI.importLeague(params.leagueId, params.leagueName);
		} catch (e: any) {
			return thunkAPI.rejectWithValue(e.response.data.message);
		}
	},
);

const addImportedLeague = createAsyncThunk(
	ImportLeagueAction.ADD_IMPORTED_LEAGUE,
	async (
		params: {
			leagueId: string;
			leagueName: string;
			competitionId: string;
			importedTeamId: string;
			leagueIdExt: string;
		},
		thunkAPI,
	) => {
		try {
			const { selectedLeague } = thunkAPI.getState() as RootState;
			const newLeague = await leagueAPI.addImportedLeague(
				params.leagueId,
				params.leagueName,
				params.competitionId,
				params.importedTeamId,
				params.leagueIdExt,
			);
			thunkAPI.dispatch(
				updateLeaguesList({
					newLeague,
					selectedLeague: selectedLeague.league,
				}),
			);
			thunkAPI.dispatch(setSelectedLeague(newLeague));
			return;
		} catch (e) {
			throw e;
		}
	},
);

export const importLeagueAction = {
	importLeague,
	addImportedLeague,
};
