import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { League } from '../../api/league/league.model';

interface SelectedLeagueState {
	league?: League;
}

// Define the initial state using that type
const initialState: SelectedLeagueState = {};

export const selectedLeagueSlice = createSlice({
	name: 'selectedLeague',

	initialState,
	reducers: {
		setSelectedLeague: (state, action: PayloadAction<League>) => {
			state.league = action.payload;
		},
	},
});

export const { setSelectedLeague } = selectedLeagueSlice.actions;
