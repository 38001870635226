import React, { FC, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import useDarkMode from '../../../hooks/useDarkMode';
import { useKeycloak } from '@react-keycloak/web';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { keycloak, initialized } = useKeycloak();
	const { darkModeStatus } = useDarkMode();

	useEffect(() => {
		const loginFakeBtn = document.getElementById('login');
		const isRedirectingToKeycloakDashboard = window.location.href.includes('state');
		if (loginFakeBtn && !isRedirectingToKeycloakDashboard) {
			loginFakeBtn.click();
		}
	}, []);

	const doLogin = useCallback(() => {
		if (!keycloak.authenticated && initialized) {
			keycloak.login();
		}
	}, [keycloak, initialized]);

	return (
		<div id={'login'} style={{ visibility: 'hidden' }} onClick={doLogin}>
			Login
		</div>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
