/**
 * 
 * @param navigate retrievable using the useNavigate() hook
 * @param parentUri 
 * @param action
 * @param subpaths a list of subpaths that will be joined by "_"
 * @param open Adds or removes the subpaths from the action
 */
export const setOpen = (
  navigate: Function,
  parentUri: string,
  currentAction: string | undefined,
  desiredAction: string,
  subpaths: string[],
  open: boolean
) => {
  const otherActions = currentAction ? currentAction
    .split('-')
    .filter((a) => !a.startsWith(desiredAction))
    .join('-') : '';
  const preparedAction = open ? `-${[desiredAction, ...subpaths].join('_')}` : ''; 
  const newAction = `${otherActions}-${preparedAction}`.split('-').filter(a => a).join('-');

  navigate(`${parentUri}/${newAction}`);
};


export const getOpen = (currentAction: string | undefined, action: string): null | string[] => {
  if (!currentAction) return null;
  
  const match = currentAction
    .split('-')
    .filter((a) => a.startsWith(action));
  
  if (match.length === 0) return null;

  return match[0].split('_').filter((a) => a !== action);
}