import { createSlice } from '@reduxjs/toolkit';
import { championshipAction } from './championship.action';
import { Championship } from '../../api/campionato/campionato.model';

interface ChampionshipState {
	loading: boolean;
	error: boolean;
	detail?: Championship;
}

// Define the initial state using that type
const initialState: ChampionshipState = {
	loading: false,
	error: false,
};

export const championshipSlice = createSlice({
	name: 'championshipDetail',

	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(championshipAction.findDetail.pending, (state, action) => {
			state.loading = true;
			state.error = false;
		});
		builder.addCase(championshipAction.findDetail.fulfilled, (state, action) => {
			state.detail = action.payload;
			state.loading = false;
			state.error = false;
		});
		builder.addCase(championshipAction.findDetail.rejected, (state, action) => {
			state.error = true;
			state.loading = false;
		});
	},
});
