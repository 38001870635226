import React, { ChangeEvent, useState } from 'react';
import InputGroup, {
	InputGroupText,
} from '../../../../../../../components/bootstrap/forms/InputGroup';
import Input from '../../../../../../../components/bootstrap/forms/Input';

interface ImportFirstStepProps {
	myLeague: string;
	setMyLeague: (myLeague: string) => void;
}

const ImportFirstStep = (props: ImportFirstStepProps) => {
	const [isTouched, setIsTouched] = useState<boolean>(false);

	return (
		<>
			<label className='mb-1'>Nome della Lega</label>
			<InputGroup>
				<InputGroupText>leghe.fantacalcio.it/</InputGroupText>
				<Input
					placeholder='la-tua-lega'
					type={'text'}
					value={props.myLeague}
					required={true}
					isValid={props.myLeague.length !== 0}
					isTouched={isTouched}
					onBlur={() => setIsTouched(true)}
					invalidFeedback={'Il nome della lega è obbligatorio'}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						props.setMyLeague(e.target.value)
					}
					ariaLabel='Input'
				/>
			</InputGroup>
		</>
	);
};

export default ImportFirstStep;
