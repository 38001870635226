import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectedLeagueSelector } from '../../store/selectedLeague/selectedLeague.selector';
import { RootState } from '../../store/store';
import { PlayerDetail } from '../../api/player/player.model';
import { playerDetailSelector } from '../../store/playerDetail/playerDetail.selector';
import { playerDetailAction } from '../../store/playerDetail/playerDetail.action';

export const usePlayerDetail = (
	playerId: string,
): {
	playerDetail?: PlayerDetail;
	isLoading: boolean;
	isError: boolean;
	updateNote: (note: string) => Promise<any>;
	setNoteMessageError: string;
} => {
	const dispatch = useAppDispatch();

	const isLoading = useAppSelector(playerDetailSelector.isLoading);
	const isError = useAppSelector(playerDetailSelector.isError);
	const setNoteMessageError = useAppSelector(playerDetailSelector.getSetNoteMessageError);

	const selectedLeague = useAppSelector(selectedLeagueSelector.getSelectedLeague);
	const playerDetail = useAppSelector((state: RootState) =>
		playerDetailSelector.getPlayerDetail(state),
	);

	const updateNote = async (note: string): Promise<any> => {
		return dispatch(playerDetailAction.setNote({ playerId, note }));
	};

	useEffect(() => {
		if (selectedLeague && playerId) {
			dispatch(playerDetailAction.findDetail({ leagueId: selectedLeague.id, playerId }));
		}
	}, [selectedLeague, playerId, dispatch]);

	return { playerDetail, isLoading, isError, updateNote, setNoteMessageError };
};
