import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScreenshot } from 'use-react-screenshot';
import { AuctionContext } from '../contexts/auctionContext';
import Button from './bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from './bootstrap/Modal';
import Icon from './icon/Icon';
import { getOpen, setOpen } from '../helpers/navigation';
import { getAuctionData } from '../api/auction/auction.api';

interface ShareModalProps {
	isMobile: boolean;
	parentUri: string;
	action: string | undefined;
	auctionId: string;
}

export const ShareModal = (props: ShareModalProps) => {
	const { isMobile } = props;
	const navigate = useNavigate();

	const [shareCode, setShareCode] = useState<string>('');
	const isOpen = Boolean(getOpen(props.action, 'condividi'));

	const ref = useRef(null);
	const getImage = () => takeScreenshot(ref.current);
	const [image, takeScreenshot] = useScreenshot();

	useEffect(() => {
		if (image) downloadInviteImage();
	}, [image]);

	useEffect(() => {
		if (props.auctionId && isOpen) {
			getAuctionData(props.auctionId).then((data) => {
				console.log('dd', data);
				setShareCode(data.joinCode);
			});
		}
	}, [props.auctionId, isOpen]);

	const downloadInviteImage = () => {
		const link = document.createElement('a');
		link.href = image;
		link.download = 'Asta_Fantalgoritmo.png';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<Modal
				id={'share'}
				titleId={'Condividi'}
				isOpen={isOpen}
				setIsOpen={() => {
					setOpen(navigate, props.parentUri, props.action, 'condividi', [], !isOpen);
				}}
				isStaticBackdrop={true}
				isScrollable={true}
				isCentered={true}
				size={!isMobile ? 'lg' : 'sm'}
				fullScreen={!isMobile ? 'lg' : false}>
				<ModalHeader
					showClose={false}
					setIsOpen={() => {
						setOpen(navigate, props.parentUri, props.action, 'condividi', [], !isOpen);
					}}>
					<ModalTitle id={'share'}>Condividi Invito</ModalTitle>
					<button
						type='button'
						className='btn-close'
						data-bs-dismiss='modal'
						aria-label='Close'
						onClick={() => {
							setOpen(
								navigate,
								props.parentUri,
								props.action,
								'condividi',
								[],
								false,
							);
						}}
					/>
				</ModalHeader>
				<ModalBody className='d-flex flex-column gap-3'>
					<div
						ref={ref}
						id='screenshot'
						className='d-flex justify-content-center position-relative'>
						<img
							className='img-fluid img-thumbnail mx-auto d-block'
							src={require('../assets/img/join-auction.png')}
							alt="Unisciti all'asta"
							style={{
								maxWidth: '100%',
								maxHeight: 'calc(100vh - 200px)', // Assicura che l'immagine non superi l'altezza del modale
								height: 'auto',
							}}
						/>
						<h4
							className='position-absolute text-center'
							style={{
								bottom: '10%',
								left: '50%',
								transform: 'translateX(-50%)',
								color: 'white',
								backgroundColor: 'rgba(0, 0, 0, 0.5)',
								padding: '5px 10px',
								borderRadius: '5px',
							}}>
							{process.env.PUBLIC_URL}/asta/{shareCode}
						</h4>
					</div>
					<div className={'d-flex justify-content-evenly'}>
						<Button
							icon={'Share'}
							color={'primary'}
							onClick={() => {
								getImage();
							}}>
							Condividi Immagine
						</Button>
						<Button
							color={'info'}
							icon={'ContentCopy'}
							onClick={() =>
								navigator.clipboard.writeText(
									`${process.env.PUBLIC_URL_SHORT}/asta/${shareCode}`,
								)
							}>
							Copia Link
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};
