import React, { useContext, useEffect, useState } from 'react';
import { Influencer, InfluencerStatistics } from '../../../../api/influencer/influencer.model';
import {
	FantalgoritmoData,
	PlayerDetail,
	PlayerStatistics,
} from '../../../../api/player/player.model';
import Button, { ButtonGroup } from '../../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Progress from '../../../../components/bootstrap/Progress';
import Icon from '../../../../components/icon/Icon';

import { setInfluencerData } from '../../../../api/influencer/influencer.api';
import placeholderPlayer from '../../../../assets/img/placeholder-player.svg';
import Collapse from '../../../../components/bootstrap/Collapse';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../../components/bootstrap/Dropdown';
import Popovers from '../../../../components/bootstrap/Popovers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import FormText from '../../../../components/bootstrap/forms/FormText';
import Input from '../../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../../components/bootstrap/forms/InputGroup';
import Label from '../../../../components/bootstrap/forms/Label';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';

import { ToastContext } from '../../../../contexts/toastContext';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../../components/bootstrap/Badge';

function calculateAge(dobString: string) {
	const parts = dobString.split('/');
	const dobDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
	const today = new Date();

	let age = today.getFullYear() - dobDate.getFullYear();
	const m = today.getMonth() - dobDate.getMonth();

	if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
		age--;
	}

	return age;
}

function remainingMonths(dateString: string) {
	const date = new Date(dateString);
	const now = new Date();

	let years = date.getFullYear() - now.getFullYear();
	let months = date.getMonth() - now.getMonth();

	if (months < 0 || (months === 0 && date.getDate() < now.getDate())) {
		years--;
		months += 12;
	}

	return years * 12 + months;
}

function contractProgress(dateOfRegistration: string, contractExpiration: string): number {
	const registrationDate = new Date(dateOfRegistration);
	const expirationDate = new Date(contractExpiration);
	const now = new Date();

	const totalDuration = expirationDate.getTime() - registrationDate.getTime();
	const elapsedDuration = now.getTime() - registrationDate.getTime();

	const progress = (elapsedDuration / totalDuration) * 100;

	return progress;
}

function getCardColor(value: number): string {
	if (value <= 39) {
		return 'bg-l25-danger';
	} else if (value <= 74) {
		return 'bg-l25-warning';
	} else {
		return 'bg-l25-success';
	}
}

function createStatisticsCard(
	name: string,
	value: any,
	rating: any,
	isMobile: boolean,
	role: string,
): JSX.Element {
	return (
		<div className='col-6 col-lg-4'>
			<Card
				shadow={'sm'}
				className={`text-center rounded-1 ${
					role === 'FREEMIUM'
						? getCardColor(Math.floor(Math.random() * 101))
						: getCardColor(rating)
				}`}>
				<CardBody className={'px-3'}>
					<Popovers title={name} placement={'top'} trigger={'hover'}>
						<h6
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
							{name}
						</h6>
					</Popovers>
					{value === null ? (
						<h5>
							<strong>N.D.</strong>
						</h5>
					) : role === 'FREEMIUM' ? (
						<h5>
							<strong>N.D.</strong>
						</h5>
					) : (
						<h5>
							<strong>{value}</strong>
						</h5>
					)}
				</CardBody>
			</Card>
		</div>
	);
}

function createStatisticsBigCard(name: string, value: any, role: string): JSX.Element {
	return (
		<div className='col-12 col-lg-6'>
			<Card shadow={'sm'} className={`text-center rounded-1`}>
				<CardBody className={'px-3'}>
					{name === 'Campionato' || name === 'Squadra' ? (
						<h6
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
							{name}
						</h6>
					) : (
						<Popovers title={name} placement={'top'} trigger={'hover'}>
							<h6
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}>
								{name}
							</h6>
						</Popovers>
					)}

					{value === null ? (
						<h5>
							<strong>N.D.</strong>
						</h5>
					) : role === 'FREEMIUM' ? (
						<h5>
							<strong>N.D.</strong>
						</h5>
					) : value.split('/').length === 2 ? (
						value.split('/').map((val: string, index: number) => (
							<h5
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
								key={index}>
								<strong>{val}</strong>
							</h5>
						))
					) : (
						<h5
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
							<strong>{value}</strong>
						</h5>
					)}
				</CardBody>
			</Card>
		</div>
	);
}

export const renderPlayerIdentikit = (playerDetail: PlayerDetail) => {
	if (!playerDetail) {
		return null;
	}

	const safeRequire = () => {
		try {
			return require(`../../../../assets/img/teams-logos/${playerDetail.team.toLowerCase()}.png`);
		} catch {
			return null;
		}
	};

	return (
		<Card>
			<CardHeader className='d-flex align-items-center justify-content-between pb-0'>
				<CardTitle className='d-flex align-items-center gap-2 my-auto'>
					<Icon icon={'AccountBox'} color={'info'} size={'2x'}></Icon>
					<h5 className='fw-bold my-auto'>Identikit</h5>
				</CardTitle>
				<CardActions>
					<span
						className={`badge rounded-1 mx-2 fs-5
                            ${
								playerDetail.role === 'P'
									? 'bg-warning'
									: playerDetail.role === 'D'
									? 'bg-success'
									: playerDetail.role === 'C'
									? 'bg-info'
									: 'bg-danger'
							}`}>
						{playerDetail.role}
					</span>
					{playerDetail?.mantraRole &&
						playerDetail?.mantraRole.map((mantraRole) => {
							return (
								<span
									key={mantraRole}
									className={`badge rounded-1 me-2 fs-5
                                        ${
											mantraRole === 'Por'
												? 'bg-warning'
												: mantraRole === 'Ds' ||
												  mantraRole === 'Dc' ||
												  mantraRole === 'Dd'
												? 'bg-success'
												: mantraRole === 'E' ||
												  mantraRole === 'M' ||
												  mantraRole === 'C'
												? 'bg-info'
												: mantraRole === 'W' || mantraRole === 'T'
												? 'bg-mantraWT'
												: 'bg-danger'
										}`}>
									{mantraRole}
								</span>
							);
						})}
				</CardActions>
			</CardHeader>
			<CardBody className='pt-3'>
				<div className={'row'}>
					<div className={'col-12'}>
						<div className='d-flex justify-content-center align-items-center gap-2'>
							<img
								src={playerDetail.icon ? playerDetail.icon : placeholderPlayer}
								style={{ maxWidth: 100, maxHeight: 100 }}></img>
							<div className='d-flex flex-column justify-content-center align-items-center mb-3'>
								{playerDetail.fullName && (
									<div className='h4 fw-bold'>{playerDetail.fullName}</div>
								)}
								<div className='d-flex flex-row align-items-center gap-1'>
									<img src={safeRequire()} width={20} />
									{playerDetail.team && (
										<div className='h7'>{playerDetail.team}</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className={'col-6'}>
						<Card shadow={'sm'} className='text-center rounded-1'>
							<CardBody>
								<h6
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}>
									Nazionalità
								</h6>
								{playerDetail.nationality && (
									<h5>
										<strong>{playerDetail.nationality}</strong>
									</h5>
								)}
							</CardBody>
						</Card>
					</div>
					<div className={'col-6'}>
						<Card shadow={'sm'} className='text-center rounded-1'>
							<CardBody>
								<h6
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}>
									Altezza
								</h6>
								{playerDetail.height && (
									<h5>
										<strong>{parseInt(playerDetail.height) / 100} m</strong>
									</h5>
								)}
							</CardBody>
						</Card>
					</div>
					<div className={'col-6'}>
						<Card shadow={'sm'} className='text-center rounded-1' stretch>
							<CardBody>
								<h6
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}>
									Data Nascita
								</h6>
								{playerDetail.dateOfBirth && (
									<h5>
										<strong>
											{playerDetail.dateOfBirth
												.split('-')
												.reverse()
												.join('/')}{' '}
											<br />(
											{calculateAge(
												playerDetail.dateOfBirth
													.split('-')
													.reverse()
													.join('/'),
											)}
											)
										</strong>
									</h5>
								)}
							</CardBody>
						</Card>
					</div>
					<div className={'col-6'}>
						<Card shadow={'sm'} className='text-center rounded-1' stretch>
							<CardBody className='mt-3'>
								<h6
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}>
									Piede
								</h6>
								{playerDetail.foot && (
									<h5>
										<strong>{playerDetail.foot}</strong>
									</h5>
								)}
							</CardBody>
						</Card>
					</div>
					<div className={'col-12'}>
						<Card shadow={'sm'} className='text-center rounded-1'>
							<CardBody>
								<h6
									style={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}>
									Mesi di Contratto Rimanenti
								</h6>
								{playerDetail.dateContractExpiration && (
									<h5>
										<strong>
											{remainingMonths(playerDetail.dateContractExpiration)}
										</strong>
									</h5>
								)}
								{playerDetail.dateContractExpiration &&
									playerDetail.dateContractRegistration && (
										<Progress
											isAutoColorInverted={true}
											isAnimated={true}
											min={0}
											max={100}
											value={contractProgress(
												playerDetail.dateContractRegistration,
												playerDetail.dateContractExpiration,
											)}
											className={'w-100'}></Progress>
									)}
							</CardBody>
						</Card>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

// export const renderDatiFantalgoritmo = (
// 	playerDetail: PlayerDetail,
// 	isMobile: boolean,
// 	role: string,
// 	navigate: any,
// 	viewType: 'classic' | 'mantra',
// 	setViewType: React.Dispatch<React.SetStateAction<'classic' | 'mantra'>>,
// 	getValue: (type: 'classic' | 'mantra', field: string) => number | undefined
// ) => {
// 	return (
// 		<Card>
// 			<CardHeader>
// 				<CardTitle className="d-flex align-items-center gap-2 my-auto">
// 					<Icon icon={'BarChart'} color={'info'} size={'2x'}></Icon>
// 					<h5 className="fw-bold my-auto">Dati Fantalgoritmo</h5>
// 				</CardTitle>
// 				<CardActions>
// 					<ButtonGroup>
// 						<Button color={'info'} isActive={viewType === 'classic'} onClick={() => setViewType('classic')}>
// 							C<span className={'d-none d-xl-inline'}>lassic</span>
// 						</Button>
// 						<Popovers
// 							title={'Nuova Funzionalità'}
// 							desc={'Presto Disponibile!'}
// 							placement={'left-end'}
// 							trigger={'hover'}
// 							delay={5000}>
// 							<Button color={'info'} isActive={viewType === 'mantra'} onClick={() => setViewType('mantra')} isDisable={false} isOutline={true}>
// 								M<span className={'d-none d-xl-inline'}>antra</span>
// 							</Button>
// 						</Popovers>
// 					</ButtonGroup>
// 				</CardActions>
// 			</CardHeader>
// 			{role === 'FREEMIUM' && (
// 				<Tooltips
// 					title={'Passa a Premium per vedere i dati'}
// 					placement={'auto'}
// 					flip={['auto']}>
// 					<div
// 						style={{
// 							textAlign: 'center',
// 							position: 'absolute',
// 							top: '60%',
// 							left: '50%',
// 							transform: 'translate(-50%, -50%)',
// 							zIndex: 1,
// 						}}
// 						onClick={() => {
// 							navigate('/upgrade');
// 						}}>
// 						<Icon icon={'Lock'} color={'dark'} size={'4x'} />
// 						<h5 className="text-center">Passa a Premium per vedere i dati</h5>
// 					</div>
// 				</Tooltips>
// 			)}
// 			<CardBody style={{ filter: role === 'FREEMIUM' ? 'blur(6px)' : '' }}>
// 				{role !== 'FREEMIUM' && !playerDetail?.data && (
// 					<CenteredMessage text="I dati sono in elaborazione... Riprova più tardi! 😉" />
// 				)}
// 				<div className={'row'}>
// 					<div
// 						className="col-12 mb-2 text-center"
// 						style={{
// 							filter: role === 'FREEMIUM' || !playerDetail?.data ? 'blur(6px)' : '',
// 						}}>
// 						<span>
// 						  <b>Qualità/Prezzo</b>
// 						</span>
// 						<Progress
// 							isAutoColor={true}
// 							value={
// 								role === 'FREEMIUM'
// 									? 50
// 									: playerDetail && playerDetail?.data
// 										? getValue(viewType, 'valueForMoney')
// 										: 50
// 							}
// 							style={{ width: '100%' }}></Progress>
// 					</div>
// 					<div
// 						className="col-12 mt-2"
// 						style={{
// 							filter: role === 'FREEMIUM' || !playerDetail?.data ? 'blur(6px)' : '',
// 						}}>
// 						<InfoCard
// 							title="Prezzo Fantalgoritmo"
// 							value={getValue(viewType, 'staticPrice')}
// 							role={role}
// 							playerDetail={playerDetail}
// 						/>
// 					</div>
// 					<div className="col-6">
// 						<InfoCard
// 							title="Quotazione"
// 							value={getValue(viewType, 'quotation')}
// 							role={role}
// 							playerDetail={playerDetail}
// 						/>
// 					</div>
// 					<div className="col-6">
// 						<InfoCard
// 							title="Indice Appetibilità"
// 							value={getValue(viewType, 'reliabilityIndex')}
// 							role={role}
// 							playerDetail={playerDetail}
// 						/>
// 					</div>
// 					<div className="col-6">
// 						<InfoCard
// 							title="Prezzo Medio Aste"
// 							value={playerDetail?.data?.averageAuctionPrice}
// 							role={role}
// 							playerDetail={playerDetail}
// 						/>
// 					</div>
// 					<div className="col-6">
// 						<InfoCard
// 							title="Valore di Mercato"
// 							value={getValue(viewType, 'marketValue')}
// 							role={role}
// 							playerDetail={playerDetail}
// 						/>
// 					</div>
// 				</div>
// 			</CardBody>
// 		</Card>
// 	);
// };

export const renderStatistiche = (
	playerDetail: PlayerDetail,
	playerStatistics: PlayerStatistics,
	dropdownOpenStatistiche: boolean,
	setDropdownOpenStatistiche: (value: boolean) => void,
	selectedYearStatistiche: string,
	setSelectedYearStatistiche: (value: string) => void,
	statisticheCollapseState: boolean,
	setStatisticheCollapseState: (value: boolean) => void,
	isMobile: boolean,
	role: string,
	navigate: any,
) => {
	const availableYears = Object.keys(playerStatistics?.performances || {});

	return (
		<Card>
			<CardHeader>
				<CardTitle className='d-flex align-items-center gap-2 my-auto'>
					<Icon icon={'ShowChart'} color={'info'} size={'2x'}></Icon>
					<h5 className='fw-bold my-auto'>Statistiche</h5>
				</CardTitle>
				<CardActions>
					<ButtonGroup>
						<Button color={'info'} isActive={true}>
							C<span className={'d-none d-xl-inline'}>lassic</span>
						</Button>
						<Popovers
							title={'Nuova Funzionalità'}
							desc={'Presto Disponibile!'}
							placement={'left-end'}
							trigger={'hover'}
							delay={5000}>
							<Button color={'info'} isDisable={false} isOutline={true}>
								M<span className={'d-none d-xl-inline'}>antra</span>
							</Button>
						</Popovers>
					</ButtonGroup>
					{availableYears && (
						<Dropdown
							isOpen={dropdownOpenStatistiche}
							setIsOpen={setDropdownOpenStatistiche}
							direction={'down'}
							isButtonGroup={true}
							className='bg-l25-info rounded-pill'>
							<DropdownToggle hasIcon={true}>
								<Button className='text-info'>{selectedYearStatistiche}</Button>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd={true} breakpoint={'sm'} size={'sm'}>
								{availableYears
									.sort((a, b) => Number(b) - Number(a))
									.map((year, index) => (
										<DropdownItem
											key={index}
											onClick={() => {
												setSelectedYearStatistiche(year);
												setDropdownOpenStatistiche(false);
											}}>
											{year}
										</DropdownItem>
									))}
							</DropdownMenu>
						</Dropdown>
					)}
				</CardActions>
			</CardHeader>
			{role === 'FREEMIUM' && (
				<Tooltips
					title={'Passa a Premium per vedere i dati'}
					placement={'auto'}
					flip={['auto']}>
					<div
						style={{
							textAlign: 'center',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: 1000,
						}}
						onClick={() => {
							navigate('/upgrade');
						}}>
						<Icon icon={'Lock'} color={'dark'} size={'4x'} />
						<h5 className='text-center'>Passa a Premium per vedere i dati</h5>
					</div>
				</Tooltips>
			)}
			{Object.keys(playerStatistics.performances).length === 0 && role !== 'FREEMIUM' && (
				<div
					style={{
						position: 'absolute',
						top: '55%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						zIndex: 1,
					}}>
					<h5 className='text-center'>I dati sono in elaborazione...</h5>
					<h5 className='text-center'>Riprova più tardi! 😉</h5>
				</div>
			)}
			<CardBody
				style={{
					filter:
						Object.keys(playerStatistics.performances).length === 0 ||
						role === 'FREEMIUM'
							? 'blur(6px)'
							: '',
				}}
				className='pt-0'>
				<div className='row'>
					{playerStatistics?.performances[`${selectedYearStatistiche}`]?.competition
						? createStatisticsBigCard(
								'Campionato',
								playerStatistics?.performances[`${selectedYearStatistiche}`]
									?.competition,
								role,
						  )
						: createStatisticsBigCard('Campionato', 'N.D.', role)}
					{playerStatistics?.performances[`${selectedYearStatistiche}`]?.competition
						? createStatisticsBigCard(
								'Squadra',
								playerStatistics?.performances[`${selectedYearStatistiche}`]?.club,
								role,
						  )
						: createStatisticsBigCard('Campionato', 'N.D.', role)}
					{playerStatistics?.performances[`${selectedYearStatistiche}`]?.appearances
						? createStatisticsCard(
								'Partite Giocate',
								playerStatistics?.performances[`${selectedYearStatistiche}`]
									?.appearances.value,
								playerStatistics?.performances[`${selectedYearStatistiche}`]
									?.appearances.rating,
								isMobile,
								role,
						  )
						: createStatisticsCard('Partite Giocate', 'N.D.', null, isMobile, role)}
					{playerStatistics?.performances[`${selectedYearStatistiche}`]?.averageVote
						? createStatisticsCard(
								'Media Voto',
								playerStatistics?.performances[`${selectedYearStatistiche}`]
									?.averageVote.value,
								playerStatistics?.performances[`${selectedYearStatistiche}`]
									?.averageVote.rating,
								isMobile,
								role,
						  )
						: createStatisticsCard('Media Voto', 'N.D.', null, isMobile, role)}
					{playerStatistics?.performances[`${selectedYearStatistiche}`]?.averageVote
						? createStatisticsCard(
								'Media Voto Fanta',
								playerStatistics?.performances[`${selectedYearStatistiche}`]
									?.averageFantaVote.value,
								playerStatistics?.performances[`${selectedYearStatistiche}`]
									?.averageFantaVote.rating,
								isMobile,
								role,
						  )
						: createStatisticsCard('Media Voto Fanta', 'N.D.', null, isMobile, role)}

					{playerDetail.role &&
						playerDetail.role.includes('P') &&
						(playerStatistics?.performances?.[`${selectedYearStatistiche}`]
							?.goalsConceded
							? createStatisticsCard(
									'Goal Concessi',
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.goalsConceded.value,
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.goalsConceded.rating,
									isMobile,
									role,
							  )
							: createStatisticsCard('Goal Concessi', 'N.D.', null, isMobile, role))}

					{playerDetail.role &&
						playerDetail.role.includes('P') &&
						(playerStatistics?.performances?.[`${selectedYearStatistiche}`]?.cleanSheets
							? createStatisticsCard(
									'Clean Sheets',
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.cleanSheets.value,
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.cleanSheets.rating,
									isMobile,
									role,
							  )
							: createStatisticsCard('Clean Sheets', 'N.D.', null, isMobile, role))}

					{playerStatistics?.performances[`${selectedYearStatistiche}`]?.goals
						? createStatisticsCard(
								'Goal',
								playerStatistics?.performances[`${selectedYearStatistiche}`]?.goals
									.value,
								playerStatistics?.performances[`${selectedYearStatistiche}`]?.goals
									.rating,
								isMobile,
								role,
						  )
						: createStatisticsCard('Goal', 'N.D.', null, isMobile, role)}

					{playerDetail.role &&
						!playerDetail.role.includes('P') &&
						(playerStatistics?.performances[`${selectedYearStatistiche}`]?.assists
							? createStatisticsCard(
									'Assist',
									playerStatistics?.performances[`${selectedYearStatistiche}`]
										?.assists.value,
									playerStatistics?.performances[`${selectedYearStatistiche}`]
										?.assists.rating,
									isMobile,
									role,
							  )
							: createStatisticsCard('Assist', 'N.D.', null, isMobile, role))}

					{playerStatistics?.performances?.[`${selectedYearStatistiche}`]?.ownGoals
						? createStatisticsCard(
								'Autoreti',
								playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.ownGoals.value,
								playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.ownGoals.rating,
								isMobile,
								role,
						  )
						: createStatisticsCard('Autoreti', 'N.D.', null, isMobile, role)}

					{playerStatistics?.performances?.[`${selectedYearStatistiche}`]?.yellowCards
						? createStatisticsCard(
								'Ammonizioni',
								playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.yellowCards.value,
								playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.yellowCards.rating,
								isMobile,
								role,
						  )
						: createStatisticsCard('Ammonizioni', 'N.D.', null, isMobile, role)}

					{playerStatistics?.performances?.[`${selectedYearStatistiche}`]
						?.secondYellowCards
						? createStatisticsCard(
								'Doppia Ammonizione',
								playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.secondYellowCards.value,
								playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.secondYellowCards.rating,
								isMobile,
								role,
						  )
						: createStatisticsCard('Doppia Ammonizione', 'N.D.', null, isMobile, role)}

					{!isMobile &&
						playerDetail.role &&
						!playerDetail.role.includes('P') &&
						(playerStatistics?.performances?.[`${selectedYearStatistiche}`]?.redCards
							? createStatisticsCard(
									'Espulsioni',
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.redCards.value,
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.redCards.rating,
									isMobile,
									role,
							  )
							: createStatisticsCard('Espulsioni', 'N.D.', null, isMobile, role))}
					{isMobile &&
						playerDetail.role &&
						!playerDetail.role.includes('P') &&
						(playerStatistics?.performances?.[`${selectedYearStatistiche}`]?.redCards
							? createStatisticsCard(
									'Espulsioni',
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.redCards.value,
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.redCards.rating,
									isMobile,
									role,
							  )
							: createStatisticsCard('Espulsioni', 'N.D.', null, isMobile, role))}
					{isMobile &&
						(playerStatistics?.performances?.[`${selectedYearStatistiche}`]
							?.substitutionsOn
							? createStatisticsCard(
									'Sostituzioni',
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.substitutionsOn.value,
									playerStatistics?.performances?.[`${selectedYearStatistiche}`]
										?.substitutionsOn.rating,
									isMobile,
									role,
							  )
							: createStatisticsCard('Sostituzioni', 'N.D.', null, isMobile, role))}
				</div>
				<div
					className='d-flex flex-column'
					style={{ filter: role === 'FREEMIUM' ? 'blur(6px)' : '' }}>
					<Collapse isOpen={statisticheCollapseState}>
						<div className='row'>
							{!isMobile &&
								playerDetail.role &&
								playerDetail.role.includes('P') &&
								(playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.redCards
									? createStatisticsCard(
											'Espulsioni',
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.redCards.value,
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.redCards.rating,
											isMobile,
											role,
									  )
									: createStatisticsCard(
											'Espulsioni',
											'N.D.',
											null,
											isMobile,
											role,
									  ))}
							{isMobile &&
								playerDetail.role &&
								playerDetail.role.includes('P') &&
								(playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.redCards
									? createStatisticsCard(
											'Espulsioni',
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.redCards.value,
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.redCards.rating,
											isMobile,
											role,
									  )
									: createStatisticsCard(
											'Espulsioni',
											'N.D.',
											null,
											isMobile,
											role,
									  ))}
							{!isMobile &&
								(playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.substitutionsOn
									? createStatisticsCard(
											'Sostituzioni',
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.substitutionsOn.value,
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.substitutionsOn.rating,
											isMobile,
											role,
									  )
									: createStatisticsCard(
											'Sostituzioni',
											'N.D.',
											null,
											isMobile,
											role,
									  ))}
							{playerStatistics?.performances?.[`${selectedYearStatistiche}`]
								?.substitutionsOff
								? createStatisticsCard(
										'Ingressi da panchina',
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.substitutionsOff.value,
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.substitutionsOff.rating,
										isMobile,
										role,
								  )
								: createStatisticsCard(
										'Ingressi da panchina',
										'N.D.',
										null,
										isMobile,
										role,
								  )}

							{playerDetail.role &&
								!playerDetail.role.includes('P') &&
								(playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.penaltyGoals
									? createStatisticsCard(
											'Goal su rigore',
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.penaltyGoals.value,
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.penaltyGoals.rating,
											isMobile,
											role,
									  )
									: createStatisticsCard(
											'Goal su rigore',
											'N.D.',
											null,
											isMobile,
											role,
									  ))}

							{playerDetail.role &&
								!playerDetail.role.includes('P') &&
								(playerStatistics?.performances?.[`${selectedYearStatistiche}`]
									?.minutesPerGoal
									? createStatisticsCard(
											'Minuti per Goal',
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.minutesPerGoal.value,
											playerStatistics?.performances?.[
												`${selectedYearStatistiche}`
											]?.minutesPerGoal.rating,
											isMobile,
											role,
									  )
									: createStatisticsCard(
											'Minuti per Goal',
											'N.D.',
											null,
											isMobile,
											role,
									  ))}

							{playerStatistics?.performances?.[`${selectedYearStatistiche}`]
								?.minutesPlayed
								? createStatisticsCard(
										'Minuti Giocati',
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.minutesPlayed.value,
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.minutesPlayed.rating,
										isMobile,
										role,
								  )
								: createStatisticsCard(
										'Minuti Giocati',
										'N.D.',
										null,
										isMobile,
										role,
								  )}

							{playerStatistics?.performances?.[`${selectedYearStatistiche}`]
								?.minutesPercentage
								? createStatisticsCard(
										'Minuti Giocati (%)',
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.minutesPercentage.value,
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.minutesPercentage.rating,
										isMobile,
										role,
								  )
								: createStatisticsCard(
										'Minuti Giocati (%)',
										'N.D.',
										null,
										isMobile,
										role,
								  )}

							{playerStatistics?.performances?.[`${selectedYearStatistiche}`]
								?.goalParticipationPercentage
								? createStatisticsCard(
										'Partecipazione ai Goal (%)',
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.goalParticipationPercentage.value,
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.goalParticipationPercentage.rating,
										isMobile,
										role,
								  )
								: createStatisticsCard(
										'Partecipazione ai Goal (%)',
										'N.D.',
										null,
										isMobile,
										role,
								  )}

							{playerStatistics?.performances?.[`${selectedYearStatistiche}`]
								?.startingElevenPercentage
								? createStatisticsCard(
										'Presenze iniziali (%)',
										+playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.startingElevenPercentage.value,
										playerStatistics?.performances?.[
											`${selectedYearStatistiche}`
										]?.startingElevenPercentage.rating,
										isMobile,
										role,
								  )
								: createStatisticsCard(
										'Presenze iniziali (%)',
										'N.D.',
										null,
										isMobile,
										role,
								  )}
						</div>
					</Collapse>
					<Button
						className='rounded-1 w-100 p-3 text-start d-flex justify-content-center align-items-center py-1'
						shadow={'none'}
						onClick={() => {
							if (role !== 'FREEMIUM')
								setStatisticheCollapseState(!statisticheCollapseState);
						}}>
						<p className='mb-0'>{statisticheCollapseState ? 'Chiudi' : 'Espandi'}</p>
						{statisticheCollapseState ? (
							<Icon icon={'KeyboardArrowUp'} size={'lg'}></Icon>
						) : (
							<Icon icon={'KeyboardArrowDown'} size={'lg'}></Icon>
						)}
					</Button>
				</div>
			</CardBody>
		</Card>
	);
};

export const renderInfortuni = (
	playerStatistics: PlayerStatistics,
	dropdownOpenInfortuni: boolean,
	setDropdownOpenInfortuni: (value: boolean) => void,
	selectedYearInfortuni: string,
	setSelectedYearInfortuni: (value: string) => void,
	isMobile: boolean,
	role: string,
	navigate: any,
) => {
	const availableYears = Object.keys(playerStatistics?.injuries || {});

	return (
		<Card>
			<CardHeader>
				<CardTitle className='d-flex align-items-center gap-2 my-auto'>
					<Icon icon={'LocalHospital'} color={'info'} size={'2x'}></Icon>
					<h5 className='fw-bold my-auto'>Infortuni</h5>
				</CardTitle>
				<CardActions>
					<ButtonGroup>
						<Button color={'info'} isActive={true}>
							C<span className={'d-none d-xl-inline'}>lassic</span>
						</Button>
						<Popovers
							title={'Nuova Funzionalità'}
							desc={'Presto Disponibile!'}
							placement={'left-end'}
							trigger={'hover'}
							delay={5000}>
							<Button color={'info'} isDisable={false} isOutline={true}>
								M<span className={'d-none d-xl-inline'}>antra</span>
							</Button>
						</Popovers>
					</ButtonGroup>
					{availableYears && (
						<Dropdown
							isOpen={dropdownOpenInfortuni}
							setIsOpen={setDropdownOpenInfortuni}
							direction={'down'}
							isButtonGroup={true}
							className='bg-l25-info rounded-pill'>
							<DropdownToggle hasIcon={true}>
								<Button className='text-info'>{selectedYearInfortuni}</Button>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd={true} breakpoint={'sm'} size={'sm'}>
								{availableYears
									.sort((a, b) => Number(b) - Number(a))
									.map((year, index) => (
										<DropdownItem
											key={index}
											onClick={() => {
												setSelectedYearInfortuni(year);
												setDropdownOpenInfortuni(false);
											}}>
											{year}
										</DropdownItem>
									))}
							</DropdownMenu>
						</Dropdown>
					)}
				</CardActions>
			</CardHeader>

			<CardBody className='pt-0' style={{ filter: !playerStatistics ? 'blur(6px)' : '' }}>
				{role === 'FREEMIUM' && (
					<Tooltips
						title={'Passa a Premium per vedere i dati'}
						placement={'auto'}
						flip={['auto']}>
						<div
							style={{
								textAlign: 'center',
								position: 'absolute',
								top: '60%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								zIndex: 1,
							}}
							onClick={() => {
								navigate('/upgrade');
							}}>
							<Icon icon={'Lock'} color={'dark'} size={'4x'} />
							<h5 className='text-center'>Passa a Premium per vedere i dati</h5>
						</div>
					</Tooltips>
				)}
				{role !== 'FREEMIUM' && !playerStatistics?.injuries && (
					<div
						style={{
							position: 'absolute',
							top: '60%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: 1,
						}}>
						<h5 className='text-center'>I dati sono in elaborazione...</h5>
						<h5 className='text-center'>Riprova più tardi! 😉</h5>
					</div>
				)}
				<div
					className='row'
					style={{
						filter:
							role === 'FREEMIUM' || !playerStatistics?.injuries ? 'blur(6px)' : '',
					}}>
					{playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
						?.currentDescription && (
						<div className='col-12'>
							<Card
								shadow={'sm'}
								className={`text-center rounded-1 ${
									role === 'FREEMIUM'
										? getCardColor(Math.floor(Math.random() * 101))
										: getCardColor(
												playerStatistics?.injuries?.[
													`${selectedYearInfortuni}`
												]?.currentGamesMissed.rating || 0,
										  )
								}
							`}>
								<CardBody className={'px-3'}>
									{role !== 'FREEMIUM' ? (
										<Popovers
											title={'Giocatore Infortunato'}
											placement={'top'}
											trigger={'hover'}>
											<h6
												style={{
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}>
												Giocatore Infortunato dal{' '}
												{
													playerStatistics?.injuries?.[
														`${selectedYearInfortuni}`
													]?.currentFrom
												}
											</h6>
										</Popovers>
									) : (
										<h6
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}>
											Giocatore Infortunato
										</h6>
									)}

									<h5>
										<strong>
											{playerStatistics?.injuries?.[
												`${selectedYearInfortuni}`
											]?.currentDescription
												? playerStatistics.injuries[selectedYearInfortuni]
														.currentDescription
												: 'N.D.'}
										</strong>
									</h5>
									<h6>
										{`Rientro previsto il ${
											playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
												?.currentUntil
												? playerStatistics.injuries[selectedYearInfortuni]
														.currentUntil
												: 'N.D.'
										}`}
									</h6>
									<h6>
										{`(${
											playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
												?.currentDays.value
												? playerStatistics.injuries[selectedYearInfortuni]
														.currentDays.value
												: 'N.D.'
										} giorni di stop / Salterà ${
											playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
												?.currentGamesMissed.value
												? playerStatistics.injuries[selectedYearInfortuni]
														.currentGamesMissed.value
												: 'N.D.'
										} giornate)`}
									</h6>
								</CardBody>
							</Card>
						</div>
					)}
					<div className='col-6 col-lg-4'>
						<Card
							shadow={'sm'}
							className={`text-center rounded-1 ${
								role === 'FREEMIUM'
									? getCardColor(Math.floor(Math.random() * 101))
									: getCardColor(
											playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
												?.totalDays.rating || 0,
									  )
							}
						`}>
							<CardBody className={'px-3'}>
								{role !== 'FREEMIUM' ? (
									<Popovers
										title={'Totale Giorni'}
										placement={'top'}
										trigger={'hover'}>
										<h6
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}>
											Totale Giorni
										</h6>
									</Popovers>
								) : (
									<h6
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}>
										Totale Giorni
									</h6>
								)}

								<h5>
									<strong>
										{playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
											?.totalDays
											? playerStatistics.injuries[selectedYearInfortuni]
													.totalDays.value
											: 'N.D.'}
									</strong>
								</h5>
							</CardBody>
						</Card>
					</div>

					<div className='col-6 col-lg-4'>
						<Card
							shadow={'sm'}
							className={`text-center rounded-1 ${
								role === 'FREEMIUM'
									? getCardColor(Math.floor(Math.random() * 101))
									: getCardColor(
											playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
												?.totalInjuryRate.rating || 0,
									  )
							}`}>
							<CardBody className={'px-3'}>
								{role !== 'FREEMIUM' ? (
									<Popovers
										title={`Numero Infortuni`}
										placement={'top'}
										trigger={'hover'}>
										<h6
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}>
											Numero Infortuni
										</h6>
									</Popovers>
								) : (
									<h6
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}>
										Numero Infortuni
									</h6>
								)}

								<h5>
									<strong>
										{playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
											?.totalInjuryRate
											? playerStatistics.injuries[selectedYearInfortuni]
													.totalInjuryRate.value
											: 'N.D.'}
									</strong>
								</h5>
							</CardBody>
						</Card>
					</div>

					<div className='col-6 col-lg-4'>
						<Card
							shadow={'sm'}
							className={`text-center rounded-1 ${
								role === 'FREEMIUM'
									? getCardColor(Math.floor(Math.random() * 101))
									: getCardColor(
											playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
												?.totalGamesMissed.rating || 0,
									  )
							} `}>
							<CardBody className={'px-3'}>
								{role !== 'FREEMIUM' ? (
									<Popovers
										title={`Giornate Saltate`}
										placement={'top'}
										trigger={'hover'}>
										<h6
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}>
											Giornate Saltate
										</h6>
									</Popovers>
								) : (
									<h6
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}>
										Giornate Saltate
									</h6>
								)}

								<h5>
									<strong>
										{playerStatistics?.injuries?.[`${selectedYearInfortuni}`]
											?.totalGamesMissed
											? playerStatistics.injuries[selectedYearInfortuni]
													.totalGamesMissed.value
											: 'N.D.'}
									</strong>
								</h5>
							</CardBody>
						</Card>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

interface InfluencerProps {
	playerId: string;
	playerDetail: PlayerDetail;
	selectedInfluencer: Influencer | undefined;
	influencerStatistics: InfluencerStatistics | undefined;
	setInfluencerStatistics: (value: InfluencerStatistics) => void;
	collapseState: boolean;
	setCollapseState: (value: boolean) => void;
	userSubscription: any;
	isMobile: boolean;
}

export const RenderInfluencer: React.FC<InfluencerProps> = ({
	playerId,
	selectedInfluencer,
	playerDetail,
	influencerStatistics,
	setInfluencerStatistics,
	collapseState,
	setCollapseState,
	userSubscription,
	isMobile,
}) => {
	const [modifying, setModifying] = useState<boolean>(false);
	const [request, setRequest] = useState<any>();
	const navigate = useNavigate();

	const [priceInvalid, setPriceInvalid] = useState<boolean>(false);
	const [percentageBudgetInvalid, setPercentageBudgetInvalid] = useState<boolean>(false);
	const [bracketInvalid, setBracketInvalid] = useState<boolean>(false);
	const [startingLineupInvalid, setStartingLineupInvalid] = useState<boolean>(false);
	const [reliabilityInvalid, setReliabilityInvalid] = useState<boolean>(false);
	const [commentInvalid, setCommentInvalid] = useState<boolean>(false);

	const note1Values = [
		{ label: 'Top', value: 1 },
		{ label: 'Semitop', value: 2 },
		{ label: 'Buono', value: 3 },
		{ label: 'Titolare', value: 4 },
		{ label: 'Scommessa', value: 5 },
		{ label: 'Jolly', value: 6 },
		{ label: 'Rischio', value: 7 },
		{ label: 'Incognita', value: 8 },
		{ label: 'N.D.', value: 99 },
	];

	const note2Values = [
		{ label: 'Incostante', value: 1 },
		{ label: 'Rigorista', value: 2 },
		{ label: 'Affidabile', value: 3 },
		{ label: 'Assistman', value: 4 },
		{ label: 'Esca', value: 5 },
		{ label: 'Modificatore', value: 6 },
		{ label: 'Occasione', value: 7 },
		{ label: 'Ammonizione', value: 8 },
		{ label: 'Bonus', value: 9 },
		{ label: 'Infortuni', value: 10 },
		{ label: 'Punizioni', value: 11 },
		{ label: 'Pararigori', value: 12 },
		{ label: 'Riserva', value: 13 },
		{ label: 'Low cost', value: 14 },
	];

	const fasciaOptions = [1, 2, 3, 4, 5, 6, 7, 8];

	useEffect(() => {
		if (influencerStatistics) {
			if (influencerStatistics.price !== null) {
				setRequest({
					price: influencerStatistics.price,
					percentageBudget: influencerStatistics.percentageBudget,
					bracket: influencerStatistics.bracket,
					startingLineup: influencerStatistics.startingLineup,
					reliability: influencerStatistics.reliability,
					note1: influencerStatistics.note1,
					note2: influencerStatistics.note2,
					note3: influencerStatistics.note3,
					note1String: influencerStatistics.note1String,
					note2String: influencerStatistics.note2String,
					note3String: influencerStatistics.note3String,
					comment: influencerStatistics.comment,
					marketObjective: influencerStatistics.marketObjective,
					notRecommendedPlayer: influencerStatistics.notRecommendedPlayer,
					recommendedPlayer: influencerStatistics.recommendedPlayer,
					advice: influencerStatistics.advice,
				});
			} else {
				setRequest({
					price: 0,
					percentageBudget: 0,
					bracket: 0,
					startingLineup: 0,
					reliability: 0,
					note1: '',
					note2: '',
					note3: '',
					note1String: '',
					note2String: '',
					note3String: '',
					comment: '',
					marketObjective: false,
					notRecommendedPlayer: false,
					recommendedPlayer: false,
					advice: '',
				});
			}
		}
	}, [influencerStatistics]);

	if (selectedInfluencer === undefined || influencerStatistics === undefined) {
		return null;
	}
	return (
		<Card>
			{influencerStatistics && (
				<>
					<CardHeader className='pb-0 d-flex flex-row align-items-center'>
						<CardTitle className='d-flex align-items-center gap-2 my-auto'>
							<Icon icon={'EmojiPeople'} color={'info'} size={'2x'}></Icon>
							<div className='d-flex flex-column my-auto'>
								<h5 className='fw-bold mb-0'>Creator</h5>
								<h6 className='mt-0 text-muted'>{selectedInfluencer?.name}</h6>
							</div>
						</CardTitle>
						<CardActions>
							<ButtonGroup>
								<Button color={'info'} isActive={true}>
									C<span className={'d-none d-xl-inline'}>lassic</span>
								</Button>
								<Popovers
									title={'Nuova Funzionalità'}
									desc={'Presto Disponibile!'}
									placement={'left-end'}
									trigger={'hover'}
									delay={5000}>
									<Button color={'info'} isDisable={false} isOutline={true}>
										M<span className={'d-none d-xl-inline'}>antra</span>
									</Button>
								</Popovers>
							</ButtonGroup>
							{!modifying &&
								userSubscription.role === 'INFLUENCER' &&
								selectedInfluencer?.id === userSubscription.influencerId && (
									<Button
										color={'info'}
										onClick={() => {
											setModifying(true);
										}}>
										Modifica
									</Button>
								)}
						</CardActions>
					</CardHeader>
				</>
			)}
			{!modifying && (
				<CardBody>
					{userSubscription.role === 'FREEMIUM' && (
						<Tooltips
							title={'Passa a Premium per vedere i dati'}
							placement={'auto'}
							flip={['auto']}>
							<div
								style={{
									textAlign: 'center',
									position: 'absolute',
									top: '55%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									zIndex: 1,
								}}
								onClick={() => {
									navigate('/upgrade');
								}}>
								<Icon icon={'Lock'} color={'dark'} size={'4x'} />
								<h5 className='text-center'>Passa a Premium per vedere i dati</h5>
							</div>
						</Tooltips>
					)}
					{influencerStatistics?.price == null &&
						userSubscription.role !== 'FREEMIUM' && (
							<div
								style={{
									position: 'absolute',
									top: !isMobile ? '60%' : '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									zIndex: 1,
								}}>
								<h5 className='text-center'>
									Non ci sono ancora dati disponibili per questo Creator...
								</h5>
								<h5 className='text-center'>Riprova più tardi! 😉</h5>
							</div>
						)}
					<div
						style={{
							filter:
								influencerStatistics?.price == null ||
								userSubscription.role === 'FREEMIUM'
									? 'blur(6px)'
									: '',
						}}>
						<div className='row'>
							{influencerStatistics.advice && (
								<div className={'col-12'}>
									<h4 className={'text-center mb-3'}>
										<Badge
											color={
												influencerStatistics.recommendedPlayer ||
												influencerStatistics.marketObjective
													? 'success'
													: influencerStatistics.notRecommendedPlayer
													? 'danger'
													: 'primary'
											}
											rounded={1}
											className={'py-2'}>
											<strong>{influencerStatistics.advice}</strong>
										</Badge>
									</h4>
								</div>
							)}
							<div className={'col-6'}>
								<Card
									shadow={'sm'}
									className='bg-l25-success text-center flex-grow-1 rounded-1'>
									<CardBody>
										<h5>
											Prezzo <small className={'text-muted'}>/ 1000 CR</small>
										</h5>
										<h3>
											<strong>{influencerStatistics.price}</strong>
										</h3>
									</CardBody>
								</Card>
							</div>
							<div className={'col-6'}>
								<Card
									shadow={'sm'}
									className='bg-l25-success text-center flex-grow-1 rounded-1'>
									<CardBody>
										<h5>Budget</h5>
										<h3>
											<strong>
												{influencerStatistics.percentageBudget}%
											</strong>
										</h3>
									</CardBody>
								</Card>
							</div>
						</div>
						<div className='row'>
							<div className={'col-4'}>
								<Card className='bg-l25-success text-center flex-grow-1 rounded-1 h-75'>
									<CardBody>
										<h6>Fascia</h6>
										<h5>
											<strong>
												{influencerStatistics.bracket}
												<sup>a</sup>
											</strong>
										</h5>
									</CardBody>
								</Card>
							</div>
							<div className={'col-4'}>
								<Card className='rounded-1 text-center h-75' shadow={'sm'}>
									<CardBody>
										<h6
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}>
											Titolarità
										</h6>
										<Progress
											isAutoColor={true}
											min={0}
											max={10}
											value={influencerStatistics.startingLineup}></Progress>
									</CardBody>
								</Card>
							</div>
							<div className={'col-4'}>
								<Card className='rounded-1 text-center h-75' shadow={'sm'}>
									<CardBody>
										<h6
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}>
											Affidabilità
										</h6>
										<Progress
											isAutoColor={true}
											min={0}
											max={10}
											value={influencerStatistics.reliability}></Progress>
									</CardBody>
								</Card>
							</div>
						</div>

						<div className='row'>
							<div className='col'>
								<div className='d-flex align-items-center justify-content-center'>
									<div className='d-flex flex-column align-items-center'>
										<div className=''>
											<h4>
												<span className='badge bg-danger px-3 py-2 mx-1'>
													{influencerStatistics.note1String || 'N.D.'}
												</span>
											</h4>
										</div>
									</div>

									<div className='d-flex flex-column align-items-center'>
										<div className=''>
											<h4>
												<span className='badge bg-warning px-3 py-2 mx-1'>
													{influencerStatistics.note2String || 'N.D.'}
												</span>
											</h4>
										</div>
									</div>

									<div className='d-flex flex-column align-items-center'>
										<div>
											<h4>
												<span className='badge badge bg-info px-3 py-2 mx-1'>
													{influencerStatistics.note3String || 'N.D.'}
												</span>
											</h4>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='d-flex flex-column mt-3'>
							<Button
								className='rounded-1 w-100 p-3 text-start d-flex justify-content-between align-items-center'
								shadow={'sm'}
								onClick={() => {
									setCollapseState(!collapseState);
								}}>
								<p className='mb-0'>Commento</p>
								<Icon icon={'KeyboardArrowDown'} size={'lg'}></Icon>
							</Button>
							<Collapse isOpen={collapseState}>
								<Card shadow={'sm'} className='mt-2 rounded-1'>
									<CardBody>
										{influencerStatistics.comment
											? influencerStatistics.comment
											: 'Nessun commento'}
									</CardBody>
								</Card>
							</Collapse>
						</div>
					</div>
				</CardBody>
			)}

			{modifying && (
				<>
					<CardBody>
						<FormGroup tag={'div'} id={'influencer-form'} size={'lg'}>
							<div className={'row py-3'}>
								<div className='col-6'>
									<Label>Prezzo</Label>
									<Input
										type={'number'}
										placeholder={`${influencerStatistics.price}`}
										value={request.price}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setPriceInvalid(false);
											setRequest({
												...request,
												price: Number(e.target.value),
											});
										}}
									/>
									<FormText>Su Base 1000 CR</FormText>
									{priceInvalid && (
										<p className='text-danger'>
											Devi inserire un valore numerico
										</p>
									)}
								</div>
								<div className='col-6'>
									<Label>Budget</Label>
									<InputGroup>
										<Input
											type={'number'}
											placeholder={`${influencerStatistics.percentageBudget}`}
											value={request.percentageBudget}
											isValid={
												typeof request.percentageBudget === 'number' &&
												request.percentageBudget >= 0 &&
												request.percentageBudget <= 100
											}
											invalidFeedback='Devi inserire un valore compreso tra 1 e 100.'
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setPercentageBudgetInvalid(false);
												setRequest({
													...request,
													percentageBudget: Number(e.target.value),
												});
											}}
										/>
										<InputGroupText>%</InputGroupText>
									</InputGroup>
									<FormText>Da 1 a 100</FormText>
									{percentageBudgetInvalid && (
										<p className='text-danger'>
											Devi inserire un valore compreso tra 1 e 100.
										</p>
									)}
								</div>
							</div>

							<div className={'row py-3'}>
								<div className='col-4'>
									<Label>Fascia</Label>
									<Select
										id={'fascia'}
										value={request.bracket.toString()}
										onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
											setBracketInvalid(false);
											setRequest({
												...request,
												bracket: Number(e.target.value),
											});
										}}
										ariaLabel={'fascia'}
										placeholder={`${request.bracket}`}>
										{fasciaOptions.map((fascia, index) => (
											<Option key={index} value={fascia}>
												{fascia.toString()}
											</Option>
										))}
									</Select>
									<FormText>Da 1 a 8</FormText>
									{bracketInvalid && (
										<p className='text-danger'>
											Devi inserire un valore compreso tra 1 e 8
										</p>
									)}
								</div>
								<div className='col-4'>
									<Label>Titolarità</Label>
									<Input
										type={'text'}
										placeholder={`${influencerStatistics.startingLineup}`}
										value={request.startingLineup}
										isValid={
											typeof request.startingLineup === 'number' &&
											request.startingLineup >= 0 &&
											request.startingLineup <= 10
										}
										invalidFeedback='Devi inserire un valore compreso tra 1 e 10.'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setStartingLineupInvalid(false);
											setRequest({
												...request,
												startingLineup: Number(e.target.value),
											});
										}}
									/>
									<FormText>Da 1 a 10</FormText>
									{startingLineupInvalid && (
										<p className='text-danger'>
											Devi inserire un valore compreso tra 1 e 10
										</p>
									)}
								</div>
								<div className='col-4'>
									<Label>Affidabilità</Label>
									<Input
										type={'text'}
										placeholder={`${influencerStatistics.reliability}`}
										value={request.reliability}
										isValid={
											typeof request.reliability === 'number' &&
											request.reliability >= 1 &&
											request.reliability <= 10
										}
										invalidFeedback='Devi inserire un valore compreso tra 1 e 10.'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setReliabilityInvalid(false);
											setRequest({
												...request,
												reliability: Number(e.target.value),
											});
										}}
									/>
									<FormText>Da 1 a 10</FormText>
									{reliabilityInvalid && (
										<p className='text-danger'>
											Devi inserire un valore compreso tra 1 e 10
										</p>
									)}
								</div>
							</div>

							<div className={'row py-3'}>
								<div className='col-4'>
									<Label className='mb-0'>Classe</Label>
									<Select
										ariaLabel={'note1'}
										value={request.note1}
										placeholder={`N.D.`}
										className='text-muted'
										onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
											setRequest({
												...request,
												note1: e.target.value,
												note1String:
													note1Values.find(
														(n) => n.value === Number(e.target.value),
													)?.label || 'N.D.',
											});
										}}>
										{note1Values.map((note, index) => (
											<Option key={index} value={note.value}>
												{note.label}
											</Option>
										))}
									</Select>
									<FormText>Opzionale</FormText>
								</div>
								<div className='col-4'>
									<Label className='mb-0'>Caratteristica</Label>
									<Select
										ariaLabel={'note2'}
										value={request.note2}
										placeholder={`N.D.`}
										className='text-muted'
										onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
											setRequest({
												...request,
												note2: e.target.value,
												note2String:
													note2Values.find(
														(n) => n.value === Number(e.target.value),
													)?.label || 'N.D.',
											});
										}}>
										{note2Values.map((note, index) => (
											<Option key={index} value={note.value}>
												{note.label}
											</Option>
										))}
									</Select>
									<FormText>Opzionale</FormText>
								</div>
								<div className='col-4'>
									<Label className='mb-0'>Caratteristica</Label>
									<Select
										ariaLabel={'note3'}
										value={request.note3}
										placeholder={`N.D.`}
										className='text-muted'
										onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
											setRequest({
												...request,
												note3: e.target.value,
												note3String:
													note2Values.find(
														(n) => n.value === Number(e.target.value),
													)?.label || 'N.D.',
											});
										}}>
										{note2Values.map((note, index) => (
											<Option key={index} value={note.value}>
												{note.label}
											</Option>
										))}
									</Select>
									<FormText>Opzionale</FormText>
								</div>
							</div>

							<div className={'row py-3'}>
								<div className='col-12'>
									<div className='d-flex align-items-center justify-content-start'>
										<Checks
											type={'switch'}
											checked={request.marketObjective}
											id={'obiettivoMercato'}
											disabled={request.notRecommendedPlayer}
											onChange={() => {
												setRequest({
													...request,
													marketObjective: Boolean(
														!request.marketObjective,
													),
													advice: 'Obiettivo Di Mercato',
												});
											}}
											className='mb-0'
										/>
										<Label className='mb-0'>Obiettivo di Mercato</Label>
									</div>
									<div className='d-flex align-items-center justify-content-start'>
										<Checks
											type={'switch'}
											checked={
												request.recommendedPlayer || request.marketObjective
											}
											id={'consigliato'}
											disabled={request.notRecommendedPlayer}
											onChange={() => {
												setRequest({
													...request,
													recommendedPlayer: Boolean(
														!request.recommendedPlayer,
													),
													advice: 'Giocatore Raccomandato',
												});
											}}
											className='mb-0'
										/>
										<Label className='mb-0'>Giocatore Consigliato</Label>
									</div>
									<div className='d-flex align-items-center justify-content-start'>
										<Checks
											type={'switch'}
											checked={request.notRecommendedPlayer}
											id={'sconsigliato'}
											disabled={
												request.recommendedPlayer || request.marketObjective
											}
											onChange={() => {
												setRequest({
													...request,
													notRecommendedPlayer: Boolean(
														!request.notRecommendedPlayer,
													),
													advice: 'Giocatore Non Raccomandato',
												});
											}}
											className='mb-0'
										/>
										<Label className='mb-0'>Giocatore Sconsigliato</Label>
									</div>
								</div>
							</div>

							<Card shadow={'none'} className='mb-0'>
								<CardHeader>
									<CardLabel>
										<div className={'d-flex align-items-center'}>
											<svg
												viewBox='0 0 24 24'
												fill='currentColor'
												className='svg-icon--material svg-icon card-icon text-info'
												data-name='Material--Notes'>
												<path d='M0 0h24v24H0V0z' fill='none' />
												<path d='M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z' />
											</svg>
											<CardTitle>Commento</CardTitle>
										</div>
									</CardLabel>
								</CardHeader>
								<div className='card-body pt-0'>
									<textarea
										id='exampleTypesPlaceholder--textarea'
										value={request.comment}
										onChange={(e) => {
											setCommentInvalid(false);
											setRequest({
												...request,
												comment: e.target.value,
											});
										}}
										className='form-control'
										rows={3}
										placeholder='Inserisci Commento sul Giocatore'
										aria-describedby='exampleTypesPlaceholder--textarea-text'
										aria-label='.form-control-lg example'
									/>
								</div>
								{commentInvalid && <p className='text-danger'>Errore </p>}
							</Card>
						</FormGroup>
					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<Button
								color={'info'}
								isOutline={true}
								onClick={() => {
									setModifying(false);
								}}>
								Indietro
							</Button>
							<Button
								type='submit'
								color={'info'}
								onClick={() => {
									let isAnyInputInvalid = false;
									if (isNaN(request.price) || request.price < 0) {
										setPriceInvalid(true);
										isAnyInputInvalid = true;
									}
									if (
										isNaN(request.percentageBudget) ||
										request.percentageBudget < 0 ||
										request.percentageBudget > 100
									) {
										setPercentageBudgetInvalid(true);
										isAnyInputInvalid = true;
									}
									if (
										isNaN(request.bracket) ||
										request.bracket < 0 ||
										request.bracket > 100
									) {
										setBracketInvalid(true);
										isAnyInputInvalid = true;
									}
									if (
										isNaN(request.startingLineup) ||
										request.startingLineup < 0 ||
										request.startingLineup > 10
									) {
										setStartingLineupInvalid(true);
										isAnyInputInvalid = true;
									}
									if (
										isNaN(request.reliability) ||
										request.reliability < 0 ||
										request.reliability > 10
									) {
										setReliabilityInvalid(true);
										isAnyInputInvalid = true;
									}

									if (!isAnyInputInvalid) {
										if (selectedInfluencer) {
											setInfluencerData(
												playerId,
												selectedInfluencer.id,
												request,
											);
											setInfluencerStatistics(request);
										}
										setModifying(false);
									}
								}}>
								Salva
							</Button>
						</CardFooterRight>
					</CardFooter>
				</>
			)}
		</Card>
	);
};

export const renderNote = (
	playerId: string,
	noteState: any,
	setNoteState: (value: any) => any,
	updateNote: (value: any) => any,
	setNoteMessageError: string,
	deleteNote: (value: any) => any,
) => {
	const { setToast } = useContext(ToastContext);

	return (
		<div className='card'>
			<>
				<div className='card-header'>
					<div className='card-label'>
						<svg
							viewBox='0 0 24 24'
							fill='currentColor'
							className='svg-icon--material svg-icon card-icon text-info'
							data-name='Material--Notes'>
							<path d='M0 0h24v24H0V0z' fill='none' />
							<path d='M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z' />
						</svg>
						<div className='card-title-wrapper'>
							<h5 className='card-title'>Note</h5>
						</div>
					</div>
					<Button
						className='p-1 px-2 rounded-1'
						color={'info'}
						isOutline={true}
						onClick={async () => {
							if (!playerId || !noteState) return;
							if (noteState.note !== '') {
								await updateNote(noteState.note);
								const toastrTitle = setNoteMessageError
									? 'Nota non salvata'
									: 'Nota salvata';
								const toastrIconColor = setNoteMessageError ? 'error' : 'success';
								const toastrMessage = setNoteMessageError
									? setNoteMessageError
									: `La nota è stata salvata`;
								setToast({
									level: toastrIconColor,
									title: toastrTitle,
									message: toastrMessage,
								});
							} else {
								deleteNote(playerId).catch((e: any) => {
									console.error(e);
								});
								const toastrTitle = setNoteMessageError
									? 'Nota non eliminata'
									: 'Nota eliminata';
								const toastrIconColor = setNoteMessageError ? 'error' : 'success';
								const toastrMessage = setNoteMessageError
									? setNoteMessageError
									: `La nota è stata eliminata`;
								setToast({
									level: toastrIconColor,
									title: toastrTitle,
									message: toastrMessage,
								});
							}
						}}>
						<Icon icon={'Save'} size={'lg'} />
					</Button>
				</div>
				<div className='card-body pt-0'>
					<textarea
						id='exampleTypesPlaceholder--textarea'
						value={noteState?.note || ''}
						onChange={(event) => {
							setNoteState({ note: event.target.value });
						}}
						className='form-control'
						rows={3}
						placeholder='Inserisci delle note sul giocatore'
						aria-describedby='exampleTypesPlaceholder--textarea-text'
						aria-label='.form-control-lg example'
					/>
				</div>
			</>
		</div>
	);
};
